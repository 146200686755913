import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import { getStorageData } from "../../framework/src/Utilities";

import { imglogo } from "../../blocks/forgot-password/src/assets";

interface myProps {
    navigation?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0px 87px 0px 79.5px',
        '@media(max-width:1600px)':{
            padding: '0px 72.5px 0px 66.25px',
        },
        '@media(max-width:1280px)':{
            padding: '0px 57px 0px 53px',
        },
    },
    footerTop: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap:'20px',
        padding: '96px 123px',
        '@media(max-width:1600px)':{
            padding: '80px 102.5px',
        },
        '@media(max-width:1280px)':{
            padding: '64px 82px',
        },
        '@media(max-width:1024px)':{
            padding: '64px 0px',
        }
    },
    footerTxt: {
        color: '#FFFFFF',
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 500,
        cursor: 'pointer',
        '@media(max-width:1600px)':{
            fontSize: '20px',
            lineHeight: '30px',
        },
        '@media(max-width:1280px)':{
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    footerLeftTxt: {
        color: '#FFFFFF',
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 400,
        '@media(max-width:1600px)':{
            fontSize: '20px',
            lineHeight: '30px',
        },
        '@media(max-width:1280px)':{
            fontSize: '16px',
            lineHeight: '24px',
        },
        '@media(max-width:768px)':{
            marginTop: "0px",
            maxWidth:'none'
        }
    },
    footerLogo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '36px',
        width: '555px',
        '@media(max-width:1600px)':{
            width: '462.5px',
        },
        '@media(max-width:1280px)':{
            width: '370px',
        },
        '@media(max-width:959px)':{
            width:'auto'
        }
    },
    linksWrappper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '511.5px',
        gap: '20px',
        '@media(max-width:1600px)':{
            width: '426.25px',
        },
        '@media(max-width:1280px)':{
            width: '341px',
        },
        '@media(max-width:959px)':{
            width:'auto'
        }
     },
     logo:{
        cursor: 'pointer',
        width: '199.26px',
        '@media(max-width:1600px)':{
            width: '166.05px',
        },
        '@media(max-width:1280px)':{
            width: '132.84px',
        },
        '@media(max-width:600px)':{
            width:'120px'
        }
     },
     rightsReserved: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 600,
        padding: '36px',
        '@media(max-width:1600px)':{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '30px',
        },
        '@media(max-width:1280px)':{
            fontSize: '16px',
            lineHeight: '24px',
            padding: '24px',
        },
     }
}));

export default function Footer(props: myProps) {

    const classes = useStyles();
    const navigateToInvest = async () => {
        if (await getStorageData("token") === null) {
            props.navigation.navigate('EmailAccountRegistrationWeb')
        } else {
            props.navigation.navigate("InvestmentOpportunitiesWeb", {
                type: 'all'
            });
        }
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    }

    const navigateToCompanyPolicy = async (param: string) => {
        props.navigation.navigate("TermsConditions", {
            type: param
        });
    }
    const navigateToAboutUs = async () => {
        props.navigation.navigate('ContentManagement');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    }

    const navigateToHomePage = async () => {
        props.navigation.navigate('HomeScreenWeb');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    }

    return (
        <Paper elevation={0} square style={{ background: 'linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)', display: 'flex', justifyContent: 'center' }}>
            <div className={classes.root}>
                <div className={classes.footerTop}>
                    <div className={classes.footerLogo}>
                        <img src={imglogo} alt="logo" className={classes.logo} onClick={() => navigateToHomePage()} />
                        <Typography className={classes.footerLeftTxt} >Seamlessly entered the world of alternative investments with Future of Alts.</Typography>
                    </div>
                    <div className={classes.linksWrappper}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Typography className={classes.footerTxt} onClick={() => navigateToInvest()}>Invest</Typography>
                            <Typography className={classes.footerTxt} onClick={() => navigateToAboutUs()}>About</Typography>
                            <Typography className={classes.footerTxt} onClick={() => navigateToCompanyPolicy('disclaimer')}>Disclaimer</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',gap:'10px' }}>
                            <Typography className={classes.footerTxt} onClick={() => navigateToCompanyPolicy('terms_and_conditions')}>Terms & Conditions</Typography>
                            <Typography className={classes.footerTxt} onClick={() => navigateToCompanyPolicy('privacy_policy')}>Privacy Policy</Typography>
                            <Typography style={{ color: 'transparent' }}> None </Typography>
                        </div>
                    </div>
                </div>
                <Divider style={{ background: '#FFFFFF' }} />
                <Typography className={classes.rightsReserved}>©{new Date().getFullYear()} Future For Alts. All rights reserved</Typography>
            </div>
        </Paper>
    )
}
