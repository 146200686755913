import React, { useState } from 'react';

export default function Readmore({ text }: { text: string | null }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const charLimit = 100;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {isExpanded ? `${text} ${" "}` : `${text ? text.slice(0, charLimit) : ""}...`}
      {text && text.length > charLimit && (
        <span
          style={{ cursor: "pointer", color: 'rgba(0, 105, 188, 1)' }}
          onClick={toggleReadMore}
        >
          {isExpanded ? text.length > charLimit && 'Read Less' : text.length > charLimit && 'Read More'}
        </span>
      )}
    </div>
  );
}
