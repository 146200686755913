import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    investmentDetails : any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class InvestmentProcessController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInvestmentProcessListByIDApiCallId : string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            activeStep: 0,
            investmentDetails:{}
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getInvestmentProcessListByIDApiCallId) {
                if (responseJson) {
                    this.setState({
                        investmentDetails: responseJson,
                    })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getInvestmentProcessList();
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScrollInvest);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleScrollInvest = () => {
        const winHeight = window.innerWidth > 1500 ? 600 : 500;
        const topLimit = winHeight * 0.2;
        const bottomLimit = winHeight * 0.8;

        let activeStep = this.state.activeStep;
        document.querySelectorAll('.scroll-content').forEach((item, index) => {
            const element = item as HTMLElement;
            const thisTop = element.getBoundingClientRect().top;
            if (thisTop >= topLimit && thisTop + element.offsetHeight <= bottomLimit) {
                activeStep = index;
            }
        });
        this.setState({ activeStep });
    };

    navigateToInvestList = async () =>{
        if (await getStorageData("token") === null) {
            const goToSignUpBlock: Message = new Message(getName(MessageEnum.NavigationMessage));
            goToSignUpBlock.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountRegistrationWeb');
            goToSignUpBlock.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(goToSignUpBlock);
        } else {
            this.props.navigation.navigate("InvestmentOpportunitiesWeb", {
                type: 'all'
            });
        }
    }

    getInvestmentProcessList = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getInvestmentProcessListByIDApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/investment_processes");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
