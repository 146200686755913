import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import React from "react";
import { profilePlaceholder } from "../../settings2/src/assets";
const configJSONData = require("../../forgot-password/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  logoutOpen: boolean;
  deleteAccountOpen: boolean;
  emailVerificationOpen: boolean;
  verify: boolean;
  verificationSuccessOpen: boolean;
  selectedSetting: string;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
  editDetails: boolean;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  loading: boolean;
  kycData: any;
  newOpportunity: boolean;
  investmentDocumentation: boolean;
  investmentQuery: boolean;
  investmentStatus: boolean;
  wireTransfer: boolean;
  notificationID: string;
  newPassword: string;
  confirmNewPassword: string;
  newPasswordError: string;
  confirmNewPasswordError: string;
  fullName: string;
  contactEmail: string;
  query: string;
  description: string;
  profileName: string;
  errors: any;
  userProfile: any;
  showCurrentPassword: boolean;
  currentPassword: string;
  currentPasswordError: string;
  oldEmail: string;
  disableEmail: boolean;
  openSideMenu : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileUploadRef: any;
  getKycDetailsApiCallId: string = "";
  getNotificationDetailsApiCallId: string = "";
  changeUserPasswordApiCallId: string = "";
  contactUsApiCallId: string = "";
  deleteAccountApiCallId: string = "";
  getUserProfileDetailsApiCallId: string = "";
  getEmailConfirmationApiCallId: string = "";
  getVerificationMailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      logoutOpen: false,
      deleteAccountOpen: false,
      emailVerificationOpen: false,
      verify: false,
      verificationSuccessOpen: false,
      selectedSetting: "Personal Information",
      showNewPassword: false,
      showConfirmNewPassword: false,
      editDetails: false,
      firstName: "",
      lastName: "",
      address: "",
      email: "",
      phoneNumber: "",
      countryCode: "+91",
      kycData: {},
      newOpportunity: false,
      investmentDocumentation: false,
      investmentQuery: false,
      investmentStatus: false,
      wireTransfer: false,
      notificationID: "",
      newPassword: "",
      confirmNewPassword: "",
      newPasswordError: "",
      confirmNewPasswordError: "",
      fullName: "",
      contactEmail: "",
      query: "",
      description: "",
      profileName: "",
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        countryCode: "",
      },
      userProfile: null,
      showCurrentPassword: false,
      currentPassword: "",
      currentPasswordError: "",
      oldEmail: "",
      disableEmail: false,
      openSideMenu:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileUploadRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.getNotificationDetailsApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          this.setState({
            investmentDocumentation:
              responseJson.data.attributes.investment_documentation,
            newOpportunity: responseJson.data.attributes.new_opportunity,
            investmentStatus: responseJson.data.attributes.investment_status,
            investmentQuery: responseJson.data.attributes.investment_query,
            wireTransfer: responseJson.data.attributes.wire_transfer,
            notificationID: responseJson.data.id,
          });
        }
      } else if (apiRequestCallId === this.getKycDetailsApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          this.setState({
            kycData: responseJson.data.attributes,
          });
        }
      } else if (apiRequestCallId === this.deleteAccountApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          this.props.navigation.navigate("HomeScreenWeb");
        }
      } else if (apiRequestCallId === this.getUserProfileDetailsApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          const userData = responseJson.data.attributes;
          this.setState({
            firstName: userData.first_name ? userData.first_name : "",
            lastName: userData.last_name ? userData.last_name : "",
            address: userData.address ? userData.address : "",
            phoneNumber: userData.phone_number ? userData.phone_number : "",
            countryCode: userData.country_code ? userData.country_code : "",
            email: userData.email ? userData.email : "",
            verify: userData.email_verified ? userData.email_verified : false,
            profileName:
              userData.first_name || userData.last_name
                ? userData.first_name + " " + userData.last_name
                : "",
            userProfile: userData.photo ? userData.photo : profilePlaceholder,
            contactEmail: userData.email ? userData.email : "",
            fullName: userData.first_name ? userData.first_name : "",
            oldEmail: userData.email ? userData.email : "",
          });
        }
      } else if (apiRequestCallId === this.getVerificationMailApiCallId) {
        this.setState({
          disableEmail: false,
        });
        if (responseJson && responseJson.data) {
          this.setState({
            loading: false,
            emailVerificationOpen: true,
          });
        } else {
          toast.error(responseJson.errors[0]);
        }
      } else if (apiRequestCallId === this.getEmailConfirmationApiCallId) {
        if (responseJson && responseJson.data) {
          const userData = responseJson.data.attributes;
          this.setState({
            verify: userData.email_verified ? userData.email_verified : false,
            verificationSuccessOpen: true,
            emailVerificationOpen: false,
            oldEmail: userData.email ? userData.email : "",
          });
        }
      } else if (apiRequestCallId === this.changeUserPasswordApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          toast.success("Password changed successfully.");
          this.setState({
            newPassword: "",
            confirmNewPassword: "",
            currentPassword: "",
          });
        } else if (responseJson && responseJson.errors[0]) {
          const firstError = responseJson.errors[0];
          const dynamicKey = Object.keys(firstError)[0];
          const dynamicValue = firstError[dynamicKey];
          toast.error(dynamicValue);
        }
      } else if (apiRequestCallId === this.contactUsApiCallId) {
        this.setState({ loading: false });

        if (responseJson && responseJson.data) {
          this.setState({
            query: "",
            description: "",
          });
          toast.success("Contact details submitted successfully.");
        } else if (responseJson && responseJson.errors[0]) {
          toast.error(responseJson.errors[0].contact[0]);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getUserProfileDetails();
    this.getKycDetails();
    this.getNotificationDetails();
    const redirectTo = await getStorageData("contactUs");
    if (redirectTo == "showContactUs") {
      this.setState(
        {
          selectedSetting: "Contact us",
        },
        () => removeStorageData("contactUs")
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleLogoutClose = () => {
    this.setState({
      logoutOpen: false,
    });
  };

  fileChange = async (event: any) => {
    const file = event.target.files[0];
    const header = { token: await getStorageData("token") };

    const formData = new FormData();
    formData.append("data[attributes][photo]", file);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLogout = () => {
    removeStorageData("token");
    removeStorageData("userInfo");
    removeStorageData("kyc_status");
    removeStorageData("userName");
    removeStorageData("userId");
    removeStorageData("wireTransfer");
    removeStorageData("supportContact");
    this.props.navigation.navigate("HomeScreenWeb");
  };

  toggleNewPassword = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword,
    }));
  };

  toggleCurrentPassword = () => {
    this.setState((prevState) => ({
      showCurrentPassword: !prevState.showCurrentPassword,
    }));
  };

  openLogoutDialog = () => {
    this.setState({
      logoutOpen: true,
    });
  };

  handlePhoneNumber = (event: any) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  handleCountryCode = (event: any, value: any) => {
    this.setState({
      countryCode: value,
    });
  };

  toggleSidebar = () => {
    this.setState((prevState) => {
      return { openSideMenu: !prevState.openSideMenu }
    })
  }

  cancelEditDetails = () => {
    this.setState(
      {
        editDetails: false,
        errors: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          countryCode: "",
        },
      },
      () => {
        this.getUserProfileDetails();
      }
    );
  };

  handleEditDetails = () => {
    this.setState({
      editDetails: true,
    });
  };

  handleFullName = (event: any) => {
    this.setState({
      fullName: event.target.value,
    });
  };

  handleQuery = (event: any) => {
    this.setState({
      query: event.target.value,
    });
  };

  handleDescription = (event: any) => {
    this.setState({
      description: event.target.value,
    });
  };

  handleFirstName = (event: any) => {
    this.setState({
      firstName:
        event.target.value?.charAt(0).toUpperCase() +
        event.target.value?.slice(1),
    });
  };

  handleLastName = (event: any) => {
    this.setState({
      lastName:
        event.target.value?.charAt(0).toUpperCase() +
        event.target.value?.slice(1),
    });
  };

  getEmailConfirmation = () => {
    this.getEmailVerificationConfirmation();
  };

  handleContactMail = (event: any) => {
    this.setState({
      contactEmail: event.target.value,
    });
  };

  handleEmail = (event: any) => {
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        const disableValue = this.state.oldEmail !== event.target.value;
        this.setState({
          disableEmail: disableValue,
        });
      }
    );
  };

  handleAddress = (event: any) => {
    let value = event.target.value;
    let str = "";
    let runOnce = true;

    for (let i in value) {
      if (
        ((value.charCodeAt(i) >= 65 && value.charCodeAt(i) <= 90) ||
          (value.charCodeAt(i) >= 97 && value.charCodeAt(i) <= 122)) &&
        runOnce == true
      ) {
        runOnce = false;
        str += value[i].toUpperCase();
      } else {
        str += value[i].toLowerCase();
      }
    }
    this.setState({
      address: str,
    });
  };

  handleverificationSuccessClose = () => {
    this.setState({
      verificationSuccessOpen: false,
    });
  };

  validate = () => {
    const { firstName, lastName, email, phoneNumber, countryCode } = this.state;

    if (firstName.length > 30) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          firstName: "First Name should not exceed 30 characters",
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          firstName: "",
        },
      }));
    }

    if (lastName.length > 30) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          lastName: "Last Name should not exceed 30 characters",
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          lastName: "",
        },
      }));
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email && !emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: "Invalid email format",
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: "",
        },
      }));
    }
    if (!countryCode) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          countryCode: "Country code is required",
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          countryCode: "",
        },
      }));
    }

    const phoneNumberRegex = /^\d{10}$/;
    if (phoneNumber && !phoneNumberRegex.test(phoneNumber)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phoneNumber: "Invalid phone number format",
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phoneNumber: "",
        },
      }));
    }

    return true;
  };

  updateUserDetails = () => {
    if (this.validate()) {
      this.setState(
        {
          editDetails: false,
        },
        () => {
          this.changeUserProfileDetails();
        }
      );
    }
  };

  handleVerifyEmail = () => {
    this.getVerificationMail();
  };

  handleEmailVerifictionClose = () => {
    this.setState({
      emailVerificationOpen: false,
    });
  };

  handleDeleteAccount = () => {
    this.setState({
      deleteAccountOpen: true,
    });
  };

  handledeleteAccountClose = () => {
    this.setState({
      deleteAccountOpen: false,
    });
  };

  handleNewPasswordChange = (event: any) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleCurrentPasswordChange = (event: any) => {
    this.setState({
      currentPassword: event.target.value,
    });
  };

  validateNewPassword = () => {
    const { newPassword } = this.state;
    if (!newPassword) {
      this.setState({ newPasswordError: configJSONData.pleaseEnterAPassword });
      return false;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      this.setState({
        newPasswordError:
          configJSONData.placeholderIsPassword +
          " must contain at least 8 characters, 1 special character, 1 number, 1 uppercase and 1 lowercase letter",
      });
      return false;
    }
    this.setState({ newPasswordError: "" });
    return true;
  };

  validateCurrentPassword = () => {
    const { currentPassword } = this.state;
    if (!currentPassword) {
      this.setState({
        currentPasswordError: configJSONData.pleaseEnterAPassword,
      });
      return false;
    }
    this.setState({ newPasswordError: "" });
    return true;
  };

  handleConfirmNewPasswordChange = (event: any) => {
    this.setState({
      confirmNewPassword: event.target.value,
    });
  };

  toggleConfirmNewPassword = () => {
    this.setState((prevState) => ({
      showConfirmNewPassword: !prevState.showConfirmNewPassword,
    }));
  };

  selectSetting = (type: string) => {
    this.setState((prevState)=>{
      return {
      selectedSetting: type,
      openSideMenu:false
    }});
  };

  validateConfirmNewPassword = () => {
    const { newPassword, confirmNewPassword } = this.state;
    if (newPassword !== confirmNewPassword) {
      this.setState({
        confirmNewPasswordError: "Your new password doesn’t match",
      });
      return false;
    }
    this.setState({ confirmNewPasswordError: "" });
    return true;
  };

  handleChangeInvestmentDocumentation = () => {
    this.setState(
      (prevState) => ({
        investmentDocumentation: !prevState.investmentDocumentation,
      }),
      () => {
        this.changeNotificationSetting();
      }
    );
  };

  handleChangeWireTransfer = () => {
    this.setState(
      (prevState) => ({
        wireTransfer: !prevState.wireTransfer,
      }),
      () => {
        this.changeNotificationSetting();
      }
    );
  };

  handleChangeInvestmentQuery = () => {
    this.setState(
      (prevState) => ({
        investmentQuery: !prevState.investmentQuery,
      }),
      () => {
        this.changeNotificationSetting();
      }
    );
  };

  changeProfilePassword = () => {
    const isCurrentPasswordValid = this.validateCurrentPassword();
    const isNewPasswordValid = this.validateNewPassword();
    const isConfirmPasswordValid = this.validateConfirmNewPassword();

    if (
      !isCurrentPasswordValid ||
      !isNewPasswordValid ||
      !isConfirmPasswordValid
    ) {
      return;
    } else {
      this.changeUserPassword();
    }
  };

  handleChangeNewOpportunity = () => {
    this.setState(
      (prevState) => ({
        newOpportunity: !prevState.newOpportunity,
      }),
      () => {
        this.changeNotificationSetting();
      }
    );
  };

  handleChangeInvestmentStatus = () => {
    this.setState(
      (prevState) => ({
        investmentStatus: !prevState.investmentStatus,
      }),
      () => {
        this.changeNotificationSetting();
      }
    );
  };

  getKycDetails = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getKycDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/user_kycs"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserProfileDetails = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/profile"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeUserProfileDetails = async () => {
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      address,
      countryCode,
      verify,
      oldEmail,
    } = this.state;
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          country_code: countryCode,
          email: verify ? email : oldEmail,
          full_name: `${firstName} ${lastName}`,
          first_name: firstName,
          last_name: lastName,
          full_phone_number: `${countryCode}${phoneNumber}`,
          address: address,
          phone_number: phoneNumber,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVerificationMail = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVerificationMailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile?email=" + this.state.email
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNotificationDetails = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_notifsettings/notification_settings"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationSetting = async () => {
    const {
      wireTransfer,
      newOpportunity,
      investmentDocumentation,
      investmentQuery,
      investmentStatus,
      notificationID,
    } = this.state;
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const httpBody = {
      wire_transfer: wireTransfer,
      new_opportunity: newOpportunity,
      investment_status: investmentStatus,
      investment_documentation: investmentDocumentation,
      investment_query: investmentQuery,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_notifsettings/notification_settings/" + notificationID
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeUserPassword = async () => {
    const { confirmNewPassword, currentPassword } = this.state;
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const httpBody = {
      data: {
        current_password: currentPassword,
        new_password: confirmNewPassword,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeUserPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/passwords"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  contactUs = async () => {
    const { fullName, contactEmail, query, description } = this.state;
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const httpBody = {
      data: {
        name: fullName,
        email: contactEmail,
        query: query,
        description: description,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contact_us/contacts"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteAccount = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    removeStorageData("token");
    removeStorageData("userInfo");
    removeStorageData("kyc_status");
    removeStorageData("userName");
    removeStorageData("userId");
    removeStorageData("wireTransfer");
    removeStorageData("supportContact");
    this.props.navigation.navigate("EmailAccountRegistrationWeb");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getEmailVerificationConfirmation = async () => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmailConfirmationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
