import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imgShape, navLogo } from "../../landingpage/src/assets";
import { imageHerosectionbackground, imageShapeTwo } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { blackBg, blueBg, imgArrows, imgDevice,xlImage,lgImage,mdImage,mdArrowImage,lgArrowImage,xlArrowImage } from "./assets";
import Footer from "../../../components/src/Footer.web";

// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImageStyle: {
        flexDirection: 'column',
        minHeight: '871px',
        display: 'flex', 
        backgroundRepeat: "round",
        alignItems: 'center',
        backgroundImage: `url(${imageHerosectionbackground})`,
        backgroundSize: "cover",
        gap: '126px',
        '@media(max-width:1920px)': {
            minHeight: '100vh',
            gap: '56px'
        },
        '@media(max-width:1600px)': {
            gap: '36px'
          },
        '@media (max-width: 1280px)': {
            gap: '20px'
        },
        '@media (max-width: 959px)': {
            minHeight: '570px',
            gap: '52px'
        },
        '@media (max-width: 600px)': {
            minHeight: '449px',
            gap: '26px'
        },
    },
    titleTextBoxStyle: {
        maxWidth: '1170px',
        position: 'relative',
        width: '100%',
        minHeight: '298px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    headerTextBoxStyle: {
        fontSize: '64px',
        maxWidth: '940px',
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        fontWeight: 600,
        width: '90%',
        '@media(max-width:1920px)':{
            fontSize:'86.95px',
            lineHeight:'130.42px',
            maxWidth:'1560px'
        },
        '@media(max-width:1600px)':{
            maxWidth:'1300px',
            fontSize:'72.45px',
            lineHeight:'108.68px'
        },
        '@media (max-width: 1280px)': {
            fontSize: '58px',
            lineHeight:'87px',
            maxWidth: '992px',
        },
        '@media (max-width: 959px)': {
            fontSize: '36px',
            lineHeight:'1.5'
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
        }
    },
    futureTextBoxStyle: {
        color: '#00E644',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    opportunitieBtnStyle: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        width: '100%',
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '319px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        height: '60px',
        background: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        }
    },
    allInOneStyle: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        
        '& .founder':{
            borderRadius: '50%',
            width: '138px',
            height: '138px' 
        },
        '& .founder-first':{
            maxWidth:'216px'
        },
        '& .description':{
            fontSize: '20px',
            textAlign: 'center',
            fontFamily: 'Lato',
            fontWeight: 600,
        },
        "& .allInOneBox": {
            display: 'flex',
            gap: '170px'
        },
        
        "& .profileBoxStyle": {
            boxSizing:"border-box",
            width: '296px',
            padding: '24px 40px',
            gap: '32px',
            borderRadius: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            background: 'linear-gradient(145.6deg, rgba(0, 230, 68, 0.15) 0%, rgba(0, 230, 68, 0.25) 100%)',
            "&.first": {
                bottom: '-78px',
                '@media(max-width:1600px)':{
                    bottom:'-64px',
                },
                '@media(max-width:1280px)':{
                    bottom:'-48px'
                },
            }
        },
        '@media(max-width:1920px)':{
            '& .arrow':{
                height:'200px'
            },
            "& .profileBoxStyle": {
                borderRadius:"15px",
            },
            "& .allInOneBox": {
                gap: '150px'
            },
            '& .down-connector':{
                width:'627px'
            },
            '& .founder':{
                borderRadius: '50%',
                width: '144px',
                height: '144px' 
            }, 
            '& .description':{
                fontSize: '20px',
                lineHeight:'31.06px',
            },
            
            '& .first':{
                width:'335px',
                padding:'25.12px 41.87px'
            },
            '& .second':{
                width:'357px',
                padding:'25.12px 31.87px'
            }
        },
        '@media(max-width:1600px)':{
            '& .arrow':{
                height:'170px'
            },
            '& .founder':{
                width: '100px',
                height: '100px' 
            }, 
            "& .profileBoxStyle": {
                gap:'15px'
            },
            "& .allInOneBox": {
                gap: '124px'
            },
            '& .down-connector':{
                width:'552px'
            },
            '& .description':{
                fontSize: '18.24px',
                lineHeight:'27.37px',
            },
            '& .first':{
                width:'295px',
                padding:'22.14px 36.89px',
                borderRadius:'14.18px',
            },
            '& .second':{
                width:'314px',
                padding:'22.14px 22.89px',
                borderRadius:'14.18px',
            }
        },
        '@media(max-width:1280px)':{
            '& .arrow':{
                height:'128px'
            },
            "& .allInOneBox": {
                gap: '80px'
            },
            '& .founder':{
                width: '80px',
                height: '80px',
            }, 
            '& .down-connector':{
                width:'458px'
            },
            '& .description':{
                fontSize: '15.06px',
                lineHeight:'22.59px',
            },
            '& .first':{
                width:'243px',
                borderRadius:'12.18px',
                padding:'18.27px 30.45px',
            },
            '& .second':{
                width:'260px',
                borderRadius:'12.18px',
                padding:'18.27px 19.45px',
            },
            "& .profileBoxStyle": {
                gap:'12px'
            }
        },
        '@media (max-width: 959px)': {
            marginBottom: "70px",
            "& .allInOneBox": {
                gap: '60px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                "& img": {
                    display: 'flex'
                }
            },
            "& .profileBoxStyle": {
                padding:'24px 20px',
                width:'296px',
                "&.first": {
                    bottom: '0 !important'
                }
            },
            "& img": {
                display: 'none'
            }
        },
    },

    containerStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems:'center',
        padding: '40px 0px',
        '@media (max-width: 1920px)': {
            minHeight:'100vh'
        },
        '@media (max-width: 1600px)': {
            padding:'0 20px',
        },
        '@media (max-width: 1280px)': {
            padding: '40px 20px',
        },
        '@media (max-width: 959px)': {
            padding: '0px 20px',
            margin: '70px 0px',
            minHeight:'auto'
        },
        '@media(max-width:768px)':{
            margin:'0'
        }
    },
    blueBlackBoxWrapper:{
        display:'flex',
        flexDirection:'column',
        gap:'48px',
        justifyContent:'center',
        '@media(max-width:1920px)':{
            gap:'48px',
            minHeight:'100vh'
        },
        '@media(max-width:1600px)':{
            gap:'40px',
        },
        '@media(max-width:1280px)':{
            gap:'6vh'
        },
        '@media(max-width:959px)':{
            gap:'70px',
            minHeight:'auto'
        }
    },
    weAreAlsoWorldsBoxWrapper:{
        display: "flex",
        justifyContent: "center",
        
        padding: '70px 100px',
        '@media(max-width:1920px)':{
            minHeight:'30vh',
            padding: '0px 100px',
        },
        '@media(max-width:1600px)':{
            minHeight:'29.5vh',
            padding: '0px 100px',
        },
        '@media(max-width:1280px)':{
            minHeight:'29.5vh',
            padding: '0px 20px',
        },
        '@media (max-width: 959px)': {
            padding: '0px 20px',
            minHeight:'auto'
        },
    },

    textStyle: {
        wordWrap: 'break-word'
    },

    gatewayChildContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        marginTop:"88px",
        marginBottom:"88px",
        "& .secondaryMarketImage": {
            maxWidth: '470px',
            boxShadow: '-132px -64px rgba(0, 230, 68, 0.25)'
        },
        "& .secondaryMarketBox": {
            width: '100%',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            '& img':{
                width:'167px',
                height:'60px',
                '@media(max-width:1920px)':{
                    width:'167px',
                    height:'60px'
                },
                '@media(max-width:1600px)':{
                    width:'149px',
                    height:'50px'
                },
                '@media(max-width:1280px)':{
                    width:'117px',
                    height:'42px'
                },
            },            
            '& ul': {
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '36px',
                margin: '0px'
            }
        },
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600
        },
        '@media(max-width:1920px)':{
            maxWidth:'1473px',
            gap:'125.88px',
            marginBottom:'140px',
            marginTop:'140px',
            marginLeft:'-120px',
            "& .secondaryMarketBox": {
                maxWidth:'756px',
                gap:"40px",
                marginTop:'-100px',
                '& li':{
                    fontSize:'25.6px',
                    lineHeight:'51.2px'
                }
            },
            "& .secondaryMarketImage": {
                maxWidth:'none',
                minHeight: '674px',
                borderRadius:'15.11px',
                width:'591px',
                boxShadow: '-174px -100px rgba(0, 230, 68, 0.25)'
    
            },
            "& .gatewayText": {
                fontSize:'56.89px',
                lineHeight:'85.34px',
                maxWidth:'708px'  
            },
            
        },
        '@media(max-width:1600px)':{
            maxWidth:'1228px',
            gap:'104.88px',
            marginTop:"127px",
            marginBottom:"127px",
            "& .gatewayText": {
                fontSize:'47.41px',
                lineHeight:'71.12px',
                maxWidth:'590px'
            },
            "& .secondaryMarketBox": {
                maxWidth:'630px',
                gap:"26px",
                '& li':{
                    fontSize:'21.33px',
                    lineHeight:'42.67px'
                }
            },
            "& .secondaryMarketImage": {
                minHeight: '562px',
                borderRadius:'12.59px',
                width:'493px',
                boxShadow: '-141px -83px rgba(0, 230, 68, 0.25)'
    
            },
        },
        '@media(max-width:1400px)':{
            marginLeft:'0px',
        },
        '@media(max-width: 1280px)': {
            maxWidth:'1035px',
            marginLeft:'-20px',
            gap:'88.51px',
            marginBottom:'87px',
            "& .gatewayText": {
                fontSize: '36px',
                lineHeight:'50px',
                maxWidth:'454px'
            },
            "& .secondaryMarketBox": {
                maxWidth:'530px',
                gap:'15px',
                '& li':{
                    fontSize:'16px',
                    lineHeight:'32px'
                }
            },
            "& .secondaryMarketImage": {
                minHeight: 'auto',
                borderRadius:'10.62px',
                width:'375px',
                boxShadow: '-120px -70px rgba(0, 230, 68, 0.25)'
    
            },
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            flexDirection: 'column',
            gap:'60px',
            "& .secondaryMarketBox": {
              paddingBottom: '0px',
              marginTop:'0'
            },
            "& .gatewayText": {
                lineHeight:'1.5'
            },
            marginLeft:'0'
        },
        '@media (max-width: 600px)': {
            "& .secondaryMarketImage": {
                width:'100%',
                minHeight:'auto'
            },
            "& .secondaryMarketBox": {
                width: 'auto',
            },
            "& .gatewayText": {
                fontSize: '32px',
            },
        },
    },

    secondaryLiquidity: {
        display: 'flex',
        justifyContent: 'center',
       
    },

    childContainerStyleWithBlueBackground: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        justifyContent:'space-evenly',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600,
            color: '#ffffff',
            maxWidth: '870px',
            textAlign:"center"
        },
        '@media(max-width:1920px)':{
            maxWidth:'none',
            "& .gatewayText": {
                fontSize: '60px',
                lineHeight:'90px',
                maxWidth:'1265px'
            },
            '& .link':{
                fontSize:'24px',
                lineHeight:'36px'
            }
        },
        '@media(max-width:1600px)':{
            "& .gatewayText": {
                fontSize: '50px',
                lineHeight:'75px',
                maxWidth:'1055px'
            },
            '& .link':{
                fontSize:'20px',
                lineHeight:'30px'
            }
        },
        '@media(max-width:1280px)':{
            "& .gatewayText": {
                fontSize: '36px',
                lineHeight:'50px',
                maxWidth:'814px'
            },
            '& .link':{
                fontSize:'16px',
                lineHeight:'24px'
            }
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            padding: '40px 0',
            "& .gatewayText": {
                fontSize: '32px',
                lineHeight:'1.5'
            },
        },
        '@media (max-width: 600px)': {
            "& .gatewayText": {
                fontSize: '24px',
            },
        },
    },
    childContainerStyleWithBackground: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        minHeight:'308px',
        margin:'0 auto',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600,
            color: '#ffffff',
            textAlign:"center"
        },
        '& .subtitle':{
            fontSize:'27px',
            lineHeight:'40.5px'
        },
        padding:'0',
        '@media(max-width:1920px)':{
            padding:'63.95px 179.86px',
            maxWidth:"none",
            minHeight:'28.4vh',
            "& .gatewayText": {
                fontSize: '60px',
                lineHeight:'90px',
                width:'892px'
            },
            '& .subtitle':{
                fontSize:'27px',
                lineHeight:'40.5px'
            },
        },
        '@media(max-width:1600px)':{
            minHeight:'28.4vh',
            padding:'42.29px 150px',
            "& .gatewayText": {
                fontSize: '50px',
                lineHeight:'75px',
                maxWidth:'743px'
            },
            '& .subtitle':{
                fontSize:'22.5px',
                lineHeight:'33.75px'
            },
        },
        '@media(max-width:1400px)':{
            "& .gatewayText": {
                maxWidth:'600px'
            },
        },
        '@media(max-width:1280px)':{
            minHeight:'auto',
            padding:'30.63px 120px',
            "& .gatewayText": {
                fontSize: '40px',
                lineHeight:'60px',
                maxWidth:'595px'
            },
            '& .subtitle':{
                fontSize:'17px',
                lineHeight:'27px'
            },
        },
        '@media(max-width:1200px)':{
            "& .gatewayText": {
                maxWidth:'400px'
            },
        },
        '@media (max-width: 959px)': {
            minHeight:'auto',
            flexWrap: 'wrap',
            padding: '40px 20px',
            "& .gatewayText": {
                fontSize: '32px',
                lineHeight:'1.5',
                maxWidth:'none'
            },
        },
        '@media (max-width: 600px)': {
            "& .gatewayText": {
                fontSize: '24px',
            },
        },
    },
    secondaryLiquidityText:{
        fontFamily: 'Lato',
        fontSize: '40px',
        fontWeight: 600,
        color: '#ffffff',
        maxWidth: 'none',
        textAlign:"center",
        '@media(max-width:1920px)':{
            fontSize: '60px',
            lineHeight: '72px'
        },
        '@media(max-width:1600px)':{
            fontSize: '50px',
            lineHeight: '60px'
        },
        '@media(max-width:1280px)':{
            fontSize: '36px',
            lineHeight: '40px'
        },
        '@media(max-width:959px)':{
            fontSize:'32px',
            lineHeight:'1.5'
        },
        '@media(max-width:600px)':{
            fontSize:'24px'
        }
    },
    whyInvestWrapper:{
        maxWidth: '1169px',
        minHeight:'100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600,
            color: '#ffffff',
            maxWidth: 'none',
            textAlign:"center"
        },
        "& .startupStyleBox": {
            display: 'flex',
            gap: '32px',
            marginTop: '12px',
            alignItems: 'flex-start',
            "& .startupStyle": {
                maxWidth: '371px',
                width: '100%',
                padding: '28px 28px 56px',
                gap: '24px',
                borderRadius: '21px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                background: 'rgba(229, 240, 248, 1)',
               
                '&.first':{
                    '@media(max-width:1920px)':{
                        top:'15px'
                    },
                    '@media(max-width:1600px)':{
                        top:'10px'
                    },
                    '@media(max-width:1280px)':{
                        top:'5px',
                    }
                },
                "&.second": {
                    background: 'rgba(0, 105, 188, 1)',
                    top: '-64px',
                    '@media(max-width:1920px)':{
                        top:'-66px'
                    },
                    '@media(max-width:1600px)':{
                        top:'-60px'
                    },
                    '@media(max-width:1280px)':{
                        top:'-56px'
                    }
                },
                "&.third": {
                    top: '-128px',
                    '@media(max-width:1920px)':{
                        top:'-150px',
                    },
                    '@media(max-width:1600px)':{
                        top:'-130px',
                    },
                    '@media(max-width:1280px)':{
                        top:'-108px',
                    }
                },
                "& .heading": {
                    wordWrap: 'break-word',
                    fontSize: '32px',
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    color: 'rgba(0, 105, 188, 1)'
                },
                
                '@media(max-width:1920px)':{
                    maxWidth:'none',
                    width:'492px',
                    padding:'42.59px',
                    gap:"8px",
                    boxSizing:'border-box',
                   '& .heading':{
                        fontSize:'41px',
                        lineHeight:'56px'
                    },
                    '& .gatewayText':{
                        fontSize:'60px',
                        lineHeight:'72px'
                    },
                    '& .description':{
                        fontSize:'24px',
                        lineHeight:'40.5px'
                    },
                  
                },
                '@media(max-width:1600px)':{
                    width:'410px',
                    padding:'35.49px',
                    gap:'8px',
                    borderRadius:'18px',
                    boxSizing:'border-box',
                    '& .heading':{
                        fontSize:'32px',
                        lineHeight:'44.5px',
                        width:'93%'
                    },
                    '& .gatewayText':{
                        fontSize:'50px',
                        lineHeight:'60px'
                    },
                    '& .description':{
                        fontSize:'18.5px',
                        lineHeight:'30.75px'
                    },
                   
                },
                '@media(max-width: 1300px)': {
                    width:'328px',
                    padding:'28.39px',
                },

                '@media(max-width: 1280px)': {
                    width:'328px',
                    padding:'28.39px',
                    borderRadius:'14px',
                    gap:'8px',
                    '& .heading':{
                        fontSize:'24px',
                        lineHeight:'34px',
                        width:'91%'
                    },
                    '& .gatewayText':{
                        fontSize:'40px',
                        lineHeight:'48px'
                    },
                    '& .description':{
                        fontSize:'15px',
                        lineHeight:'23px'
                    },
                  
                },
                '@media(max-width:959px)':{
                    maxWidth:'none',
                }
            },
            "& .imgArrows": {
                maxWidth:'896px'
            },
        },

        '@media(max-width:1920px)': {
            maxWidth: 'none',
            padding:'160px 0',
            "& .imgArrows": {
                maxWidth:'1075px'
            },
            '& .gatewayText': {
                fontSize: '60px',
                lineHeight: '72px'
            }
        },
        '@media(max-width:1600px)': {
            padding:"160px 0",
            "& .imgArrows": {
                maxWidth:'896px',
                height:'250px',
            },
            '& .gatewayText': {
                fontSize: '50px',
                lineHeight: '60px'
            }
        },
        '@media(max-width: 1280px)': {
            gap:'14px',
            padding:"24px 0",
            "& .imgArrows": {
                maxWidth: '711px',
                height:'196px'
            },
            '& .gatewayText': {
                fontSize: '40px',
                lineHeight: '48px'
            },
            
        },
        '@media(max-width:1100px)':{
            minHeight:'auto',
            "& .imgArrows": {
                display: 'none'
            },
            
            "& .startupStyleBox": {
                flexWrap: 'wrap',
                justifyContent: 'center',
                "& .startupStyle": {
                    "&.second": {
                        top: '0 !important'
                    },
                    "&.third": {
                        top: '0 !important'
                    },
                    "& .heading": {
                        fontSize: '26px !important',
                    }
                },
            },
        },
            
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            "& .gatewayText": {
                fontSize: '32px',
                lineHeight:'1.5'
            }
        },
        '@media (max-width: 600px)': {
            "& .gatewayText": {
                fontSize: '24px',
            },
            "& .startupStyleBox": {
                "& .startupStyle": {
                    "& .heading": {
                        fontSize: '22px !important',
                    }
                },
            },
        },
    },
    childContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600,
            color: '#ffffff',
            maxWidth: 'none',
            textAlign:"center"
        },
        "& .indianStartupImage": {
            maxWidth: '510px',
            width: '100%',
            maxHeight: '384px',
            position: 'absolute',
            right: '100px',
            transform: 'perspective(470px) rotateY(-20deg)'
        },
       
        '@media(max-width:1920px)': {
            maxWidth: 'none',
            "& .imgArrows": {
                maxWidth:'1075px'
            },
            "&.indianStartup": {
                maxWidth: '1569px',
                padding:'0 0 127px'
            },
            "& .indianStartupImage": {
                height:'520px',
                maxWidth: '747px',
                maxHeight:'none',
                transform: 'perspective(470px) rotateY(-14deg)'
            },
            '& .gatewayText': {
                fontSize: '60px',
                lineHeight: '72px'
            }
        },
        '@media(max-width:1600px)': {
            "& .imgArrows": {
                maxWidth:'896px'
            },
            "&.indianStartup": {
                maxWidth: '1307px',
                padding:'0 0 100px'
            },
            '& .gatewayText': {
                fontSize: '50px',
                lineHeight: '60px'
            },
            "& .indianStartupImage": {
                height:'430px',
                maxWidth: '600px',
                maxHeight:'none',
                right:"50px",
            },
        },
        '@media(max-width: 1280px)': {
            "& .imgArrows": {
                maxWidth: '717px'
            },
            "&.indianStartup": {
                maxWidth: '1046px',
                padding:'60px 0'
            },
            '& .gatewayText': {
                fontSize: '40px',
                lineHeight: '48px'
            },
            "& .indianStartupImage": {
                height:'360',
                maxWidth: '485px',
                maxHeight:'none',
                right:'20px'
            },
            
        },
        '@media(max-width:1100px)':{
            "& .imgArrows": {
                display: 'none'
            },
            
            "& .startupStyleBox": {
                flexWrap: 'wrap',
                justifyContent: 'center',
                "& .startupStyle": {
                    "&.second": {
                        top: '0 !important'
                    },
                    "&.third": {
                        top: '0 !important'
                    },
                    "& .heading": {
                        fontSize: '26px !important',
                    }
                },
            },
        },
            
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            "& .gatewayText": {
                fontSize: '32px',
                lineHeight:'1.5'
            },
           
      
            "&.indianStartup": {
                justifyContent: 'center'
            },
            "& .indianStartupImage": {
                order: 0,
                position: 'relative',
                right: '0px',
                transform: 'none',
                height:'auto'
            },
        },
        '@media (max-width: 600px)': {
            "& .gatewayText": {
                fontSize: '24px',
            },
            "& .startupStyleBox": {
                "& .startupStyle": {
                    "& .heading": {
                        fontSize: '22px !important',
                    }
                },
            },
        },
    },

    imgChildContainerWrapper:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        '@media(max-width:1920px)':{
            padding:'0 20px',
        }
    },

    imgChildContainerStyle: {
        width: '100%',
        maxWidth: '1169px',
        padding: '64px',
        display: 'flex',
        minHeight: '400px',
        backgroundImage: `url(${blackBg})`,
        backgroundSize:'100% 100%',
        backgroundRepeat: 'no-repeat',
        margin:'72px auto',
        '& .discoverText':{
            fontFamily: 'Lato',
            fontWeight: 600,
            fontSize: '48px',
            color: '#ffffff',
            marginBottom:'63px'
        },
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontWeight: 600,
            fontSize: '48px',
            color: '#ffffff'
        },
       
        '@media(max-width:1920px)':{
            maxWidth:'1558px',
            margin:'8.5vh auto',
            minHeight:'46.5vh',
            padding:'70px 85px',
            '& .discoverText':{
                maxWidth:'810px',
                lineHeight:'90px',
                fontSize: '60px',
                marginBottom:'63px'
            },
            '& .gatewayText':{
                fontSize:'64px',
                marginBottom:'53px',
                lineHeight:'76.8px',
            },
            
        },
        '@media(max-width:1600px)':{
            padding:'60px 71px 60px 77px',
            maxWidth:'1298px',
            margin:'8.5vh auto',
            minHeight:'46.5vh',
            '& .discoverText':{
                maxWidth:'764px',
                lineHeight:'75px',
                fontSize: '50px',
                marginBottom:'53px'
            },
            '& .gatewayText':{
                fontSize:'53.33px',
                lineHeight:'64px'
            },
            
        },
        '@media(max-width:1280px)':{
            maxWidth:'1039px',
            padding:'40px 56px',
            minHeight:'auto',
            margin:'54px auto',
           
            '& .discoverText':{
                fontSize: '36px',
                maxWidth:'550px',
                lineHeight:'50px',
                marginBottom:'36px'
            },
            '& .gatewayText':{
                lineHeight:'51.2px',
                fontSize:'42.67px',
            },
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            padding: '44px',
            minHeight: 'auto',
            margin:'0',
            "& .gatewayText": {
                lineHeight:'1.5',
                fontSize: '40px',
            },
            '& .discoverText':{
                lineHeight:'1.5',
                maxWidth:'none',
                fontSize:'32px'
            },
        },
        '@media (max-width: 600px)': {
            padding: '24px',
            "& .gatewayText": {
                fontSize: '28px',
            },
        },
    },


    imgChildContainerStyleHarness: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        padding: '64px',
        backgroundImage: `url(${blackBg})`,
        minHeight: '400px',
        backgroundSize:'100% 100%',
        backgroundRepeat: 'no-repeat',
        margin:'0px auto',
        '& .black-content-wrapper':{
            gap:'16px'
        },
        '& .pretext':{
            fontSize:'24px'
        },
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600,
            color: '#ffffff'
        },
        '& .discoverText':{
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600,
            color: '#ffffff',
            marginBottom:'63px'
        },
        '@media(max-width:1920px)':{
            maxWidth:'1558px',
            minHeight:'39.6vh',
            padding:'80px 85px 80px 85px',
            margin:'0 auto',
            '& .gatewayText':{
                fontSize:'64px',
                lineHeight:'76.8px',
                marginBottom:'60px'
            },
            '& .discoverText':{
                maxWidth:'810px',
                fontSize: '60px',
                lineHeight:'90px',
                marginBottom:'63px'
            },
        },
        '@media(max-width:1600px)':{
            maxWidth:'1298px',
            padding:'60px 71px 60px 77px',
            
            '& .gatewayText':{
                fontSize:'53.33px',
                lineHeight:'64px',
                marginBottom:'53px'
            },
            '& .discoverText':{
                maxWidth:'764px',
                fontSize: '50px',
                lineHeight:'75px',
                marginBottom:'53px'
            },
        },
        '@media(max-width:1280px)':{
            maxWidth:'1039px',
            padding:'40px 56px',
            '& .black-content-wrapper':{
                gap:'8px'
            },
            '& .pretext':{
                fontSize:'21px'
            },
            '& .gatewayText':{
                fontSize:'38.67px',
                lineHeight:'51.2px',
                marginBottom:'36px'
            },
            '& .discoverText':{
                maxWidth:'610px',
                fontSize: '40px',
                lineHeight:'60px',
                marginBottom:'43px'
            },
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            padding: '44px',
            minHeight: 'auto',
            "& .gatewayText": {
                fontSize: '40px',
                lineHeight:'1.5'
            },
            '& .discoverText':{
                maxWidth:'none',
                lineHeight:'1.5',
                fontSize:'32px'
            },
        },
        '@media (max-width: 600px)': {
            padding: '24px',
            "& .gatewayText": {
                fontSize: '28px',
            },
        },
    },

    discoverBenefitsBox: {
        display: "flex",
        justifyContent: "center",
        alignItems:'center',
        '@media(max-width:1920px)':{
            minHeight:'100vh',
            margin:'105px 0 105px 0'
        },
        '@media(max-width:1600px)':{
            margin:'75px 0'
        },
        '@media(max-width:1280px)':{
            margin:'60px 0'
        },
        '@media (max-width: 959px)': {
            padding: '0px',
            minHeight:'auto',
            margin:'70px 0'
        }
    },

    discoverBenefits: {
        maxWidth: '1169px',
        display:'flex',
        justifyContent:'center',
        width: '100%',
        flexWrap: "nowrap",
        "& img": {
            width: '367px'
        },
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600,
            maxWidth: '630px'
        },
        '@media(max-width:1920px)':{
            maxWidth:'1527px',
            minHeight:'85vh',
            "& .gatewayText": {
                fontSize:'58px',
                lineHeight:'88.99px'
            },
            '& .scroll-box':{
                minWidth:'825px',
                width:'100%',
                '& .boxBg':{
                    boxSizing:'border-box',
                    minHeight:'28.3vh',
                    padding:"34px 0"
                },
            },
            '& ul':{
                maxWidth:'563px',
                margin:'10px 0 0 0'
            },
            '& li':{
                fontSize:'20.76px',
                lineHeight:'38.53px'
            },
            '& .title li:nth-child(n)':{
                fontSize:"29.66px",
                lineHeight:'44.49px',
                fontFamily:'Roboto',
                fontWeight:'800'
            }
        },
        '@media(max-width:1600px)':{
            maxWidth:'1273px',
            '& ul':{
                maxWidth:'469px',
                margin:'10px 0 0 0'
            },
            "& .gatewayText": {
                fontSize:'49.44px',
                lineHeight:'74.16px'
            },
            '& .scroll-box':{
                minWidth:'687px',
                '& .boxBg':{
                    padding:"25.5px 0"
                },
            },
            '& li':{
                fontSize:'17.3px',
                lineHeight:'30.61px'
            },
            '& .title li:nth-child(n)':{
                fontSize:'24.72px',
                lineHeight:'37.08px',
            }
        },
        '@media(max-width:1300px)':{
            padding:"0 20px",
            boxSizing:'content-box'
        },
        '@media(max-width:1280px)':{
            maxWidth:'1030px',
            '& ul':{
                maxWidth:'380px',
                margin:'0'
            },
            "& .gatewayText": {
                fontSize:'40px',
                lineHeight:'60px'
            },
            '& .scroll-box':{
                minWidth:'556px',
                '& .boxBg':{
                    padding:"16px 0"
                },
            },
            '& li':{
                fontSize:'14px',
                lineHeight:'26px'
            },
            '& .title li:nth-child(n)':{
                fontSize:'20px',
                lineHeight:'30px',
            },
        },
        '@media (max-width: 1200px)': {
            padding:'0 20px',
            minWidth:'auto',
            minHeight:'auto',
            '& ul':{
                minWidth:'auto',
            },
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            justifyContent: 'center',
            '& .scroll-box':{
                maxWidth:'none',
                minWidth:'auto',
                '& .boxBg':{
                    minHeight:'auto'
                },
            },
            '& .onScroll':{
                width:'100%'
            },
            "& .gatewayText": {
                fontSize: '40px',
                maxWidth:'none',
                lineHeight:'1.5'
            },
        },
        '@media (max-width: 600px)': {
            padding: '0 24px',
            "& .gatewayText": {
                fontSize: '28px',
            },
            "& img": {
                width: '250px'
            },
        },
    },

    discoverWrapper:{
        display:'flex',
        flexDirection:'column',
        
        '@media(max-width:1920px)':{
            minHeight:'100vh',
        },        
        '@media(max-width:959px)':{
            gap:'70px',
            minHeight:'auto'
        },
        '@media(max-width:700px)':{
            
        }
    },

    indianStartupStyle: {
        backgroundImage: `url(${xlImage})`,
        width: '100%',
        maxWidth: '1077px',
        display:'flex',
        alignItems:'center',
        
        backgroundSize: 'cover',
        backgroundRepeat: 'round',
        "& .displayBox": {
            display: 'flex',
            flexDirection: 'column',
            gap: '0px',
            maxWidth: '50%',
            width: '100%',
            padding: '32px 32px 32px 80px',
            "& .gatewayText": {
                fontFamily: 'Lato',
                fontSize: '48px',
                fontWeight: 600,
                textAlign: 'start',
                '@media(max-width:1920px)':{
                    fontSize:'63px',
                    lineHeight:'94.5px'
                },
                '@media(max-width:1600px)':{
                    fontSize:'52.5px',
                    lineHeight:'78.75px'
                },
                '@media(max-width:1280px)':{
                    fontSize:'42px',
                    lineHeight:'63px'
                },
                '@media(max-width:959px)':{
                    fontSize:'32px',
                    lineHeight:'1.5'
                }
            },
            '& .description':{
                fontSize:'24px',
                lineHeight:'36px',
                color:'#F2F2F2',
                '@media(max-width:1600px)':{
                    fontSize:'20px',
                    lineHeight:'30px'
                },
                '@media(max-width:1280px)':{
                    fontSize:'16px',
                    lineHeight:'24px'
                }
            }
        },
       
        '@media(max-width:1920px)':{
            backgroundImage: `url(${xlImage})`,
            maxWidth:'1417px',
            minHeight:'774px',
        },
        '@media(max-width:1600px)':{
            backgroundImage: `url(${lgImage})`,
            maxWidth:'1181px',
            minHeight:'645px',
            '& .displayBox':{
                padding: '32px 32px 32px 68px',
            }
        },
        '@media (max-width: 1280px)': {
            backgroundImage: `url(${mdImage})`,
            maxWidth:'945px',
            minHeight:'515px',
            "& .displayBox": {
                maxWidth: '490px',
                padding: '32px 32px 32px 50px'
            },
        },
        '@media (max-width: 959px)': {
            order: 1,
            transform: 'none',
            backgroundRepeat: 'space',
            marginLeft: "0px",
            minHeight:'474px',
            "& .displayBox": {
                padding: '32px',
                width: 'auto',
                maxWidth: 'none',
                "& .gatewayText": {
                    fontSize: '40px !important',
                    lineHeight:'1.5'
                },
            },
        },
        '@media (max-width: 600px)': {
            "& .displayBox": {
                padding: '32px',
                width: 'auto',
                maxWidth: 'none',
                "& .gatewayText": {
                    fontSize: '26px !important',
                },
            },
        },
    },

    lastChildContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        padding: '64px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        background: 'black',
        borderRadius: '64px 0px 64px 0px',
        "& .seamlessText": {
            fontFamily: 'Lato',
            textAlign: 'center',
            fontSize: '36px',
            fontWeight: 600,
            color: '#ffffff'
        },
        '@media(max-width:1920px)':{
            maxWidth:'1476px',
            minHeight:'auto',
            "& .seamlessText": {
                fontSize:'60.61px',
                lineHeight:'90.91px',

            }
        },
        '@media(max-width:1600px)':{
            maxWidth:'1230px',
            minHeight:'auto',
            "& .seamlessText": {
                fontSize:'50.5px',
                lineHeight:'75.76px'
            }
        },
        '@media(max-width:1280px)':{
            maxWidth:'1030px',
            minHeight:'auto',
            "& .seamlessText": {
                fontSize:'42.29px',
                lineHeight:'63.44px'
            }
        },
        '@media (max-width: 959px)': {
            padding: '40px',
            minHeight:'auto',
            "& .seamlessText": {
                fontSize:'42.29px',
                lineHeight:'1.5'
            }
        },
        '@media(max-width:768px)':{
            margin:'70px 0'
        },
        '@media (max-width: 600px)': {
            "& .seamlessText": {
                fontSize: '24px',
            },
        }
    },

    lastChildImgContainerStyle: {
        alignItems:"center",
        minHeight: '34.7vh',
        width: '100%',
        display: 'flex',
        backgroundPosition:'75%',
        flexDirection:"row",
        flexWrap:"nowrap",
        justifyContent: 'space-between',
        padding: '64px',
        backgroundImage: `url(${blueBg})`,
        backgroundRepeat:"no-repeat",
        gap: "20px",
        '& .laptop':{
            display:'block',
            marginLeft:'auto',
        },
        "& .allInOneText": {
            fontFamily: 'Lato',
            fontSize: '36px',
            fontWeight: 600,
            maxWidth:"932px",
            color: '#ffffff'
        },
        '@media(max-width:1920px)':{
            padding: '50px 166px 20px',
            boxSizing:'border-box',
            '& .allInOneText':{
                fontSize:'50px',
                width:'92%',
                lineHeight:'76px'
            },
            '& .laptop':{
                width:'400px',
            }
        },
        '@media(max-width:1600px)':{
            minHeight:'auto',
            padding: '50px 145px 20px',
            '& .allInOneText':{
                fontSize:'40px',
                lineHeight:'60px',
                width:'73%'
            },
            '& .laptop':{
                width:'310.94px',
            }
        },
        '@media(max-width:1280px)':{
            minHeight:'auto',
            padding: '40px 112px 20px',
            '& .allInOneText':{
                fontSize:'28px',
                lineHeight:'43px',
                width:'58%'
            },
            '& .laptop':{
                width:'200px',
            }
        },
        '@media(max-width:1200px)':{
            padding: '20px 100px',
        },
        '@media (max-width: 959px)': {
            padding: '40px',
            flexWrap:"wrap",
            '& .imgDevice':{
                width:'100%',
                display:'flex',
                justifyContent:'center'
            },
            '& .laptop':{
                margin:'0 auto',
                display:"block"
            },
            '& .allInOneText':{
                fontSize:'32px',
                width:'100%',
                lineHeight:'1.5'
            }
        },
        '@media (max-width: 600px)': {
            "& .allInOneText": {
                fontSize: '24px',
            },
        }
    },

    lastChildInvestBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: 'black',
        width: '100%',
        background: '#ffffff',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '153px',
        '&:hover': {
            background: '#ffffff',
        },
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06)',
        '@media(max-width:1920px)':{
            maxWidth:'none',
            width:'286px'
        },
        '@media(max-width:1600px)':{
            width:'238px'
        },
        '@media(max-width:1280px)':{
            width:'200px'
        }
    },
    lastChildSecondBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: '#ffffff',
        width: '100%',
        background: 'black',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '153px',
        border: '1px solid rgba(255, 255, 255, 1)',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06)',
        '@media(max-width:1920px)':{
            maxWidth:'none',
            width:'286px'
        },
        '@media(max-width:1600px)':{
            width:'238px'
        },
        '@media(max-width:1280px)':{
            width:'200px'
        }
    },

    spanStyle: {
        fontStyle: 'italic',
        color: 'rgba(0, 105, 188, 1)',
        fontWeight: 800
    },

    textFormate: {
        wordWrap: 'break-word'
    },
    leftGroupFrameStyle: {
        borderWidth: '2px 2px 1px 0px',
        borderStyle: 'solid',
        borderColor: '#0069BC',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'start',
        padding: '30px 0',
        '@media(max-width:1920px)':{
            padding: '30px 0',
            maxWidth:'702px',
            width:'100%',
            '& img':{
                width:'400px',
                height:'auto'
            }
        },
        '@media(max-width:1600px)':{
            padding: '24px 0',
            maxWidth:'585px',
            '& img':{
                width:'300px',
                height:'auto'
            }
        },
        '@media(max-width:1280px)':{
            padding: '20px 0',
            maxWidth:'473px',
            '& img':{
                width:'200px',
                height:'auto'
            }
        },
        '@media(max-width:959px)':{
            maxWidth:'none',
            width:'100%',
            '& img' :{
                display:'none'
            }
        }
    },
    investBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: '#ffffff',
        width: '100%',
        background: 'rgba(0, 105, 188, 1)',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '319px',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        },
        '@media(max-width:1920px)':{
            maxWidth:'none',
            width:'266px',
            height:'80px',
            fontSize:'26.67px',
            lineHeight:'32.27px'
        },
        '@media(max-width:1600px)':{
            width:'222px',
            height:'60px',
            fontSize:'22.22px',
            lineHeight:'1.5'
        },
        '@media(max-width:1280px)':{
            width:'177px',
            height:'52px',
            fontSize:'17px'
        }
    }
    // Customizable Area End
};

export class SecondariesInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { liquidityData, startupMarketData, token, secondaryCompanyData, indianStatupData, earlyOpportunityData, secondaryCardData, secondaryMarketData } = this.state;
        const { classes } = this.props;

        const currentDate = new Date();
        const month = currentDate.toLocaleString('default', { month: 'long' });
        const date = month + ' ' + currentDate.getDate() + ',' + currentDate.getFullYear();
        // Customizable Area End
        return (
            // Customizable Area Start
            <Paper style={{ minHeight: '100vh', overflowX: 'hidden' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titleTextBoxStyle}>
                        <img src={imgShape} style={{
                            top: '-15px',
                            right: '15px',
                            position: 'absolute',
                        }} />
                        <Typography className={classes.headerTextBoxStyle}>
                            <span className={classes.futureTextBoxStyle}> Redefining </span>Venture Capital Secondary Market
                        </Typography>
                    </Box>
                </Box>

                <Grid container className={classes.containerStyle}>
                    {secondaryMarketData && <Grid container className={classes.gatewayChildContainerStyle}>
                        <img src={secondaryMarketData[0]?.attributes.image} className='secondaryMarketImage' />
                        <Box className='secondaryMarketBox'>
                            <img src={navLogo} height="100%" width="100%" />
                            <Typography className="gatewayText">
                                A Gateway to the <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }}>Secondary Market</span> for Startups and Investors
                            </Typography>
                            <ul>
                                {secondaryMarketData[0]?.attributes.venture_capital_contents.map((item: any) => (
                                    <li >{item.content}</li>
                                ))}
                            </ul>
                        </Box>
                    </Grid>}
                </Grid>

            <Box className={classes.blueBlackBoxWrapper} >
                <Grid container className={classes.weAreAlsoWorldsBoxWrapper} style={{ background: 'linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)' }}>
                    <Grid container className={classes.childContainerStyleWithBlueBackground}>
                        <Typography className="gatewayText">
                            We are also the <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 230, 68, 1)' }}>world's leading</span> digital platform for Indian startup secondaries.
                        </Typography>
                        {token === null ?
                            <Typography className='link' style={{ fontFamily: 'Roboto', fontWeight: 500, color: '#ffffff', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.navigation.navigate('EmailAccountRegistrationWeb')}>Learn more</Typography> : ''
                        }
                    </Grid>
                </Grid>
                <Grid container className={classes.imgChildContainerWrapper} >
                    <Grid container className={classes.imgChildContainerStyleHarness}>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box className="black-content-wrapper" style={{ display: 'flex', flexDirection: 'column'}}>
                                <Typography className="pretext" style={{ fontFamily: 'Lato', color: '#ffffff', fontWeight: 400 }}>
                                    From paper gains to real returns:
                                </Typography>
                                <Typography className="gatewayText">
                                    Harnessing the power of <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }} >secondary liquidity</span>
                                </Typography>
                            </Box>
                            <Button onClick={this.navigateToLiquidate} className={classes.investBtnStyle}>
                                Liquidate
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={classes.secondaryLiquidity}>
                    <Grid container className={classes.childContainerStyle} style={{ flexDirection: 'row',justifyContent:"center" }}>
                        <Typography className={classes.secondaryLiquidityText} style={{ color: "#222222", maxWidth: 'none',padding:'0 20px' }}>
                            Secondary Liquidity = <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }}>Value Add</span> and <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 230, 68, 1)' }}>Empowerment</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

                <Grid container className={classes.discoverBenefitsBox}>
                    <Grid container className={classes.discoverBenefits}>
                        <Grid item className={classes.leftGroupFrameStyle} >
                            <Typography className="gatewayText">
                                Discover the benefits of <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>secondary liquidity</span> for your financial journey.
                            </Typography>
                            <img src={imageShapeTwo} alt="" />
                        </Grid>

                        <Grid item className="onScroll">
                            {liquidityData && liquidityData.map((dataItem: any, index: any) =>
                            <Box className={"scroll-box"}>
                                <Box style={{ display: 'flex', flexDirection: 'column', paddingLeft: '32px', justifyContent: 'center', border: '1px solid #0069BC', borderColor: '#0069BC', borderStyle: 'solid', borderWidth: '2px 0px 1px 1px',
                                background: index === this.state.activeIndex ? '#0069BC' : ''
                                }} className="boxBg">
                                    <Typography className="title" style={{ color: index === this.state.activeIndex ? '#FFFFFF' : '#222222', fontFamily: 'Lato', fontWeight: 800, fontSize: '24px' }}><ol start={index + 1} style={{ margin: '0px' }}><li>{liquidityData[index]?.attributes.title}</li></ol></Typography>
                                    <ul style={{ fontFamily: 'Roboto', color: index === this.state.activeIndex ? '#FFFFFF' : 'rgba(130, 130, 130, 1)', fontSize: '16px', fontWeight: 400, display: 'flex', flexDirection: 'column' }}>
                                        {dataItem?.attributes.contents.map((item: any) => (
                                            <li className={classes.textFormate} >{item.content}</li>
                                        ))}
                                    </ul>
                                </Box>
                            </Box>
                            )}
                        </Grid> 
                    </Grid>
                </Grid>
                <Box className={classes.discoverWrapper}>
                    <Box className={classes.imgChildContainerWrapper}>
                        <Grid container className={classes.imgChildContainerStyle}>
                            <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Typography className="discoverText">
                                    Discover New Frontiers in <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }} >Startup Secondary Investments</span>
                                </Typography>
                                <Button className={classes.investBtnStyle} onClick={this.navigateToInvest} data-test-id="investBtn">
                                    Invest
                                </Button>
                            </Box>
                        </Grid>
                    </Box>

                    <Grid container style={{ backgroundColor: 'rgba(242, 242, 242, 1)' }}>
                        <Grid container className={classes.childContainerStyleWithBackground} style={{ flexDirection: 'row' }}>
                            <Box style={{ width: '100%' }}>
                                <Typography className="gatewayText" style={{ color: '#222222', textAlign: 'start' }}>
                                    Now, you have the <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }}>opportunity</span> <br/> to get in early!
                                </Typography>
                            </Box>
                            <Box style={{ width: '100%' }}>
                                {earlyOpportunityData && <Typography className="subtitle" style={{ fontFamily: 'Lato', fontWeight: 400, wordBreak: 'break-word' }}>
                                    {earlyOpportunityData[0]?.attributes.venture_capital_contents[0].content}
                                </Typography>}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.whyInvestWrapper}>
                        <Typography className="gatewayText" style={{ color: '#222222' }}>
                            Why invest in <span className={classes.spanStyle} >Startup Secondaries</span>?
                        </Typography>
                        <Grid item style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={imgArrows} className="imgArrows" alt="" style={{ width: '100%' }} />
                            {startupMarketData && <Box className="startupStyleBox">
                                <Box className="startupStyle first">
                                    <Typography className="heading">
                                        {startupMarketData[1]?.attributes.title}
                                    </Typography>
                                    <Typography className="description" style={{ wordWrap: 'break-word', fontFamily: 'Roboto', fontWeight: 400 }} >
                                        {startupMarketData[1]?.attributes.content}
                                    </Typography>
                                </Box>
                                <Box className="startupStyle second">
                                    <Typography className="heading" style={{ color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupMarketData[2]?.attributes.title}
                                    </Typography>
                                    <Typography className="description" style={{ wordWrap: 'break-word', fontFamily: 'Roboto', fontWeight: 400, color: '#FFFFFF' }} >
                                        {startupMarketData[2]?.attributes.content}
                                    </Typography>
                                </Box>
                                <Box className="startupStyle third">
                                    <Typography className="heading">
                                        {startupMarketData[0]?.attributes.title}
                                    </Typography>
                                    <Typography className="description" style={{ wordWrap: 'break-word', fontWeight: 400, fontFamily: 'Roboto', }} >
                                        {startupMarketData[0]?.attributes.content}
                                    </Typography>
                                </Box>
                            </Box>}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{ display: "none", justifyContent: 'space-around' }}>
                    <Typography style={{ color: "rgb(130, 130, 130)", fontFamily: 'Roboto', fontSize: '25px', fontWeight: 600 }}> Most viewed companies as of <span> {date}</span></Typography>
                </Grid>

                <Grid container className={classes.containerStyle} style={{ display: 'none', gap: '15px', background: "rgba(242, 248, 252, 1)" }} >
                    <Box style={{ maxWidth: '1169px', width: '100%', display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'center' }}>
                        {secondaryCompanyData?.slice(0, 8).map((data: any) => (
                            <Grid style={{ display: 'flex', flexDirection: 'column', borderRadius: "8px", width: '264px', padding: '8px', gap: '20px', background: "rgba(242, 248, 252, 1)", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', height: '55px' }}>
                                    <Typography style={{ fontFamily: 'Lato', fontWeight: 600, fontSize: '35px', }}>{data.attributes.company.data[0]?.attributes.company_name}</Typography>
                                    <img src={data.attributes.company.data[0]?.attributes.image} style={{ height: '50px', width: '50px' }} />
                                </Grid>
                                <Grid style={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto', color: "rgb(130, 130, 130)" }}>Sector</Typography>
                                    <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', color: 'rgb(0, 230, 68)' }}>{data.attributes.company.data[0]?.attributes.company_sector.sector}</Typography>
                                </Grid>

                                <Grid style={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto', color: "rgb(130, 130, 130)" }}>{data.attributes.pre_money_valuation && ("Last Round Est. Valuation")}</Typography>
                                    <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', color: 'black' }}>{data.attributes.pre_money_valuation}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Grid>


                <Grid container className={classes.containerStyle} >
                    {indianStatupData && <Grid container className={`${classes.childContainerStyle} indianStartup`} style={{ flexDirection: 'row', position: 'relative' }}>
                        <Box className={classes.indianStartupStyle}>
                            <Box className="displayBox">
                                <Box>
                                    <Typography className="gatewayText" style={{ color: '#ffffff' }}>Indian</Typography>
                                    <Typography className="gatewayText" style={{ fontWeight: 800, color: 'rgba(0, 230, 68, 1)',fontStyle:'italic' }}>Startup Secondaries</Typography>
                                </Box>
                                <pre className="description" style={{ fontFamily: 'Roboto', fontWeight: 400, color: '#ffffff', whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{indianStatupData[0]?.attributes.venture_capital_contents[0].content}</pre>
                            </Box>
                        </Box>
                        <img src={indianStatupData[0]?.attributes.image} alt="" className="indianStartupImage" />
                    </Grid>}
                </Grid>

                <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container className={classes.childContainerStyle}>
                        <Grid item className={classes.allInOneStyle}>
                            {secondaryCardData && <Box className="allInOneBox">
                                <Box className="profileBoxStyle first">
                                    <img src={secondaryCardData[0]?.attributes.image} className="founder" />
                                    <Typography className="description founder-first" >
                                        A Founder, Employee, or Early Investor in search of <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }}>Liquidity</span>
                                    </Typography>
                                </Box>
                                <Box className="profileBoxStyle second">
                                    <img src={secondaryCardData[1]?.attributes.image} className="founder" style={{ borderRadius: '50%', maxWidth:'216px'  }} />
                                    <Typography className="description" >
                                        An Investor looking for lucrative <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }}>Secondary Opportunities</span>
                                    </Typography>
                                </Box>
                            </Box>}
                            <img src={xlArrowImage} className="arrow" alt="Responsive Image"/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container style={{ background: 'linear-gradient(100.52deg, #0086F0 0%, #0069BC 100%)', display: 'flex', justifyContent: 'center' }} >
                    <Grid container className={classes.lastChildImgContainerStyle} style={{ position: 'relative' }}>
                       <Grid style={{display:"flex",justifyContent:"start"}}>
                       <Typography className="allInOneText" >
                            Our  <span style={{ fontWeight: 800, color: 'rgba(0, 230, 68, 1)', fontStyle: 'italic' }} >all-in-one platform</span> is meticulously designed to cater to both of your unique requirements.
                        </Typography>
                       </Grid>
                       <Box className="imgDevice">
                        <img src={imgDevice} className="laptop" />
                       </Box>
                    </Grid>
                </Grid>


                <Grid container className={classes.containerStyle} >
                    <Grid container className={classes.lastChildContainerStyle}>
                        <Box style={{ width: '100%', display: 'flex',justifyContent:'center', flexDirection: 'column', gap: '48px', alignItems: 'center' }}>
                            <Typography className="seamlessText">
                                A seamless experience  in the dynamic world of <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }} >Indian startups</span>.
                            </Typography>
                            <Box style={{ display: 'flex', gap: '30px',justifyContent:'center', width: '100%' }}>
                                <Button className={classes.lastChildInvestBtnStyle} onClick={this.navigateToInvest}>Invest</Button>
                                <Button className={classes.lastChildSecondBtnStyle} onClick={this.navigateToLiquidate}>Liquidate</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(SecondariesInvestmentWeb);
// Customizable Area End
