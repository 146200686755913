export const imgFund = require('../assets/image_fund.png')
export const imgArrows = require('../assets/image_arrows.png')
export const imgZero = require('../assets/textlabel_0.svg')
export const imgOne = require('../assets/textlabel_1.svg')
export const imgTwo = require('../assets/textlabel_2.svg')
export const imgThree = require('../assets/textlabel_3.svg')
export const imgFour = require('../assets/textlabel_4.svg')
export const imgFive = require('../assets/textlabel_5.svg')
export const imgBigZero = require('../assets/text_0.svg')
export const imgBigOne = require('../assets/text_1.svg')
export const imgBigTwo = require('../assets/text_2.svg')
export const imgBigThree = require('../assets/text_3.svg')
export const blackBg = require('../assets/blackBG.png')
export const blueBg = require('../assets/blueBG.png')
export const ImgCompanies = require('../assets/image_companies.png')
export const imgDevice = require('../assets/image_devices.png')
export const imgTop = require('../assets/topImg.png')
export const avtar = require('../assets/image_avatar.png')
export const avtar1 = require('../assets/image_avatar1.png')
export const downConnecter = require('../assets/image_connectors.svg')
export const slide = require('../assets/slide.png')
export const startupSecondaries = require('../assets/startup_secondaries.svg')
export const imageFund = require('../assets/image_fund (1).png')
export const viewRectangle = require('../assets/view_rectangle.png')
export const imageLock = require('../assets/image_lock.png')
export const imageButtonClose = require('../assets/imagebutton_close.png')
export const imageDoc = require('../assets/doc.png')
export const imageDownload = require('../assets/download.png')
export const buttons = require('../assets/button.png')
export const groupBank = require('../assets/group_bank.png')
export const textInput = require('../assets/textinput.png')
export const checkMark = require('../assets/check-mark.png')
export const topImg = require('../assets/topImg.png')
export const imageLogo = require('../assets/imagelogo.png')
export const imageMemo = require('../assets/image_memo.png')
export const imageDeclaration = require('../assets/image_declaration.png')
export const groupCoinvestors = require('../assets/group_coinvestors.png')
export const graph = require('../assets/graph.png')
export const roundLogo = require('../assets/round.png')
export const allocation = require('../assets/allocation.png')
export const share = require('../assets/share.png')
export const premoney = require('../assets/premoney.png')
export const fees = require('../assets/fees.png')
export const background = require('../assets/background.png')
export const memo1 = require('../assets/memo_1.png')
export const imageArrow = require('../assets/image_arrow.png')
export const PitchDeckBackground = require('../assets/pitchDeck_background.png')
export const imageShape = require('../assets/image_shape.png')
export const chartImage = require('../assets/chart_image.png')
export const fundinvestment = require('../assets/fundinvestment.png')
export const secondaries = require('../assets/secondaries.png')
export const startupinvestment = require('../assets/startupinvestment.png')
export const latestinvestment = require('../assets/latestinvestment.png')
export const portfoliovalue = require('../assets/portfoliovalue.png')
export const released = require('../assets/released.png')
export const totalinvestment = require('../assets/totalinvestment.png')
export const pdfImage = require('../assets/pdfImage.png')
export const allocationLogo = require('../assets/allocation_Logo.png')
export const certificateAccessLogo = require('../assets/certificate_access.png')
export const documentLogo = require('../assets/document.png')
export const paymentLogo = require('../assets/payment.png')
export const pdfDocument = require('../assets/pdfDocument.png')
export const investment1 = require('../assets/investment1.png')
export const investment2 = require('../assets/investment2.png')
export const investment3 = require('../assets/investment3.png')
export const investment4 = require('../assets/investment4.png')
export const hand = require('../assets/hand.png')
export const defaultImage = require('../assets/file.png')
export const jpgImage = require('../assets/jpg.png')
export const jpegImage = require('../assets/jpeg.png')
export const pngImage = require('../assets/png.png')
export const zipImage = require('../assets/zip.png')
export const GroupArrowBig = require('../assets/image_ArrowBig.png')
export const  textSmall_0 = require('../assets/textlabel_0small.png')
export const textSmall_1 = require('../assets/textlabel_1small.png')
export const textSmall_02 = require('../assets/textlabel_2small.png')
export const textSmallthree = require('../assets/textlabel_3small.png')
export const xlImage = require('../assets/1920.svg')
export const lgImage = require('../assets/1600.svg')
export const mdImage = require('../assets/1280.svg')
export const xlArrowImage = require('../assets/1920_arrow.svg')
export const lgArrowImage = require('../assets/1600_arrow.svg')
export const mdArrowImage = require('../assets/1280_arrow.svg')