import React from "react";

// Customizable Area Start

import {
  Grid,
  FormControl,
  FormHelperText,
  Select,
  Dialog,
  DialogContent,
  MenuItem,
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  OutlinedInput,
  Divider,
} from "@material-ui/core";
import AntSwitch from "../../../components/src/AntSwitch.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import TopNavBar from "../../../components/src/TopNavbar.web";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { imgVerify } from "../../email-account-registration/src/assets";
import { imgError, imgVerified } from "../../../components/src/assets";
import Loader from "../../../components/src/Loader.web";
import {
  camera,
  changepassword,
  contactus,
  invpref,
  kyc,
  logout,
  notifications,
  selectedChangepassword,
  selectedInvpref,
  selectedKyc,
  selectedNotification,
  selectedPhone,
  selectedUser,
  user,
} from "../../settings2/src/assets";
const config = require("../../landingpage/src/config");
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
// Customizable Area End

import AccountSettingController, {
  Props,
} from "./AccountSettingController.web";
import { StyleRules, withStyles } from "@material-ui/styles";

const styles: StyleRules = {
  // Customizable Area Start
  leftContainer: {
    border: "1px solid #BDBDBD",
    borderRadius: "16px",
    padding: "32px",
    display: "flex",
    maxWidth:"306px",
    width:'100%',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    '@media(max-width:959px)':{
      width:'auto'
    }
  },
  errTxt: {
    fontWeight: 400,
    color: "#DC2626",
    maxWidth: "370px",
    width: "100%",
    fontSize: "14px",
    margin: "0px",
  },
  imgBtn: {
    background: "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)",
    height: "48px",
    borderRadius: "50%",
    display: "flex",
    width: "48px",
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    position: "absolute",
    right: 0,
  },
  verificationBox: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "533px",
  },
  messageBox: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    minHeight: "321px",
    alignItems: "center",
    position: "relative",
  },
  continueBtn: {
    height: "60px",
    color: "#FFFFFF",
    width: "153px",
    fontSize: "20px",
    backgroundColor: "#0069BC",
    fontFamily: "Roboto",
    fontWeight: 700,
    borderRadius: "12px",
    padding: "10px, 16px, 10px, 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0069BC",
    },
  },
  pwdBtn: {
    width: "224px",
    height: "60px",
    borderRadius: "12px",
    backgroundColor: "#0069BC",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Roboto",
    alignSelf: "end",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0069BC",
    },
    '@media(max-width:600px)':{
      width:'100%',
      height:'auto',
      fontSize:'16px'
    }
  },
  profileBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "306px",
    width: "100%",
  },
  tabBtn: {
    display: "flex",
    alignItems: "center",
    maxWidth: "306px",
    height: "60px",
    borderRadius: "8px",
    padding: "8px 24px",
    gap: "16px",
    cursor: "pointer",
    textTransform: "none",
  },
  contactBtn: {
    width: "153px",
    height: "60px",
    borderRadius: "12px",
    backgroundColor: "#0069BC",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Roboto",
    alignSelf: "end",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0069BC",
    },
    '@media(max-width:600px)':{
      width:'100%',
      height:'auto',
      fontSize:'16px'
    }
  },
  settingBox: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  investmentBox: {
    flexDirection: "column",
    display: "flex",
  },
  errorText: {
    color: "#DC2626",
    fontWeight: 400,
    fontSize: "14px",
    margin: "0px",
    width: "100%",
  },
  kycQuestion: {
    fontFamily: "Roboto",
    fontSize: "14px",
    color: "#828282",
  },
  kycAns: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 600,
  },
  dialogContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    minHeight: "250px",
    alignItems: "center",
  },
  cancleBtn: {
    height: "60px",
    width: "153px",
    border: "1px solid #0069BC",
    borderRadius: "12px",
    fontWeight: 700,
    color: "#0069BC",
    fontFamily: "Roboto",
    fontSize: "20px",
    textTransform: "none",
    '@media(max-width:600px)':{
      width:'100%',
      height:'auto',
      fontSize:'16px'
    }
  },
  dialogCloseBtn: {
    width: "238px",
    height: "60px",
    borderRadius: "12px",
    border: "1px solid #0069BC",
    color: "#0069BC",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Roboto",
    textTransform: "none",
    '@media(max-width:600px)':{
      width:'100%',
      height:'auto',
      fontSize:'16px'
    }
  },
  inputfile: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  dialogEventBtn: {
    width: "238px",
    height: "60px",
    borderRadius: "12px",
    background: "#0069BC",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Roboto",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0069BC",
    },
    '@media(max-width:600px)':{
      width:'100%',
      height:'auto',
      fontSize:'16px'
    }
  },
  rightContainer: {
    border: "1px solid #BDBDBD",
    borderRadius: "16px",
    padding: "32px",
    display: "flex",
    flexDirection: "column",

  },
  inputBtn: {
    maxWidth: "273px",
    width: "100%",
    borderRadius: "8px",
    height: "48px",
    "&.MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(0, 105, 188)",
      },
    },
  },
  dropDownBtn: {
    width: "60px",
    borderRadius: "8px",
    height: "48px",
    "&.MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(0, 105, 188)",
      },
    },
  },
  autocomplate: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      height: "48px",
      width: "auto",
    },
  },
  firstNameWrapper:{
    display:"flex", 
    justifyContent:"space-between",
    maxWidth:"650px",
    '@media(max-width:600px)':{
      flexDirection:'column',
      rowGap:'15px',
    }
  },
  email:{
    width:'50%',
    '@media(max-width:600px)':{
      width:'100%'
    }
  },
  leftRightSectionWrapper: {
    gap: "40px",
    padding: "50px 0px",
    display: 'flex',
    '@media(max-width:959px)': {
      width: '100%',
      padding:'0 0 50px 0'
    }
  },
  updateProfileBtnWrapper:{
    width: "336px",
    display: "flex",
    justifyContent: "space-between",
    '@media(max-width:600px)': {
      width: '100%',
      gap:'20px',
      padding:'0 0 50px 0'
    }
  },
  modalTitle:{ 
    fontSize: "32px", 
    fontWeight: 800,
     fontFamily: "Lato" ,
     '@media(max-width:600px)': {
      fontSize:'20px'
     }
    },
    verifyYourEmailTitle:{
      fontSize: "32px",
      fontWeight: 700,
      color: "#222222",
      fontFamily: "Lato",
      textAlign: "center",
      '@media(max-width:600px)': {
        fontSize:'20px'
       }
    },
    verificationResult:{
      fontWeight: 700,
      color: "#222222",
      fontSize: "32px",
      textAlign: "center",
      '@media(max-width:600px)': {
        fontSize:'20px'
       }
    },
    deleteAccountBtns:{
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      '@media(max-width:600px)': {
        flexDirection:'column',
        gap:'10px'
       }
    },
    logoutBtnsWrapper:{
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      '@media(max-width:600px)': {
        gap:'20px'
       }
    },
    profileName:{
      fontSize: "24px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width:'100%',
      textAlign:'center',
      fontFamily: "Roboto",
      color: "#222222",
      fontWeight: 700,
      margin: "16px 0px",
      textTransform: "capitalize",
      '&:hover':{
        whiteSpace: "normal",
      }
    }
  // Customizable Area End
};

export class AccountSettingWeb extends AccountSettingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  logoutDialog = () => {
    const { classes } = this.props;
    const { logoutOpen } = this.state;
    return (
      <Dialog
        open={logoutOpen}
        onClose={this.handleLogoutClose}
        PaperProps={{
          style: {
            borderRadius: 16,
            maxWidth: "570px",
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <Box className={classes.dialogContainer}>
            <Typography
              className={classes.modalTitle}
            >
              {"Logout?"}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                fontFamily: "Roboto",
              }}
            >
              Are you sure you want to logout?
            </Typography>
            <Box
              className={classes.logoutBtnsWrapper}
            >
              <Button
                onClick={this.handleLogoutClose}
                className={classes.dialogCloseBtn}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleLogout}
                className={classes.dialogEventBtn}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  deleteAccountDialog = () => {
    const { classes } = this.props;
    const { deleteAccountOpen } = this.state;
    return (
      <Dialog
        open={deleteAccountOpen}
        onClose={this.handledeleteAccountClose}
        PaperProps={{
          style: {
            borderRadius: 16,
            maxWidth: "570px",
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <Box className={classes.dialogContainer}>
            <Typography
              className={classes.modalTitle}
            >
              {"Delete Account??"}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                fontFamily: "Roboto",
                textAlign: "center",
              }}
            >
              Are you sure you want to delete your account? This will
              permanently erase your account.
            </Typography>
            <Box
              className={classes.deleteAccountBtns}
            >
              <Button
                onClick={this.deleteAccount}
                className={classes.dialogCloseBtn}
              >
                Delete anyway
              </Button>
              <Button
                onClick={this.handledeleteAccountClose}
                className={classes.dialogEventBtn}
              >
                No, don’t delete
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  emailVerificationDialog = () => {
    const { classes } = this.props;
    const { emailVerificationOpen, email } = this.state;
    return (
      <Dialog
        open={emailVerificationOpen}
        onClose={this.handleEmailVerifictionClose}
        PaperProps={{
          style: {
            borderRadius: 16,
            maxWidth: "570px",
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <Box className={classes.verificationBox}>
            <Typography
              className={classes.verifyYourEmailTitle}
            >
              Verify your email address
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#6F6F70",
                textAlign: "center",
              }}
            >
              Click on the link sent to {email} Change{" "}
              <span
                style={{
                  fontWeight: 600,
                  color: "#0069BC",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={this.handleEmailVerifictionClose}
              >
                Change
              </span>
            </Typography>
            <img src={imgVerify} alt="verify" />
            <Button
              className={classes.continueBtn}
              onClick={this.getEmailConfirmation}
            >
              Continue
            </Button>
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#222222",
                textAlign: "center",
              }}
            >
              Didn’t receive an email?{" "}
              <span
                style={{ fontWeight: 600, color: "#0069BC", cursor: "pointer" }}
                onClick={this.getVerificationMail}
              >
                Resend Email
              </span>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  verificationSuccessDialog = () => {
    const { classes } = this.props;
    const { verificationSuccessOpen, verify } = this.state;
    return (
      <Dialog
        open={verificationSuccessOpen}
        onClose={this.handleverificationSuccessClose}
        PaperProps={{
          style: {
            borderRadius: 16,
            maxWidth: "570px",
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <Box className={classes.messageBox}>
            <CloseIcon
              style={{
                marginBottom: "10px",
                top: "0px",
                right: "0px",
                cursor: "pointer",
                position: "absolute",
              }}
              onClick={this.handleverificationSuccessClose}
            />
            <img src={verify ? imgVerified : imgError} />
            <Typography
              className={classes.verificationResult}
            >
              {verify ? "Email address verified!" : "Error!"}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                textAlign: "center",
                color: "#6F6F70",
              }}
            >
              {verify
                ? "Your email address has been verified successfully."
                : "Your email address could not be verified."}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };


  renderLeftSizeMenu = () => {
    const { classes } = this.props;
    return <Box className={classes.leftContainer}>
       <Box sx={{width:"100%"}}>
      <Box
        style={{ margin: "16px 0px" }}
        className={classes.profileBox}
      >
        <Box style={{ position: "relative", width: "170px" }}>
          <img
            src={this.state.userProfile}
            style={{
              height: "170px",
              width: "170px",
              borderRadius: "50%",
            }}
          />
          <Box className={classes.imgBtn}>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              ref={this.fileUploadRef}
              onChange={(e: any) => this.fileChange(e)}
              className={classes.inputfile}
            />
            <img
              src={camera}
              aria-labelledby="image"
              aria-describedby="image"
            />
          </Box>
        </Box>
        <Typography
          className={classes.profileName}
        >
          {this.state.profileName}
        </Typography>
      </Box>
      <Box
        style={{
          background:
            this.state.selectedSetting === "Personal Information"
              ? "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)"
              : "#FFFFFF",
        }}
        data-testid="Information"
        onClick={() => this.selectSetting("Personal Information")}
        className={classes.tabBtn}
      >
        <img
          src={
            this.state.selectedSetting === "Personal Information"
              ? selectedUser
              : user
          }
        />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color:
              this.state.selectedSetting === "Personal Information"
                ? "#FFFFFF"
                : "#828282",
            fontWeight: 700,
          }}
        >
          Personal Information
        </Typography>
      </Box>
      <Box
        style={{
          background:
            this.state.selectedSetting === "Investment Preference"
              ? "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)"
              : "#FFFFFF",
        }}
        data-testid="Investment"
        className={classes.tabBtn}
        onClick={() => this.selectSetting("Investment Preference")}
      >
        <img
          src={
            this.state.selectedSetting === "Investment Preference"
              ? selectedInvpref
              : invpref
          }
        />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color:
              this.state.selectedSetting === "Investment Preference"
                ? "#FFFFFF"
                : "#828282",
            fontWeight: 700,
          }}
        >
          Investment Preference
        </Typography>
      </Box>
      <Box
        style={{
          background:
            this.state.selectedSetting === "KYC"
              ? "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)"
              : "#FFFFFF",
        }}
        data-testid="KYC"
        className={classes.tabBtn}
        onClick={() => this.selectSetting("KYC")}
      >
        <img
          src={this.state.selectedSetting === "KYC" ? selectedKyc : kyc}
        />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color:
              this.state.selectedSetting === "KYC"
                ? "#FFFFFF"
                : "#828282",
            fontWeight: 700,
          }}
        >
          KYC
        </Typography>
      </Box>
      {this.renderTabs()}
      <Box style={{ width: "100%" }}>
        <Divider
          style={{ height: "2px", color: "rgb(130, 130, 130)" }}
        />
      </Box>
      <Box className={classes.tabBtn} onClick={this.openLogoutDialog}>
        <img src={logout} />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color: "#DC2626",
            fontWeight: 700,
          }}
        >
          Logout
        </Typography>
      </Box>
    </Box>
    </Box>
  }

  renderTabs=()=>{
    const { classes } = this.props;
    return <>
    
      <Box
        style={{
          background:
            this.state.selectedSetting === "Notifications"
              ? "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)"
              : "#FFFFFF",
        }}
        data-testid="Notifications"
        className={classes.tabBtn}
        onClick={() => this.selectSetting("Notifications")}
      >
        <img
          src={
            this.state.selectedSetting === "Notifications"
              ? selectedNotification
              : notifications
          }
        />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color:
              this.state.selectedSetting === "Notifications"
                ? "#FFFFFF"
                : "#828282",
            fontWeight: 700,
          }}
        >
          Notifications
        </Typography>
      </Box>
      <Box
        style={{
          background:
            this.state.selectedSetting === "Contact us"
              ? "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)"
              : "#FFFFFF",
        }}
        data-testid="Contact"
        className={classes.tabBtn}
        onClick={() => this.selectSetting("Contact us")}
      >
        <img
          src={
            this.state.selectedSetting === "Contact us"
              ? selectedPhone
              : contactus
          }
        />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color:
              this.state.selectedSetting === "Contact us"
                ? "#FFFFFF"
                : "#828282",
            fontWeight: 700,
          }}
        >
          Contact us
        </Typography>
      </Box>
      <Box
        style={{
          background:
            this.state.selectedSetting === "Change Password"
              ? "linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)"
              : "#FFFFFF",
        }}
        data-testid="Change"
        className={classes.tabBtn}
        onClick={() => this.selectSetting("Change Password")}
      >
        <img
          src={
            this.state.selectedSetting === "Change Password"
              ? selectedChangepassword
              : changepassword
          }
        />
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color:
              this.state.selectedSetting === "Change Password"
                ? "#FFFFFF"
                : "#828282",
            fontWeight: 700,
          }}
        >
          Change Password
        </Typography>
      </Box>
    </>
  }
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {this.logoutDialog()}
        {this.emailVerificationDialog()}
        {this.verificationSuccessDialog()}
        {this.deleteAccountDialog()}
        <TopNavBar navigation={this.props.navigation} />
        <Container>
            <Hidden mdUp>
              <Drawer data-test-id='side-bar' onClose={this.toggleSidebar} open={this.state.openSideMenu} > {this.renderLeftSizeMenu()}</Drawer>
            </Hidden>
            <Loader loading={this.state.loading} />
          <Box
            className={classes.leftRightSectionWrapper}
          >
            <Hidden smDown>
                {this.renderLeftSizeMenu()}
            </Hidden>
            <Box sx={{maxWidth:'770px',width:'100%'}}>
              <Hidden mdUp>
                <IconButton data-test-id='toggle-btn' style={{color:"black",margin:'10px 0'}} onClick={this.toggleSidebar}>
                  <MenuRoundedIcon />
                </IconButton>
              </Hidden>
              {this.state.selectedSetting === "Personal Information" && (
                <Box
                  className={classes.rightContainer}
                  style={{ justifyContent: "space-between", gap: "16px" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontFamily: "Lato",
                        fontWeight: 800,
                      }}
                    >
                      Personal Information
                    </Typography>
                    <EditIcon
                      style={{ color: "#0069BC", cursor: "pointer" }}
                      onClick={this.handleEditDetails}
                      data-test-id="editBtn"
                    />
                  </Box>
                  <Box className={classes.firstNameWrapper} >
                    <FormControl variant="outlined" style={{ flex: 1 }}>
                      <FormHelperText
                        style={{
                          color: "#222222",
                          margin: "0 0 8px",
                          fontSize: "14px",
                          fontWeight: 400,
                          fontFamily: "Roboto",
                        }}
                      >
                        First Name
                      </FormHelperText>
                      <OutlinedInput
                        className={classes.inputBtn}
                        value={this.state.firstName}
                        disabled={!this.state.editDetails}
                        onChange={this.handleFirstName}
                        onBlur={this.validate}
                      />
                      <FormHelperText className={classes.errorText}>
                        {this.state.errors.firstName}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" style={{ flex: 1 }}>
                      <FormHelperText
                        style={{
                          fontWeight: 400,
                          color: "#222222",
                          margin: "0 0 8px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Last Name
                      </FormHelperText>
                      <OutlinedInput
                        className={classes.inputBtn}
                        value={this.state.lastName}
                        disabled={!this.state.editDetails}
                        onChange={this.handleLastName}
                        onBlur={this.validate}
                      />
                      <FormHelperText className={classes.errorText}>
                        {this.state.errors.lastName}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <FormControl variant="outlined" style={{ flex: 1 }}>
                    <FormHelperText
                      style={{
                        fontWeight: 400,
                        color: "#222222",
                        margin: "0 0 8px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Address
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      style={{
                        maxWidth: "600px",
                      }}
                      value={this.state.address}
                      disabled={!this.state.editDetails}
                      onChange={this.handleAddress}
                    />
                  </FormControl>
                  <FormControl variant="outlined" style={{ flex: 1 }}>
                    <FormHelperText
                      style={{
                        fontWeight: 400,
                        color: "#222222",
                        margin: "0 0 8px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Email Address
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      style={{
                        maxWidth: "370px",
                      }}
                      value={this.state.email}
                      disabled={!this.state.editDetails}
                      onChange={this.handleEmail}
                      onBlur={this.validate}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography
                            onClick={this.handleVerifyEmail}
                            style={{
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              color: "#0069BC",
                              cursor: "pointer",
                              fontWeight: 700,
                            }}
                          >
                            {this.state.editDetails && "Verify"}
                          </Typography>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText className={classes.errorText}>
                      {this.state.errors.email}
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" style={{ flex: 1 }}>
                    <FormHelperText
                      style={{
                        fontWeight: 400,
                        color: "#222222",
                        fontFamily: "Roboto",
                        margin: "0 0 8px",
                        fontSize: "14px",
                      }}
                    >
                      Phone Number
                    </FormHelperText>

                    <Grid style={{ display: "flex", gap: "10px" }}>
                      <Grid>
                        <Autocomplete
                          id="combo-box-demo"
                          options={config.countryData.map(
                            (item: any) => item.dial_code
                          )}
                          disabled={!this.state.editDetails}
                          value={this.state.countryCode}
                          onChange={this.handleCountryCode}
                          getOptionLabel={(option: any) => option}
                          className={classes.autocomplate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              style={{ width: "120px" }}
                            />
                          )}
                        />
                        <FormHelperText className={classes.errorText}>
                          {this.state.errors.countryCode}
                        </FormHelperText>
                      </Grid>
                      <Grid>
                        <OutlinedInput
                          className={classes.inputBtn}
                          style={{
                            maxWidth: "238px",
                          }}
                          value={this.state.phoneNumber}
                          onChange={this.handlePhoneNumber}
                          disabled={!this.state.editDetails}
                          onBlur={this.validate}
                        />
                        <FormHelperText className={classes.errorText}>
                          {this.state.errors.phoneNumber}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </FormControl>
                  {this.state.editDetails && (
                    <Box
                      className={classes.updateProfileBtnWrapper}
                    >
                      <Button
                        className={classes.cancleBtn}
                        onClick={this.cancelEditDetails}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.contactBtn}
                        onClick={this.updateUserDetails}
                        disabled={this.state.disableEmail}
                        style={{
                          cursor: this.state.disableEmail
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  )}
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      fontFamily: "Inter",
                      color: "#0069BC",
                      cursor: "pointer",
                    }}
                    onClick={this.handleDeleteAccount}
                  >
                    Delete Account
                  </Typography>
                </Box>
              )}
              {this.state.selectedSetting === "Investment Preference" && (
                <Box
                  className={classes.rightContainer}
                  style={{
                    justifyContent: "space-between",
                    minHeight: "400px",
                    gap: "15px",
                  }}
                >
                  {this.state.kycData.investor_type === "individual" && (
                    <Box className={classes.investmentBox}>
                      <Typography className={classes.kycQuestion}>
                        The investor type that corresponds to the legal entity
                        you’ll be investing through
                      </Typography>
                      <Typography className={classes.kycAns}>
                        As an individual
                      </Typography>
                    </Box>
                  )}
                  {this.state.kycData.investor_type !== "individual" && (
                    <Box className={classes.investmentBox}>
                      <Typography className={classes.kycQuestion}>
                        Is your organization “family run”?
                      </Typography>
                      {Object.keys(this.state.kycData).length !== 0 && (
                        <Typography className={classes.kycAns}>
                          {this.state.kycData.is_family_run ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {this.state.kycData.investor_type !== "individual" && (
                    <Box className={classes.investmentBox}>
                      <Typography className={classes.kycQuestion}>
                        Select the investor type that corresponds to the legal
                        entity you’ll be investing through
                      </Typography>
                      <Typography className={classes.kycAns}>
                        {this.state.kycData.investor_type}
                      </Typography>
                    </Box>
                  )}
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      What experience do you or your entity have with
                      alternative investments as an asset class?
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.experience_alternative_investment}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      What’s driving your alternative investment strategy?
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.alternative_investment_strategy}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      What is the extent of capital actively invested by you or
                      your entity?
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.capital_actively_invested}
                    </Typography>
                  </Box>
                  {this.state.kycData.investor_type !== "individual" && (
                    <Box className={classes.investmentBox}>
                      <Typography className={classes.kycQuestion}>
                        What percentage are you aiming to allocate into
                        alternative investments?
                      </Typography>
                      <Typography className={classes.kycAns}>
                        {this.state.kycData.percentage_to_allocate}
                      </Typography>
                    </Box>
                  )}
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Tell us how your organization is accredited
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.organisation_accredited}
                    </Typography>
                  </Box>
                </Box>
              )}
              {this.state.selectedSetting === "KYC" && (
                <Box
                  className={classes.rightContainer}
                  style={{
                    justifyContent: "space-between",
                    minHeight: "400px",
                    gap: "15px",
                  }}
                >
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Legal first name
                    </Typography>
                    <Typography
                      className={classes.kycAns}
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.state.kycData.first_name}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Legal last name
                    </Typography>
                    <Typography
                      className={classes.kycAns}
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.state.kycData.last_name}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Email
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.email}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Phone number
                    </Typography>
                    <Typography className={classes.kycAns}>
                      +{this.state.countryCode}{" "}
                      {this.state.kycData.phone_number}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Address
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.address}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography className={classes.kycQuestion}>
                      Identification type
                    </Typography>
                    <Typography
                      className={classes.kycAns}
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.state.kycData.identification_type &&
                        this.state.kycData.identification_type.replace(
                          "_",
                          " "
                        )}
                    </Typography>
                  </Box>
                  <Box className={classes.investmentBox}>
                    <Typography
                      className={classes.kycQuestion}
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.state.kycData.identification_type &&
                        this.state.kycData.identification_type.replace(
                          "_",
                          " "
                        )}
                    </Typography>
                    <Typography className={classes.kycAns}>
                      {this.state.kycData.identification_number}
                    </Typography>
                  </Box>
                </Box>
              )}
              {(this.state.selectedSetting === "Investment Preference" ||
                this.state.selectedSetting === "KYC") && (
                <Typography
                  style={{
                    color: "#0069BC",
                    cursor: "pointer",
                    textAlign: "end",
                    margin: "20px 0px",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Roboto",
                  }}
                  onClick={() => this.selectSetting("Contact us")}
                  data-testid="need-help"
                >
                  Need help? Contact us
                </Typography>
              )}
              {this.state.selectedSetting === "Contact us" && (
                <Box className={classes.rightContainer}>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        color: "#828282",
                        margin: "0 0 8px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Full Name
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      value={this.state.fullName}
                      onChange={this.handleFullName}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        color: "#828282",
                        margin: "0 0 8px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Email
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      value={this.state.contactEmail}
                      onChange={this.handleContactMail}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        fontSize: "14px",
                        margin: "0 0 8px",
                        color: "#828282",
                        fontFamily: "Roboto",
                      }}
                    >
                      Query related to
                    </FormHelperText>
                    <Select
                      placeholder="Select query"
                      style={{
                        height: "48px",
                        maxWidth: "273px",
                        width: "100%",
                        borderRadius: "8px",
                      }}
                      onChange={this.handleQuery}
                      value={this.state.query}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <em>Select query</em>
                      </MenuItem>
                      <MenuItem value="investment_opportunity">
                        Investment Opportunity
                      </MenuItem>
                      <MenuItem value="waitlisted_opportunity">
                        Waitlisted Opportunity
                      </MenuItem>
                      <MenuItem value="kyc_verification">
                        KYC Verification
                      </MenuItem>
                      <MenuItem value="investment_document">
                        Investment Document
                      </MenuItem>
                      <MenuItem value="wire_transfer">Wire Transfer</MenuItem>
                      <MenuItem value="current_investment">
                        Current Investment
                      </MenuItem>
                      <MenuItem value="other">other</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        color: "#828282",
                        margin: "0 0 8px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Description
                    </FormHelperText>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      variant="outlined"
                      style={{
                        maxWidth: "560px",
                        borderColor: "rgba(189, 189, 189, 1)",
                        borderRadius: "8px",
                      }}
                      value={this.state.description}
                      onChange={this.handleDescription}
                    />
                  </FormControl>
                  <Button
                    className={classes.contactBtn}
                    onClick={this.contactUs}
                  >
                    Send
                  </Button>
                </Box>
              )}
              {this.state.selectedSetting === "Notifications" && (
                <Box
                  className={classes.rightContainer}
                  style={{ gap: "45px", minHeight: "400px" }}
                >
                  <Box>
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontFamily: "Lato",
                        fontWeight: 800,
                      }}
                    >
                      Notifications Settings
                    </Typography>
                  </Box>
                  <Box className={classes.settingBox}>
                    <Typography
                      style={{ fontSize: "16px", fontFamily: "Roboto" }}
                    >
                      New Investment Opportunity
                    </Typography>
                    <AntSwitch
                      checked={this.state.newOpportunity}
                      onChange={this.handleChangeNewOpportunity}
                    />
                  </Box>
                  <Box
                    className={classes.settingBox}
                    style={{ display: "none" }}
                  >
                    <Typography
                      style={{ fontSize: "16px", fontFamily: "Roboto" }}
                    >
                      Investment Status
                    </Typography>
                    <AntSwitch
                      checked={this.state.investmentStatus}
                      onChange={this.handleChangeInvestmentStatus}
                    />
                  </Box>
                  <Box
                    className={classes.settingBox}
                    style={{ display: "none" }}
                  >
                    <Typography
                      style={{ fontSize: "16px", fontFamily: "Roboto" }}
                    >
                      Investment Documentation
                    </Typography>
                    <AntSwitch
                      checked={this.state.investmentDocumentation}
                      onChange={this.handleChangeInvestmentDocumentation}
                    />
                  </Box>
                  <Box className={classes.settingBox}>
                    <Typography
                      style={{ fontSize: "16px", fontFamily: "Roboto" }}
                    >
                      Wire Transfer
                    </Typography>
                    <AntSwitch
                      checked={this.state.wireTransfer}
                      onChange={this.handleChangeWireTransfer}
                    />
                  </Box>
                  <Box className={classes.settingBox}>
                    <Typography
                      style={{ fontSize: "16px", fontFamily: "Roboto" }}
                    >
                      Investment Queries
                    </Typography>
                    <AntSwitch
                      checked={this.state.investmentQuery}
                      onChange={this.handleChangeInvestmentQuery}
                    />
                  </Box>
                </Box>
              )}
              {this.state.selectedSetting === "Change Password" && (
                <Box className={classes.rightContainer}>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        margin: "0 0 8px",
                        color: "#828282",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Current password
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      type={
                        this.state.showCurrentPassword ? "text" : "password"
                      }
                      value={this.state.currentPassword}
                      onChange={this.handleCurrentPasswordChange}
                      onFocus={() =>
                        this.setState({ currentPasswordError: "" })
                      }
                      onBlur={this.validateCurrentPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleCurrentPassword}>
                            {this.state.showCurrentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {this.state.currentPasswordError && (
                      <FormHelperText className={classes.errTxt}>
                        {this.state.currentPasswordError}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        margin: "0 0 8px",
                        color: "#828282",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      New password
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      type={this.state.showNewPassword ? "text" : "password"}
                      value={this.state.newPassword}
                      onChange={this.handleNewPasswordChange}
                      onFocus={() => this.setState({ newPasswordError: "" })}
                      onBlur={this.validateNewPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleNewPassword}>
                            {this.state.showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {this.state.newPasswordError && (
                      <FormHelperText className={classes.errTxt}>
                        {this.state.newPasswordError}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ margin: "16px 0px" }}
                  >
                    <FormHelperText
                      style={{
                        margin: "0 0 8px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "#828282",
                      }}
                    >
                      Confirm new password
                    </FormHelperText>
                    <OutlinedInput
                      className={classes.inputBtn}
                      type={
                        this.state.showConfirmNewPassword ? "text" : "password"
                      }
                      value={this.state.confirmNewPassword}
                      onChange={this.handleConfirmNewPasswordChange}
                      onFocus={() =>
                        this.setState({ confirmNewPasswordError: "" })
                      }
                      onBlur={this.validateConfirmNewPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleConfirmNewPassword}>
                            {this.state.showConfirmNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {this.state.confirmNewPasswordError && (
                      <FormHelperText className={classes.errTxt}>
                        {this.state.confirmNewPasswordError}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button
                    className={classes.pwdBtn}
                    onClick={this.changeProfilePassword}
                  >
                    Change password
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Paper>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(AccountSettingWeb);
// Customizable Area End
