import React from "react";

// Customizable Area Start
import { Box, Button, Divider, FormControl, FormHelperText, Grid, OutlinedInput, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import {
    group_shape,
    shape,
    imgArraow,
    imgBargraph,
    imgReport,
    imgShape,
    image_shape,
    frameShape,
    group_text02
} from "./assets";
import { StyleRules, withStyles } from "@material-ui/styles";
import { imageHerosectionbackground } from "../../contentmanagement/src/assets";
import { logo } from "../../contentmanagement/src/assets";
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import 'swiper/swiper-bundle.css';
// Customizable Area End

import LandingPageScreenController, {
  Props,
} from "./LandingPageScreenController.web";

const styles: StyleRules = {
  // Customizable Area Start
    bgImageStyle: {
        backgroundImage: `url(${imageHerosectionbackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headerRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 15px',
        justifyContent: 'space-between',
        width: '90vw'
    },
    headerMainText: {
        fontSize: '48px',
        fontWeight: 500,
        fontFamily: "Lato",
        textAlign: 'center',
        color: '#fff',
        '@media(max-width:960px)':{
            fontSize:"34px"
        },
        '@media(max-width:600px)':{
            fontSize:"28px"
        }
    },
    futureText: {
        color: '#00E644',
        fontSize: '48px',
        fontWeight: 600,
        fontStyle: 'italic',
        '@media(max-width:960px)':{
            fontSize:"34px"
        },
        '@media(max-width:600px)':{
            fontSize:"28px"
        }
    },
    titrleTextMainBox: {
        maxWidth: '1170px',
        width: '100%',
        minHeight: '298px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    descriptionMainText: {
        fontSize: '24px',
        fontFamily: "Roboto",
        textAlign: 'center',
        color: '#fff',
        fontWeight:400,
        '@media(max-width:960px)':{
            fontSize:'20px'
        },
        '@media(max-width:600px)':{
            fontSize:"16px"
        }
    },
    groupcoinvester: {
        height: "243px",
        width: "1164px",
    },
    watchbtn: {
        color: "#0069BC",
        backgroundColor: "#fff",
        borderRadius: "12px",
        height: "60px",
        width: "200px",
        fontWeight: 700,
        fontSize: '20px',
        fontFamily: "Roboto",
        margin: '20px',
        textTransform:'capitalize',
        "&:hover": {
            backgroundColor: "#fff",
        },
        '@media(max-width:600px)':{
            height: "fit-content",
            fontWeight: 500,
            fontSize: '16px',
        }
    },
    waitlistBtn: {
        color: "#fff",
        backgroundColor: "#0069BC",
        borderRadius: "12px",
        height: "60px",
        width: "200px",
        fontWeight: 700,
        fontSize: '20px',
        fontFamily: "Roboto",
        textTransform:'capitalize',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
        '@media(max-width:600px)':{
            height: "auto",
            fontSize:'18px'
        }
    },
    labelText: {
        fontWeight: 400,
        fontSize: "14px",
        margin: "0px",
        color: "#222222",
        fontFamily: "Roboto"
    },
    investorBox: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '243px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
        '@media(max-width:600px)':{
            width:'90%'
        }
    },
    companyBox: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '376px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
    },
    wealthBox: {
        padding: '50px 0px',
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px'
    },
    companyBtn: {
        borderRadius: "12px",
        height: "48px",
        fontSize: '18px',
        fontFamily: "Roboto",
        color: '#FFFFFF',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
    },
    modelBox: {
        maxWidth: "520",
        width: '100%',
        minHeight: "360px",
        backgroundColor: "#ffffff",
        position: "absolute",
        border: "2px solid transparent",
        borderRadius: "16px",
        padding: "32px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media(max-width:600px)':{
            width:'auto'
        }
    },
    imgScrollBox: {
        padding: '32px 500px 32px 32px',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: '175px',
        '@media (max-width: 700px)': {
            padding: '16px',
        }
    },
    swiperClass: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide' :{
            marginTop:'20px',
            marginBottom:'20px'
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    arrow:{
       '@media(max-width:600px)':{
            width:"200px"
        }
    },
    imageContainer: {
        position: 'relative',
        width: '100%',
        maxWidth: '970px',
        margin: '0 auto',
        '@media (max-width: 960px)': {
            maxWidth: '85%',
        },
        '@media (max-width: 600px)': {
            maxWidth: '90%',
        }
    },
    frameImg: {
        width: '90%',
        position: 'absolute',
        top: '54%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity:0.3
    },
    groupSectionImg: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        zIndex: 1,
        margin: '120 0px',
        '@media (max-width: 959px)': {
            margin: '70px 10px 70px',
        },
        '@media (max-width: 768px)': {
            margin: '70px 0',
        }
    },
    errorText: {
        color: "#DC2626",
        fontSize: "14px",
        fontWeight: 400,
        margin: "0px",
        width: "100%",
    },
    premireShape1:{
        left: "0px",
        height: "198px",
        position: "absolute",
        top: "0px",
        width: "228px",
        '@media(max-width:600px)': {
            width:'100px',
            height:'auto'
        }
    },
    premireShape2:{
        right: "16px",
        position: "absolute",
        transform: " scaleY(1)",
        height: "198px",
        width: "228px",
        bottom: "0px",
        '@media(max-width:600px)': {
            width:'100px',
            height:'auto'
        }
    },
    premireDigital: {
        fontSize: "48px",
        fontWeight: 400,
        fontFamily: 'Lato',
        zIndex: 1,
        '@media(max-width:960px)': {
            fontSize: "34px",
            maxWidth:'85%'
        },
        '@media(max-width:600px)':{
            fontSize:'24px'
        }
    },
    LandingText:{
        width:'100%',
        marginTop:'50px',
        marginBottom:'10px',
        display:'flex',
        flexDirection:'column',
        gap:'20px',
        alignItems:'center',
        '@media(max-width:960px)': {
            maxWidth:'90%'
        },
        '@media(max-width:600px)':{
            maxWidth:'85%'
        }
    },
    logo: {
        cursor: 'pointer',
        '@media(max-width:600px)': {
            maxWidth: '110px'
        }
    },
    modalTitle: {
        textAlign: "center",
        fontFamily: "Lato",
        fontSize: '32px',
        fontWeight: 800,
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    }
    // Customizable Area End
};

export class LandingPageScreen extends LandingPageScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    renderWaitlistModal = () => {
        const { classes } = this.props
        return (
            <Modal
                open={this.state.open}
                onClose={this.handleClose}
                style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}
            >
                <div className={classes.modelBox} >
                    <Typography className={classes.modalTitle}>
                        Enter Your Details
                    </Typography>
                    <FormControl variant="outlined" style={{ width: '100%' }} >
                        <FormHelperText
                            className={classes.labelText}
                        >
                            Full Name
                        </FormHelperText>
                        <OutlinedInput
                            value={this.state.fullName}
                            onChange={this.handleFullnameChange}
                            labelWidth={0}
                            style={{
                                height: "48px",
                                borderRadius: "8px",
                            }}
                            onBlur={this.validateFullName}
                            data-test-id="fullName"
                        />
                        {this.state.fullName_error && (
                            <FormHelperText className={classes.errorText}>
                                {this.state.fullName_error}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl variant="outlined" style={{ width: '100%' }} >
                        <FormHelperText
                            className={classes.labelText}
                        >
                            Email Address
                        </FormHelperText>
                        <OutlinedInput
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            data-test-id='emailChangeBtn'
                            labelWidth={0}
                            style={{
                                height: "48px",
                                borderRadius: "8px",
                            }}
                            onBlur={this.validateEmail}
                        />
                          {this.state.email_error && (
                            <FormHelperText className={classes.errorText}>
                                {this.state.email_error}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Button className={classes.waitlistBtn} onClick={this.handleSubmit} data-test-id="handleSubmitBtn">
                        Submit
                    </Button>
                </div>
            </Modal>
        );
    };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props
        return (
            <div style={{ overflowX: 'hidden' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImageStyle}>
                    <div className={classes.headerRoot}>
                        <div>
                            <img src={logo} alt="Poster" className={classes.logo} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                            <Typography className={classes.descriptionMainText} style={{ cursor: 'pointer' }} onClick={this.handleOpen}>Interested? Join our waitlist</Typography>
                        </div>
                    </div>
                    <Box className={classes.titrleTextMainBox} style={{ position: 'relative' }}>
                        <img src={imgShape} style={{
                            position: 'absolute',
                            right: '-50px',
                            top: '-15px',
                        }} />
                        <Box className={classes.LandingText}>
                            <Typography className={classes.headerMainText}> Seamlessly enter the world of alternative investments with <span className={classes.futureText}>Future for Alts.</span></Typography>
                            <Typography className={classes.descriptionMainText}>The world's only platform offering both, a website and application, for effortless access.</Typography>
                            <Button
                                className={classes.watchbtn}
                                onClick={this.handleOpen}
                                data-test-id="openModal"
                            >
                                Join waitlist
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.investorBox}>
                        <Typography style={{ fontSize: '28px', padding: '32px', fontWeight: 700, fontFamily: 'Lato', color: '#FFFFFF' }}>Our Co-Investors</Typography>
                        <img src={imgArraow} className={classes.arrow} />
                        <Swiper
                            speed={1500}
                            slidesPerView={5}
                            className={classes.swiperClass}
                            navigation
                            autoplay={{ delay: 700 }} 
                            loop
                            breakpoints={{
                                '@0.0': {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                '@0.60': {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                '@0.70': {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                '@0.90': {
                                  slidesPerView: 3,
                                  spaceBetween: 20,
                                },
                                '@1.00': {
                                  slidesPerView: 4,
                                  spaceBetween: 20,
                                },
                                '@1.50': {
                                  slidesPerView: 5,
                                  spaceBetween: 20,
                                },
                              }}
                        >
                            {this.state.investorDataRecords.map((data: any, index: number) => (
                                <SwiperSlide style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 'auto',
                                    justifyContent: 'center'
                                }}>
                                    <img
                                        src={data.attributes?.image}
                                        alt={data.attributes.company_name}
                                        style={{
                                            width: '30%',
                                            
                                        }}
                                        key={index}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                    {this.renderWaitlistModal()}
                </Box>
                <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={classes.imageContainer}>
                        <img src={frameShape} className={classes.frameImg} />
                        <img src={group_text02} className={classes.groupSectionImg} />
                    </div>

                    <Grid
                        item
                        xs={12}
                        style={{
                            minHeight: "320px",
                            width: "100%",
                            background: 'linear-gradient(92.83deg, #0086F0 0%, #0069BC 100%)',
                            fontFamily: "Lato",
                            color: "#fff",
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "68px",
                            position: "relative",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '50px'
                        }}
                    >
                        <img
                            src={shape}
                            alt="group_shape"
                            className={classes.premireShape1}
                        />
                        <Typography className={classes.premireDigital}>
                            Premier digital first platform for <br />
                            <span
                                style={{
                                    color: "#00E644",
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                }}
                            >
                                Alternative Investing.
                            </span>
                        </Typography>
                        <img
                            src={image_shape}
                            alt="group_shape"
                            className={classes.premireShape2}
                        />
                    </Grid>
                </Grid>
                <Footer navigation={this.props.navigation}/>
            </div>
        );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(LandingPageScreen);
// Customizable Area End 