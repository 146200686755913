import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Paper,
    StyleRules,
    Typography,
    withStyles,
} from "@material-ui/core";
import { imgVerify } from "./assets";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import EmailAccountRegistrationWebController, {
    Props,
} from "./EmailAccountRegistrationController.web";

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
    verificationBox: {
        boxShadow: '0px 0px 4px 0px #00000040',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        minHeight: '533px',
        maxWidth: '530px',
        borderRadius: '16px',
        width: '100%',
        margin: "20px",
        padding: "0px 20px"
    },
    messageBox: {
        boxShadow: '0px 0px 4px 0px #00000040',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '321px',
        width: '100%',
        maxWidth: '570px',
    },
    continueBtn: {
        height: '60px',
        width: '153px',
        color: '#FFFFFF',
        backgroundColor: '#0069BC',
        fontSize: '20px',
        fontWeight: 700,
        fontFamily: 'Roboto',
        padding: '10px, 16px, 10px, 16px',
        borderRadius: '12px',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
    }
    // Customizable Area End
};

export class EmailConfirmationWeb extends EmailAccountRegistrationWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { loading, registerdEmail } = this.state;

        return (
            <Paper className={classes.root}>
                <Loader loading={loading} />
                <Box className={classes.verificationBox}>
                    <Typography style={{ fontSize: '32px', fontWeight: 700, color: '#222222', fontFamily: 'Lato', textAlign: 'center' }}>
                        Verify your email address
                    </Typography>
                    <Typography style={{ fontSize: '16px', fontFamily: 'Roboto', color: '#6F6F70', textAlign: 'center' }}>
                        Click on the link sent to you at {registerdEmail} <span style={{ fontWeight: 600, color: '#0069BC', textDecoration: 'underline', cursor: 'pointer' }} onClick={this.navigateBack}>Change</span>
                    </Typography>
                    <img src={imgVerify} alt="verify" />
                    <Button className={classes.continueBtn} onClick={this.getEmailConfirmation}>Continue</Button>
                    <Typography style={{ fontSize: '16px', fontFamily: 'Roboto', color: '#222222', textAlign: 'center' }}>Didn’t receive an email? <span style={{ fontWeight: 600, color: '#0069BC', cursor: 'pointer' }} onClick={this.getVerificationMail}>Resend Email</span></Typography>
                </Box>
            </Paper>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(EmailConfirmationWeb);
// Customizable Area End
