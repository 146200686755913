import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Paper,
    Typography,
    FormControl,
    OutlinedInput,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    Container
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import Footer from "../../../components/src/Footer.web";
import TopNavBar from "../../../components/src/TopNavbar.web";
import { PitchDeckBackground, allocation, background, buttons, checkMark, fees, graph, groupBank, imageArrow, imageButtonClose, imageDoc, pdfImage, premoney, roundLogo, share, textInput, topImg, defaultImage, jpgImage, pngImage, zipImage, jpegImage } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import 'swiper/swiper-bundle.css';
import Loader from "../../../components/src/Loader.web";
import { NumericFormat } from 'react-number-format';
// Customizable Area End

import OpportunitiesDetailsController, {
    Props,
} from "./OpportunitiesDetailsController.web";

const styles: StyleRules = {
    // Customizable Area Start
    viewInvestmentBtn: {
        maxWidth: '185px',
        width: '100%',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#0069BC',
        color: '#FFFFFF',
        fontWeight: 700,
        padding: '10px, 16px, 10px, 16px',
        fontSize: '20px',
        fontFamily: 'Roboto',
        marginTop: '10px',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
    },
    viewIBtn: {
        maxWidth: '271px',
        width: '100%',
        height: '60px',
        borderRadius: '12px',
        backgroundColor: '#0069BC',
        color: '#FFFFFF',
        fontWeight: 700,
        padding: '10px, 16px, 10px, 16px',
        fontSize: '20px',
        fontFamily: 'Roboto',
        marginTop: '10px',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },

    },
    customBtn: {
        height: '115px',
        maxWidth: '450px',
        width: '100%',
        border: '1px solid #0069BC',
        borderRadius: '12px',
        fontWeight: 700,
        color: '#0069BC',
        fontFamily: 'Roboto',
        fontSize: '20px',
        textTransform: 'none',
        background: 'rgba(0, 105, 188, 0.05)',
        display: 'flex',
        alignItems: 'center',
        gap: '30px',
        padding: '0px 30px',
        '@media (max-width: 959px)': {
            padding: '0px 20px',
        }
    },
    pitchDesk: {
        height: '240px',
        width: '470px',
        border: '1px solid #0069BC',
        borderRadius: '16px',
        top: '1144px',
        left: '235px',
        color: '#0069BC',
        backgroundImage: `url(${PitchDeckBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        '@media (max-width: 600px)': {
            width: '100%',
        }
    },
    viewInvestment: {
        width: '436px',
        border: '1px solid #0069BC',
        borderRadius: '16px',
        backgroundImage: `url(${PitchDeckBackground})`,
        justifyContent: 'center',
        alignSelf: 'center',
        display: 'flex',
        gap: '24px',
        alignItems: 'center',
        padding: '16px',
        flexDirection: 'column',
        '@media (max-width: 600px)': {
            width: 'auto',
        }
    },
    dataRoom: {
        height: '240px',
        width: '470px',
        border: '1px solid #0069BC',
        borderRadius: '16px',
        top: '1144px',
        left: '235px',
        color: '#0069BC',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        '@media (max-width: 600px)': {
            width: '100%',
        }
    },
    viewMemoBtn: {
        height: '60px',
        width: '310px',
        border: '1px solid #0069BC',
        borderRadius: '12px',
        fontWeight: 700,
        color: '#0069BC',
        fontFamily: 'Roboto',
        fontSize: '20px',
        textTransform: 'none',
    },
    viewBtn: {
        height: '60px',
        width: '260px',
        border: '1px solid #0069BC',
        borderRadius: '12px',
        fontWeight: 700,
        color: '#0069BC',
        fontFamily: 'Roboto',
        fontSize: '20px',
        textTransform: 'none',
    },
    investorBox: {
        borderRadius: '64px 0px 64px 0px',
        minHeight: '243px',
        maxWidth: '1169px',
        width: '100%',
        background: '#121212',
        marginBottom: '50px'
    },
    swiper: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide': {
            marginTop: '20px'
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    amountBtn: {
        height: '44px',
        width: '87px',
        borderRadius: '10px',
        border: '1px solid rgba(189, 189, 189, 1)',
        fontSize: '16px',
        fontFamily: 'Roboto'
    },
    errorText: {
        color: "#DC2626",
        fontSize: "14px",
        fontWeight: 400,
        margin: "0px",
        width: '100%'
    },
    submitBtn: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        padding: "10px 16px",
        height: "60px",
        width: "153px",
        color: "#FFFFFF",
        borderRadius: "12px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
    },
    joinListBtn: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        padding: "10px 16px",
        height: "60px",
        width: "213px",
        color: "#FFFFFF",
        borderRadius: "8px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: 'Roboto',
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
        '@media(max-width:600px)': {
            fontSize: '16px',
            height: '40px',
            padding:'0',
            width: '100%'
        }
    },
    cancelBtn: {
        height: "60px",
        width: "213px",
        border: '1px solid #0069BC',
        borderRadius: '12px',
        fontWeight: 700,
        color: '#0069BC',
        fontFamily: 'Roboto',
        fontSize: '20px',
        textTransform: 'none',
        background: 'rgba(0, 105, 188, 0.05)',
        '@media(max-width:600px)': {
            fontSize: '16px',
            height: '40px',
            padding:'0',
            width: '100%'
        }
    },
    signBtn: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        padding: "10px 16px",
        height: "60px",
        width: "221px",
        color: "#FFFFFF",
        borderRadius: "12px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
        margin: '24px 0px'
    },
    checkBoxStyle: {
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'rgba(0, 105, 188, 1)'
        }
    },
    wireInstructions: {
        textTransform: "none",
        backgroundColor: ' rgba(255, 255, 255, 1)',
        height: "60px",
        width: "217px",
        color: "rgba(0, 105, 188, 1)",
        border: '1px solid rgba(0, 105, 188, 1)',
        borderRadius: "12px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "24px",
        gap: '8px',
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
    },
    browseOppurtunities: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        padding: "10px 16px",
        color: "#FFFFFF",
        borderRadius: "12px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
        margin: '24px 0px 10px 0px'
    },

    uploadBtn: {
        height: '48px',
        maxWidth: '522px',
        width: '100%',
        backgroundRepeat: 'round',
        textTransform: 'capitalize',
        color: 'rgba(0, 105, 188, 1)',
        backgroundImage: `url(${textInput})`,
        border: '2px none rgba(0, 105, 188, 1)'
    },
    uploadedDoc: {
        height: '48px',
        maxWidth: '522px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontSize: '16px',
        borderRadius: '8px',
        border: '1px solid rgba(189, 189, 189, 1)',
        justifyContent: 'space-between',
        padding: '0px 16px'
    },
    valueTxt: {
        color: 'rgba(0, 105, 188, 1)',
        fontSize: '32px',
        fontStyle: 'Roboto',
        fontWeight: 700
    },
    joinWaitlListField: {
        maxWidth: '522px',
        width: "100%",
        height: "48px",
        borderRadius: "8px",
        '&.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(0, 105, 188)',
                borderStyle: 'dashed'
            },
        },
    },

    wireInstructLabel: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        color: '#828282',
        display: 'block'
    },

    wireInstructContent: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#222222',
        display: 'block'
    },
    opportunityDetailsBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: '100px',
        "& .image": {
            height: '476px',
            width: '400px',
            borderRadius: '12px'
        },
        '@media (max-width: 959px)': {
            flexDirection: 'column !important',
            alignItems: 'center',
            gap: '48px',
            "& .memoBox": {
                textAlign: 'center'
            }
        },
        '@media (max-width: 600px)': {
            "& .image": {
                height: 'auto',
                width: '100%',
            },
        }
    },
    opportunityDetailsTextBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media (max-width: 959px)': {
            alignItems: 'center'
        }
    },
    opportunityDetailsText: {
        fontSize: '16px', 
        color: 'rgba(130, 130, 130, 1)',
        width: '670px',
        fontFamily: 'Roboto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical',
        '@media (max-width: 1280px)': {
            width: 'auto',
            WebkitLineClamp: 5,
        }
    },
    minimumInvestmentBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '24px',
        '@media (max-width: 959px)': {
            flexDirection: 'column'
        }
    },
    showDocsWrapper:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '24px',
        '@media (max-width: 959px)': {
            flexDirection: 'column'
        }
    },
    dialogTitleStyle: {
        color: '#222222',
        fontSize: '32px',
        fontWeight: 800,
        textAlign: 'center',
        fontFamily: 'Lato',
        '@media (max-width: 600px)': {
            fontSize: '26px',
        }
    },
    dialogCloseStyle: {
        position: 'absolute',
        top: '24px',
        right: '24px',
        cursor: 'pointer',
        '@media (max-width: 600px)': {
            top: '10px',
            right: '10px',
        }
    },
    cardWrapper:{
        display:'flex',
        flexDirection:'column',
        gap:'24px',
        '@media (max-width: 600px)': {
            padding:'0 20px'
        }
    }
    // Customizable Area End
};

export class OpportunitiesDetailsWeb extends OpportunitiesDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    capitalizeFirstLetter = (string: string) => {
        if (!string || typeof string !== 'string') {
            return '';
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    showDocuments = (documents: any) => {
        const { classes } = this.props;
        return documents.slice(0, 2).map((doc: any, index: any) => (
            <Box key={index} className={index == 0 ? classes.pitchDesk : classes.dataRoom}>
                <Typography
                    style={{
                        fontStyle: 'lato',
                        fontSize: '40px',
                        color: 'rgba(0, 105, 188, 1)',
                        fontWeight: 800,
                        left: '267px',
                        margin: '24px 0px 10px 33px',
                    }}
                >
                    {doc.attributes.name}
                </Typography>
                <Typography
                    title={doc.attributes.document?.url ? "" : "No document available"}
                    onClick={() => doc.attributes.document?.url && window.open(doc.attributes.document.url, '_blank')}
                    style={{
                        cursor: doc.attributes.document?.url ? 'pointer' : 'not-allowed',
                        fontStyle: 'Roboto',
                        fontSize: '18px',
                        color: 'rgba(0, 105, 188, 1)',
                        fontWeight: 700,
                        left: '267px',
                        margin: '82px 0px 10px 33px',
                        textDecoration: 'underline',
                    }}
                    data-test-id={'viewUrl' + index}
                >
                    View Document
                </Typography>
            </Box>
        ))
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { opportunityDetails, kycStatus, allocationAmountOpen, joinamount, wireInstructionsDetails, successJoinList, joinWaitlistBox, wireAmount, wireTransferName, wireTransferPageError, reviewDocumentError, esopLetter, wireTransferEmail, amount, selectedAmount, viewButton, checkBox, wireTransferOpen, wireInstructionOpen, wireInitiatedSuccess, reviewDocumentOpen, amountError,isLoading, joinWaitlistSecondaryBox, emailAddress } = this.state;
        const { classes } = this.props;
        const wire_instruction_data = wireInstructionsDetails?.attributes
       
        let previewImage = defaultImage;
        if (esopLetter) {
            if (esopLetter.name.includes('jpg')) {
                previewImage = jpgImage;
            } else if (esopLetter.name.includes('jpeg')) {
                previewImage = jpegImage;
            } else if (esopLetter.name.includes('png')) {
                previewImage = pngImage;
            } else if (esopLetter.name.includes('pdf')) {
                previewImage = pdfImage;
            } else if (esopLetter.name.includes('zip')) {
                previewImage = zipImage;
            }
        }
        // Customizable Area End

        return (
            // Customizable Area Start
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <TopNavBar navigation={this.props.navigation} />

                <Container style={{ display: 'flex', flexDirection: 'column', gap: '48px', padding: '40px 20px' }}>
                    <>
                        <Box className={classes.opportunityDetailsBox} style={{ justifyContent: 'flex-start' }}>
                            <Box>
                                <img src={topImg} alt={`Image`} className="image" />
                            </Box>
                            <Box className={classes.opportunityDetailsTextBox}>
                                <img src={opportunityDetails?.attributes?.company.data[0]?.attributes.image} style={{ width: '224px' }} />
                                <Typography style={{ fontSize: '64px', fontStyle: 'italic', fontFamily: 'lato' }}>
                                    {opportunityDetails?.attributes?.company.data[0]?.attributes.company_name}
                                </Typography>
                                <Typography className={classes.opportunityDetailsText}>{opportunityDetails?.attributes?.company.data[0]?.attributes.about}</Typography>
                                {
                                    opportunityDetails?.attributes?.status == 'live' && kycStatus === "verified" &&
                                    <Button className={classes.viewInvestmentBtn} data-testid='allocationBtn' onClick={() => this.handleAllocationAmountOpen(opportunityDetails?.id)}>Allocate Now</Button>}

                                {opportunityDetails?.attributes?.status == 'not_yet_live' && kycStatus === "verified" &&
                                    <Button className={classes.viewInvestmentBtn} data-testid='joinwaitListBtn' onClick={() => this.handleJoinWaitlistPopUp()}>Join Waitlist</Button>
                                }
                                {(kycStatus !== "verified") &&
                                    (<Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                                        <Button className={classes.viewIBtn} onClick={this.completeDeclaration}>View Investment Terms</Button>
                                        <Typography style={{ fontWeight: 500, fontSize: '20px', fontFamily: 'Roboto' }}> *Complete the declaration to be able to allocate now</Typography>
                                    </Box>
                                    )
                                }
                            </Box>
                        </Box>
                        <Box className={classes.cardWrapper}>

                        <Box className={classes.minimumInvestmentBox}>
                            <Box className={classes.customBtn}>
                                <img src={graph} alt={`Image`} style={{ height: '64px', width: '64px' }} />
                                <Box>
                                    <Typography style={{ color: 'rgba(130, 130, 130, 1)', fontSize: '18px', fontStyle: 'Roboto' }}>Minimum Investment</Typography>
                                    <Typography className={classes.valueTxt} >${this.numberWithCommaOpportunity(opportunityDetails?.attributes?.minimum_investment)}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.customBtn}>
                                <img src={allocation} alt={`Image`} style={{ height: '64px', width: '64px' }} />
                                <Box>
                                    <Typography style={{ color: 'rgba(130, 130, 130, 1)', fontSize: '18px', fontStyle: 'Roboto' }}>Allocation</Typography>
                                    <Typography className={classes.valueTxt}>${this.numberWithCommaOpportunity(opportunityDetails?.attributes?.allocation)}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.customBtn}>
                                <img src={roundLogo} alt={`Image`} style={{ height: '64px', width: '64px' }} />
                                <Box>
                                    <Typography style={{ color: 'rgba(130, 130, 130, 1)', fontSize: '18px', fontStyle: 'Roboto' }}>Round</Typography>
                                    <Typography className={classes.valueTxt}>{this.capitalizeFirstLetter('seed+')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.minimumInvestmentBox}>
                            <Box className={classes.customBtn}>
                                <img src={fees} alt={`Image`} style={{ height: '64px', width: '64px' }} />
                                <Box>
                                    <Typography style={{ color: 'rgba(130, 130, 130, 1)', fontSize: '18px', fontStyle: 'Roboto' }}>Estimated Fees</Typography>
                                    <Typography className={classes.valueTxt}>{opportunityDetails?.attributes?.estimated_fee}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.customBtn}>
                                <img src={premoney} alt={`Image`} style={{ height: '64px', width: '64px' }} />
                                <Box>
                                    <Typography style={{ color: 'rgba(130, 130, 130, 1)', fontSize: '18px', fontStyle: 'Roboto' }}>Pre-Money Valuation</Typography>
                                    <Typography className={classes.valueTxt}>${this.numberWithCommaOpportunity(opportunityDetails?.attributes?.pre_money_valuation)}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.customBtn}>
                                <img src={share} alt={`Image`} style={{ height: '64px', width: '64px' }} />
                                <Box>
                                    <Typography style={{ color: 'rgba(130, 130, 130, 1)', fontSize: '18px', fontStyle: 'Roboto' }}>Share Type</Typography>
                                    <Typography className={classes.valueTxt}>{this.capitalizeFirstLetter(opportunityDetails?.attributes?.share_type)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        </Box>

                        {opportunityDetails?.attributes?.status == 'live' && opportunityDetails?.attributes?.documents.data.length > 0 &&
                            <Box className={classes.showDocsWrapper} style={{ filter: (kycStatus !== "verified") ? 'blur(4px)' : '' }}>
                                {this.showDocuments(opportunityDetails?.attributes?.documents?.data)}
                            </Box>
                        }

                        {!viewButton && opportunityDetails?.attributes?.investment_memo.data.map((data: any, index: number) => (
                            <Box className={classes.opportunityDetailsBox} style={{ flexDirection: index % 2 ? 'row-reverse' : 'row', alignItems: 'center' }}>
                                <Box className="memoBox" >
                                    {index === 0 && <Typography style={{ fontSize: '48px', fontStyle: 'italic', fontFamily: 'lato' }}>Memo</Typography>}
                                    <Typography style={{ maxWidth: '570px', fontSize: '16px', color: 'rgba(130, 130, 130, 1)', marginTop: '15px', fontFamily: 'Roboto' }} >{data.attributes.description}</Typography>
                                </Box>
                                <Box >
                                    <img src={data.attributes.image} className="image" />
                                </Box>
                            </Box>
                        ))}
                        {(viewButton && opportunityDetails?.attributes?.investment_memo.data.length > 0) &&
                            <Box className={classes.opportunityDetailsBox} style={{ alignItems: 'center' }}>
                                <Box className="memoBox" >
                                    <Typography style={{ fontSize: '48px', fontStyle: 'italic', fontFamily: 'lato' }}>Memo</Typography>
                                    <Typography style={{ maxWidth: '570px', fontSize: '16px', color: 'rgba(130, 130, 130, 1)', marginTop: '15px', fontFamily: 'Roboto' }} >{opportunityDetails?.attributes?.investment_memo.data[0]?.attributes.description}</Typography>
                                </Box>
                                <Box >
                                    <img src={opportunityDetails?.attributes?.investment_memo.data[0]?.attributes.image} className="image" />
                                </Box>
                            </Box>
                        }

                        {viewButton && kycStatus === "verified" &&
                            <Box style={{ justifyContent: 'center', display: 'flex', marginTop: '19px' }}>
                                <Button className={classes.viewMemoBtn} onClick={this.showDetails}> View Full Investment Memo</Button>
                            </Box >}

                        {(kycStatus !== "verified") &&
                            <Box className={classes.viewInvestment}>
                                <Typography style={{ color: 'rgba(34, 34, 34, 1)', fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', width: '308px', fontWeight: 700 }}>
                                    Complete the declaration to view full investment memo
                                </Typography>
                                <Button data-testid='declarationBtn' className={classes.viewBtn} onClick={this.completeDeclaration}>Complete Declaration</Button>
                            </Box>
                        }
                        <Box style={{ display: 'flex', justifyContent: 'center', }}>
                            <Typography style={{ fontStyle: 'italic', fontFamily: 'lato', fontSize: '48px', fontWeight: '800px' }} >Team</Typography>
                        </Box>
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '40px', justifyContent: 'center', flexWrap: 'wrap' }}>
                            {opportunityDetails?.attributes?.company.data[0]?.attributes.team_members.data.map((data: any) => (
                                <Box style={{ textAlign: 'center' }}>
                                    <img src={data.attributes.image} alt={`Image`} style={{ height: '360px', width: '270px', borderRadius: '16px' }} />
                                    <Typography style={{ marginTop: '15px', fontFamily: 'lato', fontSize: '24px', fontWeight: '700px', width: '270px', wordBreak: 'break-word' }}>{data.attributes.name}</Typography>
                                    <Typography style={{ marginTop: '5px', height: '21px', width: '270px', fontFamily: 'Roboto', fontSize: '18px', fontWeight: '500px', color: 'rgba(0, 105, 188, 1)',wordBreak: 'break-word' }}>{data.attributes.role}</Typography>
                                </Box>
                            ))}
                        </Box>

                        <Box className={classes.investorBox}>
                            <Typography style={{ fontSize: '28px', padding: '32px', fontWeight: 700, fontFamily: 'Lato', color: '#FFFFFF' }}>Our Co-Investors</Typography>
                            <img src={imageArrow} />
                            <Swiper
                                slidesPerView={5}
                                speed={1500}
                                navigation
                                autoplay={{ delay: 700 }}
                                className={classes.swiper}
                                loop
                                breakpoints={{
                                            '@0.0': {
                                              slidesPerView: 1,
                                              spaceBetween: 10,
                                            },
                                            '@0.60': {
                                              slidesPerView: 1,
                                              spaceBetween: 10,
                                            },
                                            '@0.70': {
                                              slidesPerView: 2,
                                              spaceBetween: 10,
                                            },
                                            '@0.90': {
                                              slidesPerView: 3,
                                              spaceBetween: 20,
                                            },
                                            '@1.00': {
                                              slidesPerView: 4,
                                              spaceBetween: 20,
                                            },
                                            '@1.50': {
                                              slidesPerView: 5,
                                              spaceBetween: 20,
                                            },
                                          }}
                            >
                                {opportunityDetails?.attributes?.co_investors.data.map((data: any, index: number) => (
                                    <SwiperSlide style={{
                                        justifyContent: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'auto',
                                        padding: '2px'
                                    }}>
                                        <img
                                            src={data.attributes?.image}
                                            key={index}
                                            alt={data.attributes.company_name}
                                            style={{
                                                width: '35%',
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>


                        <Dialog
                            open={allocationAmountOpen}
                            onClose={this.handleAllocationAmountClose}
                            className={classes.dialogContainer}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px'
                                }
                            }}
                        >
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <Typography className={classes.dialogTitleStyle}>Enter allocation amount</Typography>
                                <CloseIcon className={classes.dialogCloseStyle} onClick={this.handleAllocationAmountClose} />
                            </DialogTitle>
                            <DialogContent>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px 0px' }}>
                                    <FormControl variant="outlined" style={{ margin: "16px 0px", width: '100%', display: 'flex', gap: '16px' }}>
                                        <NumericFormat
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            value={amount}
                                            onChange={this.handleAmountChange}
                                            placeholder="$Enter allocation amount"
                                            style={{
                                                maxWidth: '522px',
                                                width: "100%",
                                                height: "48px",
                                                borderRadius: "8px",
                                                paddingLeft: '14px',
                                                fontSize: '16px',
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: '0 0 0 1px #BDBDBD inset'
                                            }}
                                        />
                                        <Box style={{ display: 'flex', gap: '16px' }}>
                                            <Button
                                                className={classes.amountBtn}
                                                onClick={() => this.handleSelectedAmount("100000")}
                                                style={{
                                                    background: selectedAmount === '100000' ? 'rgba(0, 105, 188, 1)' : 'rgba(255, 255, 255, 1)',
                                                    color: selectedAmount === '100000' ? 'rgba(255, 255, 255, 1)' : 'rgba(189, 189, 189, 1)',
                                                    fontWeight: selectedAmount === '100000' ? 700 : 400
                                                }}>
                                                100K
                                            </Button>
                                            <Button
                                                className={classes.amountBtn}
                                                onClick={() => this.handleSelectedAmount("200000")}
                                                style={{
                                                    background: selectedAmount === '200000' ? 'rgba(0, 105, 188, 1)' : 'rgba(255, 255, 255, 1)',
                                                    color: selectedAmount === '200000' ? 'rgba(255, 255, 255, 1)' : 'rgba(189, 189, 189, 1)',
                                                    fontWeight: selectedAmount === '200000' ? 700 : 400
                                                }}>
                                                200K
                                            </Button>
                                            <Button
                                                className={classes.amountBtn}
                                                onClick={() => this.handleSelectedAmount("300000")}
                                                style={{
                                                    background: selectedAmount === '300000' ? 'rgba(0, 105, 188, 1)' : 'rgba(255, 255, 255, 1)',
                                                    color: selectedAmount === '300000' ? 'rgba(255, 255, 255, 1)' : 'rgba(189, 189, 189, 1)',
                                                    fontWeight: selectedAmount === '300000' ? 700 : 400
                                                }}>
                                                300K
                                            </Button>
                                        </Box>
                                        {amountError === '' && <Typography style={{ fontSize: '14px', fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)' }}><span style={{ color: 'rgba(0, 105, 188, 1)' }}>*</span>The minimum investment is $100K</Typography>}
                                        {amountError && (
                                            <Box style={{ display: 'flex' }}>
                                                <ErrorOutlineIcon style={{ color: 'red', width: '20px' }} />
                                                <FormHelperText className={classes.errorText}>
                                                    {amountError}
                                                </FormHelperText>
                                            </Box>)}
                                    </FormControl>
                                    <Button className={classes.submitBtn} onClick={this.handleSubmit}>Submit</Button>
                                    <Typography style={{ fontSize: '14px', cursor: 'pointer', fontFamily: 'Roboto', fontWeight: 700, textAlign: 'center', color: 'rgba(0, 105, 188, 1)' }} onClick={this.navigateToContact}>Need help? Contact us</Typography>
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={reviewDocumentOpen}
                            onClose={this.handleReviewDocumentClose}
                            className={classes.dialogContainer}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px'
                                }
                            }}
                        >
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <ArrowBackIosIcon style={{ position: 'absolute', top: '28px', left: '24px', cursor: 'pointer' }} onClick={this.handleReviewDocumentClose} />
                                <Typography className={classes.dialogTitleStyle} style={{ paddingLeft: "20px" }}>Review Documents & Sign</Typography>
                            </DialogTitle>
                            <DialogContent>
                            <Loader loading={isLoading} />
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px 0px' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', border: '1px solid rgba(218, 218, 218, 1)', borderRadius: '10px', padding: '24px', justifyContent: 'space-around' }}>
                                        <img src={imageDoc} />
                                        <Box style={{ maxWidth: '300px', width: '100%' }}>
                                            <Typography style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Roboto' }}>Sign investment document</Typography>
                                            <Typography style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto' }}>Finalized documents will be emailed to you on your registered email ID.</Typography>
                                        </Box>
                                    </Box>
                                    <FormControlLabel
                                        className={classes.checkBoxStyle}
                                        control={<Checkbox className={classes.checkBoxStyle} checked={checkBox} onChange={this.handleCheckboxChange} />}
                                        label={
                                            <span>
                                                <Typography style={{ fontSize: "14px", fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)' }}>
                                                    I have read the {" "}
                                                    <span style={{ color: "#0069BC", textDecoration: 'none', fontFamily: 'Roboto', fontWeight: 700 }} 
                                                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                            this.redirectToTermAndCond(event)
                                                        }}
                                                    > Terms and Provisions </span>and agree to e-sign the above document
                                                </Typography>
                                            </span>
                                        }
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {reviewDocumentError.checkBox}
                                    </FormHelperText>
                                    <Button className={classes.signBtn} onClick={this.handleReviewDocumentDone}>Confirm and Sign</Button>
                                </Box>
                            </DialogContent>
                        </Dialog>


                        <Dialog
                            open={wireTransferOpen}
                            onClose={this.handleWireTransferOpen}
                            className={classes.dialogContainer}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px'
                                }
                            }}
                        >
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <ArrowBackIosIcon style={{ position: 'absolute', top: '28px', left: '24px', cursor: 'pointer' }} onClick={this.backToWireTransfer} />
                                <Typography className={classes.dialogTitleStyle}>Wire Transfer</Typography>
                                <CloseIcon style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }} onClick={this.handleAllocationAmountClose} />
                            </DialogTitle>
                            <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '32px', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                                    <Box style={{ display: 'flex', flexDirection: 'column', border: '1px solid rgba(218, 218, 218, 1)', borderRadius: '10px', padding: '24px', gap: '24px' }}>
                                        <Box style={{ display: 'flex', gap: '32px' }}>
                                            <img src={groupBank} style={{ height: '40px', width: '40px', marginTop: '17px' }} />
                                            <Box>
                                                <Typography style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Roboto' }}>Transfer Fund</Typography>
                                                <Typography style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)' }}>Convenient, No Fees, Transfers complete in 3-5 business days</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'center', gap: '8px', whiteSpace: 'nowrap' }}>
                                            <Button className={classes.wireInstructions} onClick={this.wireInstructions}>Wire Instructions</Button>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontSize: "16px", fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)' }}>
                                            Please read the wire instructions and wire the amount via your mobile or net banking, post initiating the wire please enclose a screenshot below and click on Wire Initiated.
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                    <Box>
                                        <span style={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Legal name</span>
                                        <OutlinedInput
                                            style={{
                                                maxWidth: '522px',
                                                width: "100%",
                                                height: "48px",
                                                borderRadius: "8px",
                                                justifyContent: 'center',
                                                margin: '16px 0px',
                                                fontWeight: 600
                                            }}
                                            value={wireTransferName}
                                            onChange={this.handleNameChange}
                                            type="text"
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {wireTransferPageError.wireTransferName}
                                        </FormHelperText>
                                    </Box>

                                    <Box>
                                        <span style={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Email address</span>
                                        <OutlinedInput
                                            style={{
                                                maxWidth: '522px',
                                                width: "100%",
                                                height: "48px",
                                                borderRadius: "8px",
                                                justifyContent: 'center',
                                                margin: '16px 0px',
                                                fontWeight: 600
                                            }}
                                            value={wireTransferEmail}
                                            onChange={this.handlestartupNameChange}
                                            // onBlur={this.validateAmount}
                                            type="text"
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {wireTransferPageError.wireTransferEmail}
                                        </FormHelperText>
                                    </Box>

                                    <Box>
                                        <span style={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Amount</span>
                                        <NumericFormat
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            value={wireAmount}
                                            onChange={this.handleWebsiteChange}
                                            placeholder="$"
                                            style={{
                                                paddingLeft: '14px',
                                                fontSize: '16px',
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: '0 0 0 1px #BDBDBD inset',
                                                maxWidth: '522px',
                                                width: "100%",
                                                height: "48px",
                                                borderRadius: "8px",
                                                justifyContent: 'center',
                                                margin: '16px 0px',
                                                fontWeight: 600
                                            }}
                                        />
                                        <FormHelperText className={classes.errorText}>
                                            {wireTransferPageError.wireAmount}
                                        </FormHelperText>
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'column', height: '68px', maxWidth: '522px', width: "100%", }}>
                                        <Typography style={{ fontSize: '16px', fontWeight: 400, marginBottom: '5px', fontFamily: 'Roboto', color: 'rgba(130, 130, 130, 1)' }}>Enclose Proof of Transfer<span style={{ color: 'red' }}>*</span></Typography>
                                        <Box style={{ position: 'relative' }}>
                                            <label htmlFor="uploadDoc">
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="uploadDoc"
                                                    name="uploadDoc"
                                                    type="file"
                                                    onChange={this.uploadDoc}
                                                    data-test-id="uploadFileBtn"
                                                />
                                                {(esopLetter === null || esopLetter === undefined) ? (
                                                    <Button
                                                        color="secondary"
                                                        className={classes.uploadBtn}
                                                        variant="outlined" component="span">
                                                        <img src={buttons} />
                                                    </Button>
                                                ) : (
                                                    <Box className={classes.uploadedDoc}>
                                                        <Box style={{ display: 'flex', gap: '12px', fontFamily:"Roboto" }}>
                                                            <img src={previewImage} style={{height: '24px', width: '24px'}} />
                                                            {esopLetter.name}
                                                        </Box>
                                                        <img src={imageButtonClose} onClick={this.handleRemoveESOPDocFile} style={{ cursor: 'pointer' }} />
                                                    </Box>
                                                )}
                                            </label>
                                            <FormHelperText className={classes.errorText}>
                                                {wireTransferPageError.esopLetter}
                                            </FormHelperText>
                                        </Box>
                                    </Box>

                                    <Box style={{ textAlign: "center" }}>
                                        <Button className={classes.signBtn} onClick={this.WireInitiatedSuccessPopUp}>Wire Initiated</Button>
                                        <Typography style={{ fontSize: '14px', cursor: 'pointer', fontFamily: 'Roboto', fontWeight: 700, textAlign: 'center', color: 'rgba(0, 105, 188, 1)' }} onClick={this.navigateToContact}>Need help? Contact us</Typography>
                                    </Box>
                                </Box>

                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={wireInitiatedSuccess}
                            className={classes.dialogContainer}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px'
                                }
                            }}>
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <CloseIcon style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }} onClick={this.handleAllocationAmountClose} />
                            </DialogTitle>
                            <DialogContent>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px 0px' }}>
                                    <Box style={{ textAlign: 'center' }} >
                                        <img src={checkMark} style={{ height: '160px', width: '160px' }} />
                                        <Typography style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: 'rgba(111, 111, 112, 1)', textAlign: 'left' }}>
                                            You've successfully initiated a wire transfer. Please be advised that it typically takes 3-4 working days for the wire transfer to be reflected in our accounts. You'll receive a confirmation as soon as the transfer is complete.
                                        </Typography>
                                        <Button className={classes.browseOppurtunities} onClick={this.navigateToInvestList}>Browse Other Opportunities</Button>
                                    </Box>

                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={wireInstructionOpen}
                            onClose={this.handleWireTransferOpen}
                            className={classes.dialogContainer}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px'
                                }
                            }}
                        >
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <ArrowBackIosIcon style={{ position: 'absolute', top: '28px', left: '24px', cursor: 'pointer' }} onClick={this.backTopWireInstructions} />
                                <Typography className={classes.dialogTitleStyle}>Wire instructions</Typography>
                                <CloseIcon style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }} onClick={this.handleAllocationAmountClose} />
                            </DialogTitle>
                            <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '32px', scrollbarWidth: 'none', msOverflowStyle: 'none', marginBottom: '30px' }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '32px', minHeight: '310px', height: 'auto' }}>
                                    <Box style={{ paddingTop: "20px" }}>
                                        <span className={classes.wireInstructLabel}>Wire Instructions</span>
                                        <Typography style={{fontStyle: 'italic'}} className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.description) }}></Typography>
                                    </Box>
                                    <Box>
                                        <span className={classes.wireInstructLabel}>Bank</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addUnderlineBreak(wire_instruction_data?.bank) }}></Typography>
                                    </Box>
                                    <Box>
                                        <span className={classes.wireInstructLabel}>Amount</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.amount) }}></Typography>
                                    </Box>
                                    <Box>
                                        <span className={classes.wireInstructLabel}>ABA</span>
                                        <Typography style={{textDecoration: 'underline'}} className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.aba) }}></Typography>
                                    </Box>
                                    <Box>
                                        <span className={classes.wireInstructLabel}>Account Number</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.account_number) }}></Typography>
                                    </Box>

                                    <Box>
                                        <span className={classes.wireInstructLabel}>Beneficiary</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addUnderlineBreak(wire_instruction_data?.beneficiary) }}></Typography>
                                    </Box>

                                    <Box>
                                        <span className={classes.wireInstructLabel}>SWIFT</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.swift) }}></Typography>
                                    </Box>

                                    <Box>
                                        <span className={classes.wireInstructLabel}>Intermediary Bank</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addUnderlineBreak(wire_instruction_data?.intermediary_bank) }}></Typography>
                                    </Box>

                                    <Box>
                                        <span className={classes.wireInstructLabel}>Intermediary Bank SWIFT</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.intermediary_bank_swift) }}></Typography>
                                    </Box>
                                    <Box>
                                        <span className={classes.wireInstructLabel}>IBAN</span>
                                        <Typography className={classes.wireInstructContent} dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.iban) }}></Typography>
                                    </Box>
                                    <Box>
                                        <Typography dangerouslySetInnerHTML={{ __html: this.addBreak(wire_instruction_data?.content) }}></Typography>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={joinWaitlistBox}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px',
                                    padding: '32px 0px 32px 0px'
                                }
                            }}
                        >
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <Typography className={classes.dialogTitleStyle}> Join the waitlist</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                                    <Box style={{ padding: '6px' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '14px', textAlign: 'center', color: 'rgba(111,111,112,1)' }}>
                                            We'll notify you once this opportunity is live. To help us understand the level of interest in
                                            this opportunity, please tell us how much you would like to invest.
                                        </Typography>
                                    </Box>
                                    <Box style={{ height: '101px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '14px' }}>How much would you consider investing? (optional)</Typography>
                                        <NumericFormat
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            value={joinamount}
                                            onChange={this.handleJoinAmountChange}
                                            placeholder="$"
                                            style={{
                                                maxWidth: '522px',
                                                width: "100%",
                                                height: "48px",
                                                paddingLeft: '14px',
                                                fontSize: '16px',
                                                borderRadius: "8px",
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: '0 0 0 1px #BDBDBD inset',
                                                ...(this.state.isFocused && {
                                                    border: '1px dashed rgb(0, 105, 188)',
                                                }),
                                            }}
                                            onFocus={() => this.setState({ isFocused: true })}
                                            onBlur={() => this.setState({ isFocused: false })}
                                        />
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 600, fontSize: '14px' }}><span style={{ color: 'rgba(0,105,188,1)' }}>*</span>The minimum investment is $100k</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                        <Button className={classes.cancelBtn} onClick={this.handleJoinListCancel}>Cancel</Button>
                                        <Button className={classes.joinListBtn} onClick={this.handleSubmitJoinWaitList}>Join Waitlist</Button>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={successJoinList}
                            className={classes.dialogContainer}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px'
                                }
                            }}>
                            <DialogTitle style={{ padding: '16px 24px 0px', position: 'relative' }}>
                                <CloseIcon style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }} onClick={this.handleSuccessJoinBox} />
                            </DialogTitle>
                            <DialogContent>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px 0px' }}>
                                    <Box style={{ textAlign: 'center' }} >
                                        <img src={checkMark} style={{ height: '160px', width: '160px' }} />
                                        <Typography style={{ fontSize: '32px', fontWeight: 800, fontFamily: 'Lato', display: 'flex', justifyContent: 'center', color: 'rgba(34, 34, 34, 1)' }}>
                                            Waitlist Joined !
                                        </Typography>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </Dialog>

                         <Dialog
                            open={joinWaitlistSecondaryBox}
                            PaperProps={{
                                style: {
                                    borderRadius: 16,
                                    width: '570px',
                                    padding: '32px 0px 32px 0px'
                                }
                            }}
                        >
                            <DialogContent>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                    <Box style={{ height: 'auto'}}>
                                        <Typography style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '16px', textAlign: 'center', color: 'rgba(111,111,112,1)' }}>
                                            Please leave your interest here, and we will notify you as soon as the diligence process is complete and the opportunity is live.
                                        </Typography>
                                    </Box>
                                    <Box style={{ height: '101px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        <FormControl
                                            variant="outlined"
                                            style={{ margin: "16px 0px", width: "100%" }}
                                        >
                                            <FormHelperText
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#828282",
                                                    fontFamily: "Roboto",
                                                    margin: "0 0 8px",
                                                }}
                                            >
                                                Email
                                            </FormHelperText>
                                            <OutlinedInput
                                                style={{
                                                    height: "48px",
                                                    borderRadius: "8px",
                                                }}
                                                onChange={this.handleEmailChange}
                                                value={emailAddress}
                                                onBlur={this.validateEmails}
                                                data-test-id="emailField"
                                            />
                                            {this.state.emailError && (
                                                <FormHelperText
                                                    className={classes.errorText}
                                                >
                                                    {this.state.emailError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className={classes.joinListBtn} onClick={this.handleSecondaryWaitList} data-test-id="secondaryJoinListBtn">Submit</Button>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </>
                </Container>

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(OpportunitiesDetailsWeb);
// Customizable Area End
