import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import moment from "moment";

interface ApiResponse {
    data: {
        message: string;
        success: boolean;
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    investorType: string;
    investorTypeSelect: boolean;
    experience_alternative_investment: string;
    alternative_investment_strategy: string;
    capital_actively_invested: string;
    organisation_accredited: string;
    percentage_to_allocate: string;
    is_family_run: string;
    first_name: string;
    first_name_error: string;
    last_name: string;
    last_name_error: string;
    phone_number: string;
    phone_number_error: string;
    email: string;
    email_error: string;
    address: string;
    address_error: string;
    identification_type: string;
    identification_type_error: string;
    identification_number: string;
    identification_number_error: string;
    page: number;
    kycVerified: boolean;
    kycStatus: string;
    kycFailed: boolean;
    selectedCheckbox: any;
    open: boolean;
    firstName: string;
    firstName_error: string;
    lastName: string;
    lastName_error: string;
    contactEmail: string;
    contactEmail_error: string;
    confirmSelection: boolean;
    confirmation_error: string;
    country_code: any;
    country_code_error: any;
    date_of_birth: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
    issue_date: string;
    expiration_date: string;
    date_of_birth_error: string;
    city_error: string;
    state_error: string;
    postal_code_error: string;
    country_error: string;
    issue_date_error: string;
    expiration_date_error: string;
    otp: string;
    validateAadharStatus: boolean;
    seconds: number;
    timerRunning: boolean;
    unableToVerify: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class InvestorProfileWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    userKycApiCallId: string = ''
    stayInTouchApiCallId: string = ''
    submitKycStatusApiCallId: string = ''
    validateNationalCallId: string = ''
    sendOtpCallId: string = ''
    confirmOtpCallId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            investorType: '',
            token: localStorage.getItem('token') || "",
            experience_alternative_investment: '',
            investorTypeSelect: true,
            capital_actively_invested: '',
            alternative_investment_strategy: '',
            percentage_to_allocate: '',
            organisation_accredited: '',
            first_name: '',
            first_name_error: '',
            is_family_run: '',
            phone_number: '',
            phone_number_error: '',
            country_code_error: "",
            country_code: { "name": "India", "dial_code": "+91", "code": "IN" },
            last_name: '',
            last_name_error: '',
            address: '',
            address_error: '',
            email: '',
            email_error: '',
            identification_number: '',
            identification_number_error: '',
            identification_type: '',
            identification_type_error: '',
            page: 1,
            kycFailed: false,
            kycVerified: false,
            selectedCheckbox: null,
            open: false,
            firstName: '',
            firstName_error: '',
            lastName: '',
            lastName_error: '',
            contactEmail: '',
            contactEmail_error: '',
            confirmSelection: false,
            confirmation_error: '',
            kycStatus: '',
            date_of_birth: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
            issue_date: '',
            expiration_date: '',
            date_of_birth_error: '',
            city_error: '',
            state_error: '',
            postal_code_error: '',
            country_error: '',
            issue_date_error: '',
            expiration_date_error: '',
            otp:'',
            validateAadharStatus:false,
            seconds: 60,
            timerRunning: false,
            unableToVerify: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.userKycApiCallId) {
                this.userKycApiCallIdResponse(responseJson)
            } else if (apiRequestCallId === this.stayInTouchApiCallId) {
                this.setState({ loading: false, page: this.state.page + 1 });
            }
            else if (apiRequestCallId === this.submitKycStatusApiCallId) {
                if (responseJson.data) {
                    responseJson.data.attributes.kyc_status === "skipped" && this.props.navigation.navigate("HomeScreenWeb");
                    setStorageData("kyc_status", this.state.kycStatus)
                } else {
                    toast.error(responseJson?.errors[0]?.token);
                }
            }
            else if (apiRequestCallId === this.validateNationalCallId) {
                this.validateNationalCallIdResponse(responseJson)
            }
            else if (apiRequestCallId === this.sendOtpCallId) {
                this.sendOtpCallIdResponse(responseJson)
            }
            else if (apiRequestCallId === this.confirmOtpCallId) {
                this.confirmOtpCallIdResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        window.scrollTo(0, 0);
        this.loadGoogleFont();
        const redirectTo = await getStorageData("supportContact")
        
        if (redirectTo == 'showUnableToVerify') {
            this.setState({
                unableToVerify: true
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            investorType: event.target.value
        })
    };

    handleCheckboxChange = (index: number) => {
        this.setState({
            selectedCheckbox: index
        });
    };

    setCountryCode = (event: React.ChangeEvent<{}>, value: any) => {
        this.setState({
            country_code: {
                "name": value.name,
                "dial_code": value.dial_code,
                "code": value.code
            }
        })
    }

    handleConfirmSelection = () => {
        this.setState({
            confirmSelection: !this.state.confirmSelection,
            confirmation_error:""
        })
    }

    goOnNextPage = () => {
        if (!this.state.confirmSelection) {
            this.setState({
                confirmation_error: 'Please confirm that your selection is accurate and that you are an accredited investor'
            })
        } else {
            this.setState({
                page: this.state.page + 1
            })
        }
    }

    handleAlternativeInvestmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            experience_alternative_investment: event.target.value
        })
    };

    handleInvestmentStrategyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            alternative_investment_strategy: event.target.value
        })
    };

    handleCapitalInvestedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            capital_actively_invested: event.target.value
        })
    };

    handleFNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            firstName: event.target.value
        })
    };

    validatefirstName = () => {
        const { firstName } = this.state;
        if (!firstName) {
            this.setState({ firstName_error: "First name is required" });
            return false;
        }
        if (/\d/.test(firstName)) {
            this.setState({ firstName_error: "First name cannot contain numeric numbers" });
            return false;
        }
        this.setState({ firstName_error: "" });
        return true;
    };

    handleLNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            lastName: event.target.value
        })
    };

    validatelastName = () => {
        const { lastName } = this.state;
        if (!lastName) {
            this.setState({ lastName_error: "Last name is required" });
            return false;
        }
        if (/\d/.test(lastName)) {
            this.setState({ lastName_error: "Last name cannot contain numeric numbers" });
            return false;
        }
        this.setState({ lastName_error: "" });
        return true;
    };

    handleContactEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            contactEmail: event.target.value
        })
    };

    validateEmailAddress = () => {
        const { contactEmail } = this.state;
        if (!contactEmail) {
            this.setState({ contactEmail_error: "Email is required" });
            return false;
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(contactEmail)) {
            this.setState({ contactEmail_error: "Invalid email address" });
            return false;
        }
        this.setState({ contactEmail_error: "" });
        return true;
    };

    handlePercentageAllocateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            percentage_to_allocate: event.target.value
        })
    };

    handleFamilyRunChange = (value: string) => {
        this.setState({
            is_family_run: value
        })
    };

    selectDocumentChange = (event: React.ChangeEvent<{value: any }>) => {
        this.setState({
            identification_type: event.target.value
        })
        if (event.target.value !== 'passport') {
            this.setState({
                issue_date: '',
                expiration_date: ''
            })
        }
    }

    validateDocument = () => {
        const { identification_type } = this.state;
        if (!identification_type) {
            this.setState({ identification_type_error: "Identification type is required" });
            return false;
        }
        this.setState({ identification_type_error: "" });
        return true;
    }

    handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            first_name: event.target.value
        })
    }

    validateFirstName = () => {
        const { first_name } = this.state;
        if (!first_name) {
            this.setState({ first_name_error: "Legal First name is required" });
            return false;
        }
        if (/\d/.test(first_name)) {
            this.setState({ first_name_error: "First name cannot contain numeric numbers" });
            return false;
        }
        this.setState({ first_name_error: "" });
        return true;
    };

    handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            last_name: event.target.value
        })
    }

    validateLastName = () => {
        const { last_name } = this.state;
        if (!last_name) {
            this.setState({ last_name_error: "Legal Last name is required" });
            return false;
        }
        if (/\d/.test(last_name)) {
            this.setState({ last_name_error: "Last name cannot contain numeric numbers" });
            return false;
        }
        this.setState({ last_name_error: "" });
        return true;
    };

    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            email: event.target.value
        })
    }

    validateEmail = () => {
        const { email } = this.state;
        if (!email) {
            this.setState({ email_error: "Email is required" });
            return false;
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            this.setState({ email_error: "Invalid email address" });
            return false;
        }
        this.setState({ email_error: "" });
        return true;
    };

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            phone_number: event.target.value
        })
    }

    validatePhoneNumber = () => {
        const { phone_number } = this.state;
        if (!phone_number) {
            this.setState({ phone_number_error: "Phone number is required" });
            return false;
        }
        if (!/^\d{10}$/.test(phone_number)) {
            this.setState({ phone_number_error: "Invalid phone number. It should be numeric and have 10 digits" });
            return false;
        }
        this.setState({ phone_number_error: "" });
        return true;
    };

    validateCountryCode = () => {
        const { country_code } = this.state;
        if (!country_code) {
            this.setState({ country_code_error: "Country code is required" });
            return false;
        }
        this.setState({ country_code_error: "" });
        return true;
    };

    handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            address: event.target.value
        })
    }

    validateAddress = () => {
        const { address } = this.state;
        if (!address) {
            this.setState({ address_error: "Address is required" });
            return false;
        }
        this.setState({ address_error: "" });
        return true;
    };

    handleIdentificationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            identification_number: event.target.value
        })
    }

    validateIdentificationNumber = () => {
        const { identification_number } = this.state;
        if (!identification_number) {
            this.setState({ identification_number_error: "Identification Number is required" });
            return false;
        }
        this.setState({ identification_number_error: "" });
        return true;
    };

    nextPage = () => {
        this.setState({
            investorTypeSelect: false,
            page: this.state.page + 1
        })
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    navigateOnLandingPage = () => {
        this.setState({
            kycStatus: "skipped"
        }, () => {
            this.kycStatusPatchList()
        })
    }

    handleStartOver = () => {
        this.setState({
            investorTypeSelect: true,
            loading: false,
            investorType: '',
            alternative_investment_strategy: '',
            experience_alternative_investment: '',
            is_family_run: '',
            capital_actively_invested: '',
            organisation_accredited: '',
            percentage_to_allocate: '',
            first_name: '',
            address: '',
            last_name: '',
            phone_number: '',
            country_code:'',
            email: '',
            identification_type: '',
            identification_number: '',
            page: 1,
            kycVerified: false,
            kycFailed: false,
            open: false,
            selectedCheckbox: null,
            firstName: '',
            lastName: '',
            contactEmail: '',
            token: localStorage.getItem('token') || "",
        })
    }

    handleUpdateAccrediation = () => {
        this.setState({
            page: 5
        })
    }

    handleKycSubmit = () => {
        const isFirstNameValid = this.validateFirstName();
        const isLastNameValid = this.validateLastName();
        const isEmailValid = this.validateEmail();
        const isAddressValid = this.validateAddress();
        const isPhoneNumberValid = this.validatePhoneNumber();
        const isDocumentSelected = this.validateDocument();
        const isValid = this.validateIdentificationNumber();
        const isCountryCodeValid = this.validateCountryCode()
        const isDobValid = this.validateDob()
        const isCityValid = this.validateCity()
        const isStateValid = this.validateState()
        const isPostalCodeValid = this.validatePostalCode()
        const isCountryValid = this.validateCountryCodeCheck()
        const isIssueDateValid = this.validateIssueDateCode()
        const isExpirationDateValid = this.validateExpirationDateCode()
        const validateDropDown = this.state.identification_type === "passport" ? (!isIssueDateValid || !isExpirationDateValid) : false
        if (!isFirstNameValid || !isLastNameValid || !isEmailValid || !isAddressValid || !isPhoneNumberValid || !isDocumentSelected || !isValid || !isCountryCodeValid || !isDobValid || !isCityValid || !isStateValid|| !isPostalCodeValid || !isCountryValid || validateDropDown) {
            return;
        } else {
            if (this.state.identification_type === "national_id") {
                this.validateAadharNumber()
            } else {
                this.userKycSubmit()
            }
        }
    }

    handleStayInTouch = () => {
        const isFirstNameValid = this.validatefirstName();
        const isLastNameValid = this.validatelastName();
        const isEmailValid = this.validateEmailAddress();

        if (!isFirstNameValid || !isLastNameValid || !isEmailValid) {
            return;
        } else {
            this.stayInTouch()
        }
    }

    indivisualOrganization = [
        'Qualified Purchaser - $5M or more in investments',
        'Accredited Investor - Income is greater than $200,000 in each of the prior 2 years',
        'Accredited Investor - Income is greater than $300,000 with spouse or partner in each of the prior 2 years',
        'Accredited Investor - Net worth of $1M or more in assets, excluding primary residence',
        'Accredited Investor - Hold a series 7, 65, or 82 license that is currently in good standing',
        'I am not an accredited investor',
        'I confirm that my selection is accurate and that I am an accredited investor',
    ]

    familyOrganization = [
        'Ownership - all equity owners are qualified purchasers',
        'Investments - $5M or more in investments owned (must be a “family company”)',
        'Investments - $25M or more in investments owned or managed',
        'Ownership - all equity owners are accredited investors',
        'My organization is not accredited',
        'I confirm that my selection is accurate and that I am an accredited investor'
    ]

    userKycSubmit = () => {
        this.setState({ loading: true });

        const {
            investorType,
            alternative_investment_strategy,
            experience_alternative_investment,
            capital_actively_invested,
            is_family_run,
            selectedCheckbox,
            percentage_to_allocate,
            phone_number,
            first_name,
            last_name,
            address,
            email,
            identification_number,
            identification_type,
            country_code,
            date_of_birth,
            city,
            state,
            postal_code,
            country,
            issue_date,
            expiration_date,
        } = this.state;

        const header = { "Content-Type": "application/json", token: this.state.token };
        const data = {
            "attributes": {
                "first_name": first_name,
                "last_name": last_name,
                "phone_number": phone_number,
                "country_code":country_code.dial_code,
                "email": email,
                "full_name": first_name + ' ' + last_name,
                "investor_type": investorType,
                "experience_alternative_investment": experience_alternative_investment,
                "alternative_investment_strategy": alternative_investment_strategy,
                "capital_actively_invested": capital_actively_invested,
                "organisation_accredited": investorType === 'As an individual' ? this.indivisualOrganization[selectedCheckbox] : this.familyOrganization[selectedCheckbox],
                "percentage_to_allocate": percentage_to_allocate,
                "address": address,
                "identification_type": identification_type,
                "identification_number": identification_number,
                "is_family_run": is_family_run === 'Yes',
                "date_of_birth": this.dateFormat(date_of_birth),
                "city": city,
                "state": state,
                "postal_code": postal_code,
                "country": country,
                "issue_date": this.dateFormat(issue_date),
                "expiration_date": this.dateFormat(expiration_date),
            }
        }
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.userKycApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/user_kycs");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    stayInTouch = () => {
        this.setState({ loading: true });

        const {
            firstName,
            lastName,
            contactEmail
        } = this.state;

        const header = { "Content-Type": "application/json", token: this.state.token };
        const data = {
            "attributes": {
                "first_name": firstName,
                "last_name": lastName,
                "email": contactEmail,
            }
        }
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.stayInTouchApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.stayInTouch);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    kycStatusPatchList = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("token") };
        const data = {
            "attributes": {
                "kyc_status": this.state.kycStatus
            }
        }
        setStorageData("kyc_status", this.state.kycStatus)
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.submitKycStatusApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/kyc_status");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            date_of_birth: event.target.value
        })
    }

    validateDob = () => {
        const { date_of_birth } = this.state;
        if (!date_of_birth) {
            this.setState({ date_of_birth_error: "Date of birth is required" });
            return false;
        }
        this.setState({ date_of_birth_error: "" });
        return true;
    };

    handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            city: event.target.value
        })
    }

    validateCity = () => {
        const { city } = this.state;
        if (!city) {
            this.setState({ city_error: "City is required" });
            return false;
        }
        this.setState({ city_error: "" });
        return true;
    };

    handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            state: event.target.value
        })
    }

    validateState = () => {
        const { state } = this.state;
        if (!state) {
            this.setState({ state_error: "State is required" });
            return false;
        }
        this.setState({ state_error: "" });
        return true;
    };

    handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 6) {
            this.setState({
                postal_code: value,
                postal_code_error: "" 
            });
        } else {
            this.setState({
                postal_code_error: ""
            });
        }
    }

    validatePostalCode = () => {
        const { postal_code } = this.state;
    
        if (!postal_code) { 
            this.setState({ postal_code_error: "Postal Code is required" });
            return false;
        }
    
        if (!/^\d{5,6}$/.test(postal_code)) { 
            this.setState({ postal_code_error: "Postal code is invalid" });
            return false;
        }
    
        this.setState({ postal_code_error: "" });
        return true;
    };
    

    handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            country: event.target.value
        })
    }

    validateCountryCodeCheck = () => {
        const { country } = this.state;
        if (!country) {
            this.setState({ country_error: "Country is required" });
            return false;
        }
        this.setState({ country_error: "" });
        return true;
    };

    handleIssueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            issue_date: event.target.value
        })
    }

    validateIssueDateCode = () => {
        const { issue_date } = this.state;
        if (!issue_date) {
            this.setState({ issue_date_error: "Issue Date is required" });
            return false;
        }
        this.setState({ issue_date_error: "" });
        return true;
    };

    handleExpirationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            expiration_date: event.target.value
        })
    }

    validateExpirationDateCode = () => {
        const { expiration_date } = this.state;
        if (!expiration_date) {
            this.setState({ expiration_date_error: "Expiration Date is required" });
            return false;
        }
        this.setState({ expiration_date_error: "" });
        return true;
    };    

    convertToHiddenNumber = (number: string, char: string) => {
        if (number.length >= 2) {
            return char.repeat(number.length - 2) + number.slice(-2);
        } else {
            return number;
        }
    }

    handleChangeOtp = (value: any) => {
        this.setState({
            otp: value
        })
    }

    validateAadharNumber = () => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const data = {
            "national_id": this.state.identification_number,
        }
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.validateNationalCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.validateNationalID);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    validateNationalCallIdResponse = (responseJson: ApiResponse) => {
        if (responseJson.data.success) {
            let pageNumber = this.state.investorType === 'individual' ? 8 : 9
            this.setState({
                validateAadharStatus: false,
                loading: false,
                page: pageNumber
            })
        } else {
            this.setState({
                validateAadharStatus: true
            })
            toast.error(responseJson.data.message)
        }
    }

    sendOTP = () => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const data = {
            "national_id": this.state.identification_number,
        }
        const httpBody = { data: data };
        const requestMessages = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sendOtpCallId = requestMessages.messageId;
        requestMessages.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessages.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessages.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessages.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sendOTP);
        runEngine.sendMessage(requestMessages.id, requestMessages);
    }

    sendOtpCallIdResponse = (responseJson: ApiResponse) => {
        if (responseJson.data.success) {
            let pageNumber = this.state.investorType === 'As an individual' ? 9 : 10
            this.setState({
                page: pageNumber,
                timerRunning: true,
                seconds:60,
                kycVerified: false,
                kycFailed: false,
                loading: false
            })
            this.resendTimer()
            toast.success(responseJson.data.message);
        } else {
            toast.error(responseJson.data.message)
        }
    }

    confirmOTP = () => {
        if(this.state.otp){
        const header = { "Content-Type": "application/json", token: this.state.token };
        const data = {
            "otp": this.state.otp,
        }
        const httpBody = { data: data };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.confirmOtpCallId = requestMsg.messageId;
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.confirmOTP);
        runEngine.sendMessage(requestMsg.id, requestMsg);
        }
    }

    confirmOtpCallIdResponse = (responseJson: ApiResponse) => {
        if (responseJson.data.success) {
            this.userKycSubmit()
        } else {
            toast.error(responseJson.data.message)
        }
    }

    resendTimer = () => {
        const intervalId = setInterval(() => {
            const { timerRunning, seconds } = this.state;
            if (timerRunning && seconds > 0) {
                this.setState(prevState => ({
                    seconds: prevState.seconds - 1
                }));
            } else if (seconds === 0) {
                clearInterval(intervalId);
                this.setState({
                    timerRunning: false,
                });
            }
        }, 1000);
    }

    resendOTP = () => {
        if (!this.state.timerRunning) {
            this.sendOTP()
        }
    }

    userKycApiCallIdResponse = (responseJson: any) => {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
            const isVerifiedStatus = responseJson.data.attributes.is_verified
            this.setState({
                kycVerified: isVerifiedStatus,
                kycFailed: !isVerifiedStatus,
                kycStatus: isVerifiedStatus ? "verified" : "filled"
            }, () => {
                !isVerifiedStatus ? this.kycStatusPatchList() : setStorageData("kyc_status", this.state.kycStatus);
            })
            setTimeout(() => {
                const goToHomeScreenWeb: Message = new Message(getName(MessageEnum.NavigationMessage));
                goToHomeScreenWeb.addData(getName(MessageEnum.NavigationTargetMessage), "HomeScreenWeb");
                goToHomeScreenWeb.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(goToHomeScreenWeb);
            }, 3000);
        } else if (responseJson && responseJson.errors) {
            toast.error(responseJson?.errors[0]);
        }
    }

    dateFormat = (date: string) => {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }

    redirectToContact = async () => {
        const navigateToContactPage: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateToContactPage.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
        navigateToContactPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToContactPage);
        await setStorageData("contactUs", 'showContactUs');
    }
    loadGoogleFont = () => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Inter&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    };
    // Customizable Area End
}
