import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Typography } from "@material-ui/core";
import { profilePlaceholder } from "../../blocks/settings2/src/assets";
import { headerLogo } from "./assets";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import { getStorageData, setStorageData, removeStorageData } from "../../framework/src/Utilities";
const baseURL = require("../../framework/src/config.js").baseURL;
import { toast } from "react-toastify";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

interface myProps {
  navigation?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 180px',
    justifyContent: 'space-between',
    width: '100%',
    '@media(max-width:1600px)':{
      margin: '0px 150px',
    },
    '@media(max-width:1280px)':{
      margin: '0px 120px',
    }
  },
  forMenues: {
    display: 'flex',
    justifyContent:'center',
    gap: '32px',
    width:'580px',
    marginLeft:'30px',
    '@media(max-width:1024px)':{
      gap:'15px'
    },
    [theme.breakpoints.down(900)]: {
      display: 'none'
    }
  },
  forMenuItems: {
    fontSize: '23.97px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    textDecoration: 'none',
    color: '#FFFFFF',
    cursor: 'pointer',
    '@media(max-width:1600px)':{
      fontSize: '19.97px',
    },
    '@media(max-width:1280px)':{
      fontSize: '15.98px',
    }
  },
  activeMenuItem: {
    color: '#FFFFFF',
    position: 'relative',
    fontWeight: 700,
    '&::after': {
      content: '""',
      textDecoration: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      borderBottom: '4px solid #FFFFFF'
    }
  },
  signUpButton: {
    color: '#0069BC',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    borderRadius: '10.67px',
    backgroundColor: '#ffffff',
    fontSize: '18.67px',
    height: '53.33px',
    border: 'none',
    fontFamily: 'Roboto',
    fontWeight: 700,
    width: '205.33px',
    '@media(max-width:1600px)':{
      fontSize: '15.61px',
      borderRadius: '8.92px',
      height: '44.61px',
      width: '171.75px',
    },
    '@media(max-width:1280px)':{
      fontSize: '12.44px',
      borderRadius: '7.11px',
      height: '35.56px',
      width: '136.89px',
    }
  },
  menuBar: {
    display: 'none',
    [theme.breakpoints.down(900)]: {
      display: 'flex',
      justifyContent: 'center',
      width: '25%'
    }
  },
  toastCSS: {
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '70%',
    },
  },
  name:{ 
    fontWeight: 500, 
    fontSize: '23.97px', 
    color: '#FFFFFF',
    textTransform: 'capitalize',
    maxWidth:'170px',
    width:'100%',
    overflow:'hidden',
    textOverflow:"ellipsis",
    whiteSpace:'nowrap',
    '@media(max-width:1600px)':{
      fontSize: '19.97px', 
    },
    '@media(max-width:1280px)':{
      fontSize: '15.98px',
    },
    '@media(max-width:600px)':{
      fontSize:'14px',
      maxWidth:'58px'
    }
  },
  hello: {
    color: '#FFFFFF',
    fontSize: '18.64px',
    '@media(max-width:1600px)':{
      fontSize: '15.53px',
    },
    '@media(max-width:1280px)':{
      fontSize: '12.43px',
    }
  },
  helloBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '53px',
    '@media(max-width:1600px)': {
      gap: '43px',
    },
    '@media(max-width:1280px)': {
      gap: '35px',
    }
  },
  profileImage: {
    width: '63px',
    height: '63px',
    borderRadius: '50%',
    '@media(max-width:1600px)': {
      width: '53px',
      height: '53px',
    },
    '@media(max-width:1280px)': {
      width: '42px',
      height: '42px',
    }
  },
  paper: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '121.3px',
    '@media(max-width:1600px)': {
      height: '101.1px',
    },
    '@media(max-width:1280px)': {
      height: '81.52px',
    },
    '@media(max-width:900px)': {
      width: '95%'
    }
  },
  burgerBtn: {
    color: 'white',
    margin: '0 20px'
  },
  logo: {
    cursor: 'pointer',
    width: '147.39px',
    '@media(max-width:1600px)': {
      width: '122.83px',
    },
    '@media(max-width:1280px)': {
      width: '98.26px',
    },
    '@media(max-width:600px)': {
      width: '80px'
    }
  }
}));

export default function AppHeader(props: myProps) {

  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [anchorInvestMenu, setAnchorInvestMenu] = React.useState(null);
  const [showLoader, setShowLoader] = React.useState(false);

  const open = Boolean(anchorMenu);
  const openInvest = Boolean(anchorInvestMenu);
  const [profile, setProfile] = React.useState({ attributes: { first_name: '', photo: null } });

  const fetchSecretData = async () => {
    const URL = baseURL + '/account_block/profile'
    const header = { "Content-Type": "application/json", token: await getStorageData("token") };
    fetch(URL, { headers: header })
      .then(response => response.json())
      .then(data => {
        if (data?.errors) {
          removeStorageData("userInfo")
          removeStorageData("token");
          removeStorageData("kyc_status")
          removeStorageData("userName")
          removeStorageData("userId")
          removeStorageData("wireTransfer")
          removeStorageData("supportContact")
          setShowLoader(false)
          toast.error(data.errors[0])
        }

        if (data?.data) {
          setProfile(data.data)
          setStorageData("userName", data.data.attributes?.first_name);
          setShowLoader(true)
          setStorageData("userName", data.data.attributes?.first_name);
        setStorageData("userInfo", JSON.stringify(data.data))}}).catch( ()=> {setProfile({ attributes: { first_name: '', photo: null } })
        setShowLoader(false)
      });
  }

  useEffect(() => {
    fetchSecretData()
  }, []);
  const handleMenuClick = (event: any) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleInvestMenuClick = (event: any) => {
    setAnchorInvestMenu(event.currentTarget);
    setAnchorMenu(null)
  };
  const navigationPage = (path: string) => {
      customNavigation(path)

  }
  const onImgClick = () => {
    if (window.location.pathname !== "/") {
      customNavigation('HomeScreenWeb')
    }
  }

  const customNavigation = (navigationPath: string) => {
    const goToNavigationBlock: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToNavigationBlock.addData(getName(MessageEnum.NavigationTargetMessage), navigationPath);
    goToNavigationBlock.addData(getName(MessageEnum.NavigationPropsMessage), props);
    const uuidv4 = require("uuid/v4");
    const blockId = uuidv4();
    runEngine.sendMessage(blockId, goToNavigationBlock);
  }

  return (
    <Paper elevation={0} square className={classes.paper}>
      <div className={classes.root}>
        <div style={{display:'flex',alignItems:'center'}}>
          <div className={classes.menuBar}>
            <IconButton
              id="basic-button"
              className={classes.burgerBtn}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <MenuRoundedIcon />
            </IconButton>
          </div>
          {showLoader && <img src={headerLogo} alt="Poster" onClick={onImgClick} className={classes.logo} />}
        </div>
        <div className={classes.menuBar}>
          <Menu
            id="basic-menu"
            anchorEl={anchorMenu}
            open={open}
            onClose={() => setAnchorMenu(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem id="home" onClick={(e) => {
              customNavigation('HomeScreenWeb')
              setAnchorMenu(null)
            }}>Home</MenuItem>
            <MenuItem id="Invest" onClick={handleInvestMenuClick}>Invest</MenuItem>
            <MenuItem id="About" onClick={(e) => {
              customNavigation('ContentManagement')
              setAnchorMenu(null)
            }}>About</MenuItem>
            
            {localStorage.getItem('token') != null && <MenuItem id="Portfolio" onClick={(e) => {
              setAnchorMenu(null)
              customNavigation('PortfolioManagementWeb')
            }}>Portfolio</MenuItem>}
            <MenuItem id="Secondaries" onClick={(e) => {
              setAnchorMenu(null)
              customNavigation('SecondariesInvestmentWeb')
            }}>Secondaries</MenuItem>
             {localStorage.getItem('token') !== null && <MenuItem id="Account" onClick={(e) => {
              setAnchorMenu(null)
              customNavigation('Settings')
            }}>Account</MenuItem>}
          </Menu>
        </div>
        <div className={classes.forMenues} id="menuLinks">
          <span
            onClick={(e) => {
              if (window.location.pathname !== "/") {
                customNavigation('HomeScreenWeb')
              }
            }}
            className={`${classes.forMenuItems} ${(window.location.pathname === '/LandingPageScreen' || window.location.pathname === '/') ? classes.activeMenuItem : undefined}`}
            id="home"
          >
            Home
          </span>        
          <span
            className={`${classes.forMenuItems} ${(window.location.pathname === '/StartupInvestmentWeb' || window.location.pathname === '/FundInvestmentWeb' || window.location.pathname === '/InvestmentProcessWeb' ) ? classes.activeMenuItem : undefined}`}
            id="Invest"
            onClick={handleInvestMenuClick}
          >
            Invest
          </span>
          <Menu
            id="basic-menu"
            anchorEl={anchorInvestMenu}
            open={openInvest}
            onClose={() => setAnchorInvestMenu(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              id="Startup Investment"
              onClick={() => {
                navigationPage("StartupInvestmentWeb")
              }}>
              Startup Investment
            </MenuItem>
            <MenuItem
              id="Fund Investment"
              onClick={() => {
                navigationPage("FundInvestmentWeb")
              }}>
              Fund Investment
            </MenuItem>
            <MenuItem
              id="Secondaries"
              onClick={() => {
                customNavigation('SecondariesInvestmentWeb')
              }}>
              Secondaries
            </MenuItem>
            <MenuItem
              id="Investment Process"
              onClick={() => {
                navigationPage("InvestmentProcessWeb")
              }}>
              Investment Process
            </MenuItem>
          </Menu>
               <span
            className={`${classes.forMenuItems} ${window.location.pathname === '/ContentManagement' || window.location.pathname === '/AboutUs'? classes.activeMenuItem : undefined}`}
            id="About"
            onClick={() => {
              customNavigation('ContentManagement')
            }}
          >
            About
          </span>
         
          {localStorage.getItem('token') != null && <span
            className={`${classes.forMenuItems} ${window.location.pathname === '/PortfolioManagementWeb' ? classes.activeMenuItem : undefined}`}
            id="Portfolio"
            onClick={() => {
              customNavigation('PortfolioManagementWeb')
            }}
          >
            Portfolio
          </span>}
          <span
            className={`${classes.forMenuItems} ${window.location.pathname === '/SecondariesInvestmentWeb' || window.location.pathname === '/EducationCenterIntermediateWeb' || window.location.pathname === '/EducationCenterWeb' ? classes.activeMenuItem : undefined}`}
            id="Secondaries"
            onClick={() => {
              customNavigation('SecondariesInvestmentWeb')
            }}
          >
            Secondaries
          </span>
          {localStorage.getItem('token') !== null && 
          <span
            className={`${classes.forMenuItems} ${window.location.pathname === '/Settings' ? classes.activeMenuItem : undefined}`}
            id="Account"
            onClick={() => navigationPage("Settings")}
          >
            Account
          </span>
          }
        </div>
        <div className={classes.helloBox}>
          {localStorage.getItem('token') !== null && <Notifications color='#ffffff' id='' navigation={props.navigation} />}
          {localStorage.getItem('token') == null && <button type="button" className={classes.signUpButton} onClick={(e: any) => {
            customNavigation('EmailAccountRegistrationWeb')
          }}
          >Get Started</button>}
          {localStorage.getItem('token') != null &&
            <div style={{ display: 'flex', gap: '15px',alignItems:'center' }}>
              { showLoader ? <img src={profile && profile?.attributes?.photo === null ? profilePlaceholder : profile?.attributes?.photo} className={classes.profileImage} /> : <img src={profilePlaceholder} style={{ width: '48px', height: '48px', borderRadius: '50%',visibility:'hidden' }} />}
              <div>
                <Typography className={classes.hello} style={{  }}>Hello,</Typography>
                <Typography className={classes.name}>{profile?.attributes?.first_name}</Typography>
              </div>
            </div>
          }
        </div>
      </div>
    </Paper>
  )
}
