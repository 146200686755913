import React from "react";

// Customizable Area Start

import {
  imgBlueCheck,
  imgBlueUncheck,
  imgDarkUncheck,
  imgFailed,
  imgFirm,
  imgIndividual,
  imgInfo,
  imgLogo,
  imgOffice,
  imgProgress,
  imgShape,
  imgSuccess,
  imgTrust,
} from "./assets";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
 
import {
  Paper,
  Grid,
  StyleRules,
  withStyles,
  Box,
  Typography,
  Button,
  Radio,
} from "@material-ui/core";
const config = require("../../landingpage/src/config");
import TextField from "@material-ui/core/TextField";
import OtpInput from 'react-otp-input';

// Customizable Area End

import InvestorProfileWebController, {
    Props,
} from "./InvestorProfileController.web";

const styles: StyleRules = {
  // Customizable Area Start
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  centerMainBox: {
    display: "flex",
    flexDirection: "column",
  },
  textStyle: {
    fontSize: "16px",
    margin: "16px 0px",
    color: "#6F6F70",
    textAlign: 'initial'
  },
  headerText: {
    fontWeight: 800,
    fontSize: "32px",
    margin: "16px 0px",
    color: "#0069BC",
    fontFamily: "Lato",
  },
  nextBtn: {
    backgroundColor: "#0069BC",
    textTransform: "none",
    padding: "10px 16px",
    height: "56px",
    width: "153px",
    color: "#FFFFFF",
    borderRadius: "12px",
    alignSelf: "end",
    fontWeight: 700,
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "#0069BC",
    },
  },
  btn: {
    textTransform: "none",
    padding: "16px",
    border: "1px solid #BDBDBD",
    height: "60px",
    maxWidth: "505px",
    width: "100%",
    color: "#868686",
    margin: "16px 0px",
    borderRadius: "12px",
    float: "inline-end",
    fontWeight: 700,
    fontSize: "20px",
  },
  option: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#222222",
  },
  optionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #D9D9D9",
    height: "80px",
    borderRadius: "12px",
    float: "inline-start",
    margin: "12px 0px",
    padding: "0px 16px",
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#0069BC",
    height: "48px",
    width: "48px",
    borderRadius: "8px",
  },
  startBtn: {
    border: "1px solid #0069BC",
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    padding: "10px 16px",
    height: "56px",
    width: "153px",
    color: "#0069BC",
    borderRadius: "12px",
    alignSelf: "start",
    fontWeight: 700,
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    '@media (max-width: 600px)': {
        width: "auto",
    }
  },
  startAndNextButtonBox: {
    justifyContent: "space-between",
    padding: "30px 24px",
    gap: '8px',
    '@media (max-width: 600px)': {
        flexWrap: 'wrap',
        justifyContent: "center",
    }
  },
  dialogContainer: {
    // minWidth: "570px",
    minHeight: "298px",
    borderRadius: "16px",
  },
  leftHeaderTxt: {
    fontSize: "48px",
    maxWidth: "560px",
    width: "100%",
    fontWeight: 800,
    color: "#F2F2F2",
    fontFamily: "Lato",
  },
  errorText: {
    color: "#DC2626",
    fontSize: "14px",
    fontWeight: 400,
    margin: "0px",
    width: "100%",
  },
  fnameLogo: {
    width: "100%",
  },
  desriptionMainText: {
    fontSize: "18px",
    maxWidth: "560px",
    color: "#F2F2F2",
    width: "100%",
  },
  titleMainBox: {
    textAlign: "justify",
  },
  buttonMainBox: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0px",
  },
  leftSieMainContainer: {
    display: "flex",
    position: "absolute",
    top: "15%",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "0 20px",
    gap: "50px",
  },
  autoComplateStyle: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      height: "48px",
      width: "auto",
    },
  },
    rightText: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: '16px',
        width: '60%',
        wordBreak: 'break-all',
        color:'#6F6F70'
    },

    rightContent: {
        display: 'inline-flex',
        alignItems: 'first baseline',
        flexDirection: 'row',
    },

    leftContent: {
        width: '40%',
        color: '#222222',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        fontFamily: 'Roboto'
    },

    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },

    otpText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        width: '100%',
        wordBreak: 'break-all',
        color: '#828282'
    },

    inputStyle: {
        width: '64px !important',
        height: '80px',
        fontSize: '18px',
        textAlign: 'center',
        border: '1px solid #BDBDBD',
        borderRadius: '8px',
        margin: '0 5px',
        '@media (max-width: 1280px)': {
            margin: '0px',
        },
        '@media (max-width: 600px)': {
            width: '36px !important',
            height: '40px'
        }
    },

    resendOtp: {
        display: 'block',
        margin: 'auto',
        position: 'relative'
    },

    customOutlinedInput: {
        '& .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px'
        }
    },
    leftSideContainer: {
        background: "linear-gradient(180deg, #0086F0 0%, #0069BC 100%)",
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
    },
    rightMainContainer: {
        display: "flex",
        justifyContent: "center",
        padding: "50px 85px",
        maxWidth: "670px",
        margin: 'auto',
        '@media (max-width: 600px)': {
            padding: "30px",
        }
    },
    mainContainer: {
        flexWrap: "nowrap",
        '@media (max-width: 959px)': {
            flexWrap: "wrap",
        }
    }
  // Customizable Area End
};

export class InvestorProfile extends InvestorProfileWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    adhaarVerificationDetails = () => {
        const { classes } = this.props;
        const {
            first_name,
            last_name,
            address,
            phone_number,
            identification_number,
            date_of_birth,
            validateAadharStatus
        } = this.state;
        return (
            <>
                <Box className={classes.centerMainBox} style={{ width: '100%', gap: '40px' }}>
                    <Typography className={classes.headerText}>Aadhaar Verification</Typography>
                    <Box style={{ display: 'block', margin: 'auto' }}>
                        <img src={imgSuccess} />
                    </Box>
                    <Box className={classes.detailsContainer}>
                        <Box className={classes.rightContent}>
                            <Typography className={classes.leftContent}>Full name </Typography>
                            <span className={classes.rightText} style={{ textTransform: 'capitalize' }}>{first_name + ' ' + last_name}</span>
                        </Box>

                        <Box className={classes.rightContent}>
                            <Typography className={classes.leftContent}>Aadhaar number </Typography>
                            <span className={classes.rightText}>{this.convertToHiddenNumber(identification_number,'*')}</span>
                        </Box>

                        <Box className={classes.rightContent}>
                            <Typography className={classes.leftContent}>Date of birth </Typography>
                            <span className={classes.rightText}>{date_of_birth}</span>
                        </Box>

                        <Box className={classes.rightContent}>
                            <Typography className={classes.leftContent}>Phone number </Typography>
                            <span className={classes.rightText}>{this.convertToHiddenNumber(phone_number,'*')}</span>
                        </Box>

                        <Box className={classes.rightContent}>
                            <Typography className={classes.leftContent}>Address </Typography>
                            <span className={classes.rightText} style={{ textTransform: 'capitalize' }}>{address}</span>
                        </Box>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button className={`${classes.nextBtn}`} disabled={validateAadharStatus} onClick={this.sendOTP} style={{ width: "182px" }} data-test-id="sendOtpId">
                            Verify Aadhaar
                        </Button>
                    </Box>
                </Box>
            </>
        )
    }

    adhaarVerificationOTP = () => {
        const { classes } = this.props;
        const {
            identification_number,
            phone_number,
            otp,
            seconds,
            timerRunning,
            loading
        } = this.state;

        return (
            <>
                {loading && (
                    <Box style={{ height: "80vh" }}>
                        <Box
                            style={{ marginBottom: "50px", textAlign: "justify", }}
                        >
                            <Typography className={classes.headerText}>
                                Verification Status
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                gap: "24px",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <img src={imgProgress} />
                            <Typography
                                style={{
                                    fontWeight: 800,
                                    fontSize: "32px",
                                    fontFamily: "Lato",
                                    color: "#222222",
                                }}
                            >
                                In-Progress
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: "16px",
                                    color: "#6F6F70",
                                    fontFamily: "Roboto",
                                    textAlign: "center",
                                }}
                            >
                                We are working on verifying your profile. This
                                usually happens within 24 hours and you’ll receive
                                an email once verified.
                            </Typography>
                        </Box>
                    </Box>
                )}
                {!loading &&
                <Box className={classes.centerMainBox} style={{ width: '100%' }}>
                    <Typography className={classes.headerText}>Aadhaar Verification</Typography>
                    <Typography className={classes.otpText}>Enter the OTP sent to {this.convertToHiddenNumber(phone_number, 'X')} linked to your aadhaar number {this.convertToHiddenNumber(identification_number, 'X')}</Typography>
                    <Box style={{ display: 'flex', justifyContent: 'start' }}>
                        <OtpInput
                            data-test-id="ChangeOtp"
                            value={otp}
                            onChange={this.handleChangeOtp}
                            numInputs={6}
                            renderSeparator={<span> &nbsp;&nbsp; </span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={classes.inputStyle}
                            containerStyle={{ margin: '40px -6px' }}
                        />
                    </Box>
                    <Box className={classes.resendOtp} style={{left: seconds != 0 ? '-25px' : 'unset',}}>
                        <Typography className={classes.otpText} style={{ color: '#222222' }}>
                            <span onClick={this.resendOTP} data-test-id="resendOtpId" style={{ textDecoration: 'underline', cursor: timerRunning ? 'not-allowed' : 'pointer', opacity: timerRunning ? '0.7' : 'unset' }}>Resend OTP</span>
                            {seconds != 0 && <> in <span style={{ color: '#0069BC', fontWeight: 700 }}>{seconds} sec</span></>}
                             </Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '40px' }}>
                        <Button className={`${classes.nextBtn} ${classes.mobileBtn}`} onClick={this.confirmOTP} style={{ width: "153px" }} data-test-id="confirmOtpId">
                            Verify
                        </Button>
                    </Box>
                </Box>
                }
            </>
        )
    }

    showUnableToVerifyScreen = () => {
        const { classes } = this.props;
        return (<Box className={classes.centerMainBox}>
            <Box style={{ height: "auto" }}>
                <Box
                    style={{
                        marginBottom: "50px",
                        textAlign: "justify",
                    }}
                >
                    <Typography className={classes.headerText}>
                        Verification Status
                    </Typography>
                </Box>
                <Box
                    style={{
                        alignItems: "center",
                        flexDirection: "column",
                        display: "flex",
                        gap: "24px",
                    }}
                >
                    <img src={imgFailed} />
                    <Typography
                        style={{
                            fontWeight: 800,
                            color: "#222222",
                            fontFamily: "Lato",
                            fontSize: "32px",
                        }}
                    >
                        Unable to verify
                    </Typography>
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#6F6F70",
                        }}
                    >
                        We were unable yo verify the identity to you or
                        your business. We will automatically review to
                        ensure this wasn’t an error. If you think this
                        is a mistake, please open a support ticket.
                    </Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0' }}>
                    <Button className={`${classes.nextBtn} ${classes.mobileBtn}`} onClick={this.redirectToContact} style={{ width: "253px" }} data-test-id="supportTicket">
                        Open Support Ticket
                    </Button>
                </Box>
            </Box>
        </Box>)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
        loading,
        experience_alternative_investment,
        investorType,
        capital_actively_invested,
        investorTypeSelect,
        page,
        alternative_investment_strategy,
        is_family_run,
        open,
        percentage_to_allocate,
        address,
        first_name,
        last_name,
        phone_number,
        email,
        identification_type,
        kycFailed,
        identification_number,
        kycVerified,
        selectedCheckbox,
        firstName,
        contactEmail,
        lastName,
        confirmSelection,
        date_of_birth,
        city,
        state,
        postal_code,
        country,
        issue_date,
        expiration_date,
        unableToVerify
        } = this.state;

        return (
            <Paper>
                <Grid container className={classes.mainContainer}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={6}
                        className={classes.leftSideContainer}
                    >
                        <Box>
                            <img className={classes.fnameLogo} src={imgShape} alt="poster" />
                        </Box>
                        <Box className={classes.leftSieMainContainer}>
                            <img src={imgLogo} alt="poster" style={{
                                maxHeight: '64px',
                                maxWidth: '176px',
                                height: 'auto',
                                width: 'auto',
                            }} />
                            <Typography className={classes.leftHeaderTxt}>
                                {page === 1 && !unableToVerify && "Unlock New Horizons of Alternative Investing"}
                                {unableToVerify &&  "Multiply your investment potential"}
                                {investorType === "As an individual" &&
                                    (page === 3 || page === 2 || page === 4) &&
                                    "Unlock New Horizons of Alternative Investing"}
                                {investorType === "As an individual" &&
                                    page === 5 &&
                                    "Seamless Investment Journey"}
                                {investorType === "As an individual" &&
                                    (page === 7 || page === 6 || page === 8 || page === 9) &&
                                    "Multiply your investment potential"}

                                {(investorType === "As a firm or fund" || investorType === "as a family office" || investorType === "Through a trust") && page === 2 && "One platform, multiple opportunities"}
                                {(investorType === "As a firm or fund" ||  investorType === "as a family office" ||   investorType === "Through a trust") &&
                                    (page === 4 || page === 3 || page === 5 || page === 6) &&
                                    "Unlock New Horizons of Alternative Investing"}
                                {(investorType === "As a firm or fund" || investorType === "as a family office" ||  investorType === "Through a trust") &&    page === 7 &&
                                    "Monitor And Optimise Your Portfolio"}
                                {(investorType === "As a firm or fund" ||  investorType === "as a family office" ||  investorType === "Through a trust") && ( page === 8 || page === 9 || page === 10) && 
                                    "Multiply your investment potential"}
                            </Typography>
                            <Typography className={classes.desriptionMainText}>
                                {page === 1 &&  "Empower your investment portfolio with limitless choices. Select from tier-1 funds, dynamic startups, and beyond."}

                                {investorType === "As an individual" &&  (page === 2 || page === 3 || page === 4) &&
                                    "Empower your investment portfolio with limitless choices. Select from tier-1 funds, dynamic startups, and beyond."}
                                {investorType === "As an individual" &&     page === 5 &&
                                    "Select, Allocate, Authenticate, and Transfer."}
                                {investorType === "As an individual" && (page === 6 || page === 7 || page === 8 || page === 9) &&
                                    "Experience instant diversification and tap into the track record of top-tier managers at your fingertips."}

                                {(investorType === "As a firm or fund" ||   investorType === "As a family office" ||
                                    investorType === "Through a trust") &&   page === 2 &&
                                    "Invest in various asset classes through our user-friendly mobile and web application."}
                                {(investorType === "As a firm or fund" ||
                                    investorType === "As a family office" ||
                                    investorType === "Through a trust") &&
                                    (page === 6 || page === 5 || page === 4 || page === 3) &&
                                    "Empower your investment portfolio with limitless choices. Select from tier-1 funds, dynamic startups, and beyond."}
                                {(investorType === "As a firm or fund" ||  investorType === "As a family office" ||
                                    investorType === "Through a trust") && page === 7 &&                                  "Centralise your diverse alternative investments"}
                                {(investorType === "As a firm or fund" ||  investorType === "As a family office" ||
                                    investorType === "Through a trust") &&
                                    (page === 8 || page === 10 || page === 9 )&&
                                    "Experience instant diversification and tap into the track record of top-tier managers at your fingertips."}
                            </Typography>
                        </Box>
                    </Grid> 
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={6}
                        className={classes.rightMainContainer}
                    >
                        <Box className={classes.rightContainer}>
                            {!unableToVerify && investorTypeSelect && (
                                <Box className={classes.centerMainBox}>
                                    <Box className={classes.titleMainBox}>
                                        <Typography className={classes.headerText}>
                                            Investor Profile
                                        </Typography>
                                        <Typography
                                            className={classes.textStyle}
                                        >
                                            Select the investor type that corresponds to the legal entity you’ll be investing through
                                        </Typography>
                                    </Box>
                                    <Box className={classes.optionBox}>
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "16px",
                                            }}
                                        >
                                            <Box className={classes.imgBox}>
                                                <img src={imgIndividual} alt="imgIndividual" />
                                            </Box>
                                            <Typography className={classes.option}>
                                                As an individual
                                            </Typography>
                                        </Box>
                                        <Radio
                                            checked={investorType === "As an individual"}
                                            onChange={this.handleChange}
                                            value="As an individual"
                                            data-test-id="Radio"
                                            name="radio-buttons"
                                            style={{
                                                color:
                                                    investorType === "As an individual" ? "#0069BC" : "#6F6F70",
                                            }}
                                        />
                                    </Box>
                                    <Box className={classes.optionBox}>
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "16px",
                                            }}
                                        >
                                            <Box className={classes.imgBox}>
                                                <img src={imgFirm} alt="imgFirm" />
                                            </Box>
                                            <Typography className={classes.option}>
                                                As a firm or fund
                                            </Typography>
                                        </Box>
                                        <Radio
                                            checked={investorType === "As a firm or fund"}
                                            onChange={this.handleChange}
                                            value="As a firm or fund"
                                            name="radio-buttons"
                                            style={{
                                                color:
                                                    investorType === "As a firm or fund"
                                                        ? "#0069BC"
                                                        : "#6F6F70",
                                            }}
                                        />
                                    </Box>
                                    <Box className={classes.optionBox}>
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "16px",
                                            }}
                                        >
                                            <Box className={classes.imgBox}>
                                                <img src={imgOffice} alt="imgOffice" />
                                            </Box>
                                            <Typography className={classes.option}>
                                                As a family office
                                            </Typography>
                                        </Box>
                                        <Radio
                                            checked={investorType === "As a family office"}
                                            onChange={this.handleChange}
                                            value="As a family office"
                                            name="radio-buttons"
                                            style={{
                                                color:
                                                    investorType === "As a family office"
                                                        ? "#0069BC"
                                                        : "#6F6F70",
                                            }}
                                        />
                                    </Box>
                                    <Box className={classes.optionBox}>
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "16px",
                                            }}
                                        >
                                            <Box className={classes.imgBox}>
                                                <img src={imgTrust} alt="imgTrust" />
                                            </Box>
                                            <Typography className={classes.option}>
                                                Through a trust
                                            </Typography>
                                        </Box>
                                        <Radio
                                            checked={investorType === "Through a trust"}
                                            onChange={this.handleChange}
                                            value="Through a trust"
                                            name="radio-buttons"
                                            style={{
                                                color: investorType === "Through a trust" ? "#0069BC" : "#6F6F70",
                                            }}
                                        />
                                    </Box>
                                    <Box className={classes.buttonMainBox}>
                                        <div>
                                            <Typography
                                                style={{
                                                    color: "#0069BC",
                                                    fontSize: "20px",
                                                    fontWeight: 500,
                                                }}
                                                onClick={this.handleClickOpen}
                                            >
                                                Skip for now
                                            </Typography>
                                            <Dialog
                                                open={open}
                                                onClose={this.handleClose}
                                                className={classes.dialogContainer}
                                                PaperProps={{
                                                    style: {
                                                        borderRadius: 16,
                                                    },
                                                }}
                                            >
                                                <DialogTitle style={{ padding: "16px 24px 0px" }}>
                                                    <Typography
                                                        style={{
                                                            color: "#222222",
                                                            fontSize: "32px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {"Are you sure you want to skip?"}
                                                    </Typography>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Typography
                                                        style={{
                                                            color: "#6F6F70",
                                                            fontSize: "16px"
                                                        }}
                                                    >
                                                        If you choose to skip the completion of the investor
                                                        questionnaire and the mandatory KYC, you will not be
                                                        able to participate in any investment opportunities
                                                        or access further details related to them.
                                                    </Typography>
                                                </DialogContent>
                                                <DialogActions
                                                    className={classes.startAndNextButtonBox}
                                                >
                                                    <Button
                                                        className={classes.startBtn}
                                                        onClick={this.navigateOnLandingPage}
                                                        style={{ width: "241px" }}
                                                    >
                                                        Skip Anyway
                                                    </Button>
                                                    <Button
                                                        className={classes.nextBtn}
                                                        onClick={this.handleClose}
                                                        style={{ width: "241px", margin: '0px' }}
                                                    >
                                                        Don't Skip
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                        <Button
                                            className={classes.nextBtn}
                                            onClick={this.nextPage}
                                            disabled={investorType === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {unableToVerify && this.showUnableToVerifyScreen()}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                page === 2 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography
                                                className={classes.textStyle}
                                            >
                                                What experience do you or your entity have with alternative investments as an asset class?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    New to Alternative Investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                checked={
                                                    experience_alternative_investment ===
                                                    "New to Alternative Investment"
                                                }
                                                onChange={this.handleAlternativeInvestmentChange}
                                                value="New to Alternative Investment"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        experience_alternative_investment ===
                                                            "New to Alternative Investment"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Moderate experience in alternative investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                checked={
                                                    experience_alternative_investment ===
                                                    "Moderate experience in alternative investments"
                                                }
                                                onChange={this.handleAlternativeInvestmentChange}
                                                value="Moderate experience in alternative investments"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        experience_alternative_investment ===
                                                            "Moderate experience in alternative investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Extensive experience in alternative investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                checked={
                                                    experience_alternative_investment ===
                                                    "Extensive experience in alternative investments"
                                                }
                                                onChange={this.handleAlternativeInvestmentChange}
                                                value="Extensive experience in alternative investments"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        experience_alternative_investment ===
                                                            "Extensive experience in alternative investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            className={classes.nextBtn}
                                            onClick={this.nextPage}
                                            disabled={experience_alternative_investment === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                page === 3 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                What’s driving your alternative investment strategy?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Secure access to alternative investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                checked={
                                                    alternative_investment_strategy ===
                                                    "Secure access to alternative investments"
                                                }
                                                onChange={this.handleInvestmentStrategyChange}
                                                value="Secure access to alternative investments"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        alternative_investment_strategy ===
                                                            "Secure access to alternative investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Discover options with higher than usual returns
                                                </Typography>
                                            </Box>
                                            <Radio
                                                checked={
                                                    alternative_investment_strategy ===
                                                    "Discover options with higher than usual returns"
                                                }
                                                onChange={this.handleInvestmentStrategyChange}
                                                value="Discover options with higher than usual returns"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        alternative_investment_strategy ===
                                                            "Discover options with higher than usual returns"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Expand the diversity of your investment portfolio
                                                </Typography>
                                            </Box>
                                            <Radio
                                                checked={
                                                    alternative_investment_strategy ===
                                                    "Expand the diversity of your investment portfolio"
                                                }
                                                onChange={this.handleInvestmentStrategyChange}
                                                value="Expand the diversity of your investment portfolio"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        alternative_investment_strategy ===
                                                            "Expand the diversity of your investment portfolio"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            className={classes.nextBtn}
                                            onClick={this.nextPage}
                                            disabled={alternative_investment_strategy === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                page === 4 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                What is the extent of capital actively invested by you or
                                                your entity?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                Less than $1M
                                            </Typography>
                                            <Radio
                                                checked={capital_actively_invested === "Less than $1M"}
                                                onChange={this.handleCapitalInvestedChange}
                                                value="Less than $1M"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "Less than $1M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                $1M - $50M
                                            </Typography>
                                            <Radio
                                                checked={capital_actively_invested === "$1M - $50M"}
                                                onChange={this.handleCapitalInvestedChange}
                                                value="$1M - $50M"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "$1M - $50M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                $50M - $100M
                                            </Typography>
                                            <Radio
                                                checked={capital_actively_invested === "$50M - $100M"}
                                                onChange={this.handleCapitalInvestedChange}
                                                value="$50M - $100M"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "$50M - $100M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                $100M - $500M
                                            </Typography>
                                            <Radio
                                                checked={capital_actively_invested === "$100M - $500M"}
                                                onChange={this.handleCapitalInvestedChange}
                                                value="$100M - $500M"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "$100M - $500M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                Over $500M
                                            </Typography>
                                            <Radio
                                                checked={capital_actively_invested === "Over $500M"}
                                                onChange={this.handleCapitalInvestedChange}
                                                value="Over $500M"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "Over $500M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                Over $1B
                                            </Typography>
                                            <Radio
                                                checked={capital_actively_invested === "Over $1B<"}
                                                onChange={this.handleCapitalInvestedChange}
                                                value="Over $1B<"
                                                name="radio-buttons"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "Over $1B<"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            className={classes.nextBtn}
                                            onClick={this.nextPage}
                                            disabled={capital_actively_invested === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                page === 5 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Identity Accreditation
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                Tell us how your organization is accredited:
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: "flex",
                                                padding: "16px",
                                                borderRadius: "12px",
                                                gap: "16px",
                                                background: "#0069BC1A",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            <img src={imgInfo} />
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    fontSize: "16px",
                                                    color: "#6F6F70",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                    lineHeight:"24px",
                                                    fontFamily: '"Inter", sans-serif',
                                                    fontOpticalSizing: 'auto',
                                                    fontWeight: 400,
                                                    fontStyle: 'normal',
                                                    fontVariationSettings: '"slnt" 0'
                                                }}
                                            >
                                                <li>
                                                    Only one qualification is required, but you should
                                                    select the highest level that you or your entity
                                                    qualify for.
                                                </li>
                                                <li>
                                                    The SEC requires that all U.S. investors in Future for
                                                    Alts funds must be accredited.{" "}
                                                </li>
                                            </Box>
                                        </Box>
                                        <FormControlLabel
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 0
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(0)}
                                                    style={{
                                                        marginRight: "8px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            style={{ margin: "16px 0px 0px" }}
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px",fontWeight:600 }}>
                                                        Qualified Purchaser - $5M or more in investments
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 1
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(1)}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontWeight:600,fontSize: "16px" }}>
                                                        Accredited Investor - Income is greater than
                                                        $200,000 in each of the prior 2 years
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 2
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(2)}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight:600 }}>
                                                        Accredited Investor - Income is greater than
                                                        $300,000 with spouse or partner in each of the prior
                                                        2 years
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 3
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(3)}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight:600 }}>
                                                        Accredited Investor - Net worth of $1M or more in
                                                        assets, excluding primary residence
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 4
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(4)}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontWeight:600, fontSize: "16px" }}>
                                                        Accredited Investor - Hold a series 7, 65, or 82
                                                        license that is currently in good standing
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 5
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(5)}
                                                    style={{
                                                        marginRight: "8px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight:600 }}>
                                                        I am not an accredited investor
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px" }}
                                            control={
                                                <img
                                                    src={confirmSelection ? imgBlueCheck : imgDarkUncheck}
                                                    alt="checkbox"
                                                    onClick={() => this.handleConfirmSelection()}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Roboto",
                                                            lineHeight: "24px",
                                                            fontWeight: 900,
                                                        }}
                                                    >
                                                        I confirm that my selection is accurate and that I
                                                        am an accredited investor
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormHelperText
                                            className={classes.errorText}
                                            style={{ marginLeft: "28px" }}
                                        >
                                            {this.state.confirmation_error}
                                        </FormHelperText>
                                        <Box
                                            style={{
                                                margin: "40px 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                maxWidth: "500px",
                                                cursor: "pointer",
                                                alignItems: "center",
                                                gap: '10px'
                                            }}
                                        >
                                            <div>
                                                <Button
                                                    className={classes.startBtn}
                                                    onClick={this.handleClickOpen}
                                                >
                                                    Start Over
                                                </Button>
                                                <Dialog
                                                    onClose={this.handleClose}
                                                    open={open}
                                                    className={classes.dialogContainer}
                                                    PaperProps={{
                                                        style: {
                                                            borderRadius: 16,
                                                        },
                                                    }}
                                                >
                                                    <DialogTitle style={{ padding: "16px 24px 0px" }}>
                                                        <Typography
                                                            style={{
                                                                padding: "0px 16px",
                                                                color: "#222222",
                                                                fontSize: "32px",
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {"Start Over"}
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Typography
                                                            style={{
                                                                color: "#6F6F70",
                                                                margin: "16px",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            By continuing you will delete the investor profile
                                                            that is in progress. This action cannot be undone.
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                color: "#222222",
                                                                margin: "16px",
                                                                fontSize: "16px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Would you like to delete this investor profile and
                                                            start a new one?
                                                        </Typography>
                                                    </DialogContent>
                                                    <DialogActions
                                                        style={{
                                                            justifyContent: "space-around",
                                                            padding: "0px 40px 30px",
                                                        }}
                                                    >
                                                        <Button
                                                            className={classes.startBtn}
                                                            style={{ width: "241px" }}
                                                            onClick={this.handleClose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            className={classes.nextBtn}
                                                            style={{ width: "241px" }}
                                                            onClick={this.handleStartOver}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                            <Button
                                                data-test-id="next-page"
                                                disabled={selectedCheckbox === null}
                                                className={classes.nextBtn}
                                                onClick={this.goOnNextPage}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                selectedCheckbox === 5 &&
                                page === 6 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box>
                                            <Typography
                                                style={{
                                                    fontWeight: 800,
                                                    fontSize: "31px",
                                                    color: "#222222",
                                                    fontFamily: "Lato",
                                                    maxWidth: "515px",
                                                    width: "100%",
                                                }}
                                            >
                                                Unfortunately, we currently cannot serve you as an
                                                investor.
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                padding: "16px",
                                                gap: "16px",
                                                margin: "16px 0px",
                                                borderRadius: "12px",
                                                background: "rgb(233 200 200 / 54%)",
                                                display: "flex",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            <img src={imgInfo} />
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                    fontSize: "16px",
                                                    color: "#6F6F70",
                                                }}
                                            >
                                                <li>
                                                    The SEC requires that all U.S. investors in our fund
                                                    must be accredited. We apologize for the
                                                    inconvenience.
                                                </li>
                                            </Box>
                                        </Box>
                                        <Box style={{ margin: "16px 0px" }}>
                                            <Typography
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "24px",
                                                    color: "#222222",
                                                    fontFamily: "Lato",
                                                }}
                                            >
                                                Stay in touch.
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: "16px",
                                                    color: "#6F6F70",
                                                    fontFamily: "Roboto",
                                                }}
                                            >
                                                Sign up to receive our expert insights and company
                                                updates right in your inbox.
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: "10px"
                                            }}
                                        >
                                            <FormControl
                                                variant="outlined"
                                                style={{ margin: "16px 0px" }}
                                            >
                                                <FormHelperText
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#828282",
                                                        margin: "0 0 8px",
                                                        fontFamily: "Roboto",
                                                    }}
                                                >
                                                    First name
                                                </FormHelperText>
                                                <OutlinedInput
                                                    style={{
                                                        maxWidth: "240px",
                                                        width: "100%",
                                                        height: "48px",
                                                        borderRadius: "8px",
                                                    }}
                                                    value={firstName}
                                                    onChange={this.handleFNameChange}
                                                    onBlur={this.validatefirstName}
                                                />
                                                {this.state.firstName_error && (
                                                    <FormHelperText className={classes.errorText}>
                                                        {this.state.firstName_error}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                            <FormControl
                                                variant="outlined"
                                                style={{ margin: "16px 0px" }}
                                            >
                                                <FormHelperText
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#828282",
                                                        margin: "0 0 8px",
                                                        fontFamily: "Roboto",
                                                    }}
                                                >
                                                    Last name
                                                </FormHelperText>
                                                <OutlinedInput
                                                    style={{
                                                        maxWidth: "240px",
                                                        width: "100%",
                                                        height: "48px",
                                                        borderRadius: "8px",
                                                    }}
                                                    value={lastName}
                                                    onBlur={this.validatelastName}
                                                    onChange={this.handleLNameChange}
                                                />
                                                {this.state.lastName_error && (
                                                    <FormHelperText className={classes.errorText}>
                                                        {this.state.lastName_error}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Box>
                                        <FormControl
                                            variant="outlined"
                                            style={{ margin: "16px 0px" }}
                                        >
                                            <FormHelperText
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#828282",
                                                    margin: "0 0 8px",
                                                    fontFamily: "Roboto",
                                                }}
                                            >
                                                Email address
                                            </FormHelperText>
                                            <OutlinedInput
                                                style={{
                                                    maxWidth: "505px",
                                                    width: "100%",
                                                    height: "48px",
                                                    borderRadius: "8px",
                                                }}
                                                value={contactEmail}
                                                onBlur={this.validateEmailAddress}
                                                onChange={this.handleContactEmailChange}
                                            />
                                            {this.state.contactEmail_error && (
                                                <FormHelperText className={classes.errorText}>
                                                    {this.state.contactEmail_error}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                margin: "24px 0px",
                                                flexWrap: 'wrap',
                                                gap: '20px'
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "#0069BC",
                                                    fontFamily: "Roboto",
                                                }}
                                                onClick={this.handleUpdateAccrediation}
                                            >
                                                Update accreditation method
                                            </Typography>
                                            <Button
                                                className={classes.nextBtn}
                                                onClick={this.handleStayInTouch}
                                                disabled={experience_alternative_investment === ""}
                                                data-test-id="stayInTouch"
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                ((selectedCheckbox !== 5 && page == 6) || page === 7) && (
                                    <Box className={classes.centerMainBox}>
                                        {loading ? (
                                            <Box style={{ height: "80vh" }}>
                                                <Box
                                                    style={{ marginBottom: "50px", textAlign: "justify", }}
                                                >
                                                    <Typography className={classes.headerText}>
                                                        Verification Status
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    style={{
                                                        gap: "24px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <img src={imgProgress} />
                                                    <Typography
                                                        style={{
                                                            fontWeight: 800,
                                                            fontSize: "32px",
                                                            fontFamily: "Lato",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        In-Progress
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#6F6F70",
                                                            fontFamily: "Roboto",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        We are working on verifying your profile. This
                                                        usually happens within 24 hours and you’ll receive
                                                        an email once verified.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box>
                                                {!kycFailed && !kycVerified && (
                                                <Box
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                >
                                                    <Box style={{ textAlign: "justify" }}>
                                                        <Typography className={classes.headerText}>
                                                            Identity Verification
                                                        </Typography>
                                                        <Typography className={classes.textStyle}>
                                                            Please provide all required information in order
                                                            to complete profile verification.
                                                        </Typography>
                                                    </Box>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    Legal First Name
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={first_name}
                                                                    onChange={this.handleFirstNameChange}
                                                                    onBlur={this.validateFirstName}
                                                                    data-test-id="firstNameInput"
                                                                />
                                                                {this.state.first_name_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.first_name_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    Legal Last Name
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={last_name}
                                                                    onBlur={this.validateLastName}
                                                                    onChange={this.handleLastNameChange}
                                                                />
                                                                {this.state.last_name_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.last_name_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={6}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    Date Of Birth
                                                                </FormHelperText>
                                                                    <TextField
                                                                        style={{ borderRadius: "8px", height: "48px" }}
                                                                        type="date"
                                                                        value={date_of_birth}
                                                                        onChange={this.handleDobChange}
                                                                        onBlur={this.validateDob}
                                                                        inputProps={{
                                                                            'data-test-id': 'dateOfBirthInput',
                                                                            max: `${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${new Date().getDate()}`
                                                                        }}
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        className={classes.customOutlinedInput}
                                                                    />
                                                            {this.state.date_of_birth_error && (
                                                                <FormHelperText className={classes.errorText}>
                                                                    {this.state.date_of_birth_error}
                                                                </FormHelperText>
                                                            )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    Email
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        borderRadius: "8px",
                                                                        height: "48px",
                                                                    }}
                                                                    onBlur={this.validateEmail}
                                                                    value={email}
                                                                    onChange={this.handleEmailChange}
                                                                />
                                                                {this.state.email_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.email_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            md={4}
                                                            lg={4}
                                                            sm={4}
                                                            xs={4}
                                                            style={{
                                                                gap: "10px",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >

                                                            <FormControl
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                                variant="outlined"
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        margin: "0 8px 8px 0",
                                                                        fontSize: "14px",
                                                                        fontFamily: "Roboto",
                                                                        width: "150px",
                                                                        color: "#828282",
                                                                    }}
                                                                >
                                                                    Phone Number
                                                                </FormHelperText>

                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    options={config.countryData}
                                                                    value={this.state.country_code}
                                                                    onChange={this.setCountryCode}
                                                                    onBlur={this.validateCountryCode}
                                                                    getOptionLabel={(option: any) =>
                                                                        option.dial_code + " " + option.code
                                                                    }
                                                                    className={classes.autoComplateStyle}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} lg={8}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width:'100%' }}
                                                            >
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "0 8px 8px 0",
                                                                        marginTop: "31px",
                                                                    }}
                                                                    value={phone_number}
                                                                    onChange={this.handlePhoneNumberChange}
                                                                    onBlur={this.validatePhoneNumber}
                                                                />
                                                                
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>

                                                                {this.state.country_code_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                        style={{
                                                                            top: '-16px',
                                                                            position: 'relative'
                                                                        }}
                                                                    >
                                                                        {this.state.country_code_error}
                                                                    </FormHelperText>
                                                                )}

                                                                {this.state.phone_number_error && (
                                                                    <FormHelperText
                                                                        style={{
                                                                            top: '-16px',
                                                                            position: 'relative'
                                                                        }}
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.phone_number_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                    </Grid>

                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ margin: "16px 0px" }}
                                                    >
                                                        <FormHelperText
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "#828282",
                                                                fontFamily: "Roboto",
                                                                margin: "0 0 8px",
                                                            }}
                                                        >
                                                            Address
                                                        </FormHelperText>
                                                        <OutlinedInput
                                                            style={{
                                                                height: "48px",
                                                                borderRadius: "8px",
                                                            }}
                                                            value={address}
                                                            onChange={this.handleAddressChange}
                                                            onBlur={this.validateAddress}
                                                        />
                                                        {this.state.address_error && (
                                                            <FormHelperText className={classes.errorText}>
                                                                {this.state.address_error}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    City
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={city}
                                                                    onChange={this.handleCityChange}
                                                                    onBlur={this.validateCity}
                                                                    data-test-id="cityInput"
                                                                />
                                                                {this.state.city_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.city_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    State or Province
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={state}
                                                                    onBlur={this.validateState}
                                                                    onChange={this.handleStateChange}
                                                                    data-test-id="stateInput"
                                                                />
                                                                {this.state.state_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.state_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    Postal Code
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={postal_code}
                                                                    onChange={this.handlePostalCodeChange}
                                                                    onBlur={this.validatePostalCode}
                                                                    data-test-id="postalInput"
                                                                />
                                                                {this.state.postal_code_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.postal_code_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px", width: "100%" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    Country
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={country}
                                                                    onBlur={this.validateCountryCodeCheck}
                                                                    onChange={this.handleCountryChange}
                                                                    data-test-id="countryInput"
                                                                />
                                                                {this.state.country_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.country_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>

                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ margin: "16px 0px" }}
                                                    >
                                                        <FormHelperText
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "#828282",
                                                                fontFamily: "Roboto",
                                                                margin: "0 0 8px",
                                                            }}
                                                        >
                                                            Identification Number <br />
                                                        <span style={{fontSize:"12px"}}> (Note: Indian passport holders are requested to use their National ID (Aadhar) for quicker verification.)</span> 
                                                        </FormHelperText>
                                                        <Select
                                                            value={identification_type}
                                                            onChange={this.selectDocumentChange}
                                                            onBlur={this.validateDocument}
                                                            displayEmpty
                                                            style={{
                                                                height: "48px",
                                                                borderRadius: "8px",
                                                            }}
                                                            data-test-id="identification_type_id"
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select identification document</em>
                                                            </MenuItem>
                                                            <MenuItem value="passport">Passport</MenuItem>
                                                            <MenuItem value="national_id">
                                                                National ID
                                                            </MenuItem>
                                                            <MenuItem value="social_security">
                                                                Social Security
                                                            </MenuItem>
                                                        </Select>
                                                        {this.state.identification_type_error && (
                                                            <FormHelperText
                                                                style={{
                                                                    color: "#DC2626",
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    margin: "0px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {this.state.identification_type_error}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    {identification_type !== "" && (
                                                        <>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ margin: "16px 0px" }}
                                                            >
                                                                <FormHelperText
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        color: "#828282",
                                                                        fontFamily: "Roboto",
                                                                        margin: "0 0 8px",
                                                                    }}
                                                                >
                                                                    {identification_type === "passport" &&
                                                                        "Passport number"}
                                                                    {identification_type === "national_id" &&
                                                                        "National ID number"}
                                                                    {identification_type ===
                                                                        "social_security" &&
                                                                        "Social Security number"}
                                                                </FormHelperText>
                                                                <OutlinedInput
                                                                    style={{
                                                                        height: "48px",
                                                                        borderRadius: "8px",
                                                                    }}
                                                                    value={identification_number}
                                                                    onBlur={this.validateIdentificationNumber}
                                                                    onChange={
                                                                        this.handleIdentificationNumberChange
                                                                    }
                                                                />
                                                                {this.state.identification_number_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.identification_number_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                            {identification_type === "passport" && (
                                                                <Grid container spacing={2}>
                                                                    <Grid item md={6} lg={6} xs={12} sm={12} >
                                                                        <FormControl
                                                                            style={{
                                                                                width: "100%",
                                                                                margin: "16px 0px",
                                                                            }}
                                                                            variant="outlined"
                                                                        >
                                                                            <FormHelperText
                                                                                style={{
                                                                                    margin: "0 0 8px",
                                                                                    fontSize: "14px",
                                                                                    color: "#828282",
                                                                                    fontFamily: "Roboto",
                                                                                }}
                                                                            >
                                                                                Issue Date
                                                                            </FormHelperText>
                                                                            <TextField
                                                                                type="date"
                                                                                style={{
                                                                                    borderRadius: "8px",
                                                                                    height: "48px",
                                                                                }}
                                                                                value={issue_date}
                                                                                onBlur={this.validateIssueDateCode}
                                                                                onChange={this.handleIssueDateChange}
                                                                                inputProps={{
                                                                                    'data-test-id': 'issueDateInput',
                                                                                }}
                                                                                className={classes.customOutlinedInput}
                                                                                variant="outlined"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                            {this.state.issue_date_error && (
                                                                                <FormHelperText
                                                                                    className={classes.errorText}
                                                                                >
                                                                                    {this.state.issue_date_error}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item md={6} lg={6} xs={12} sm={12} >
                                                                        <FormControl
                                                                            style={{
                                                                                width: "100%",
                                                                                margin: "16px 0px",
                                                                            }}
                                                                            variant="outlined"
                                                                        >
                                                                            <FormHelperText
                                                                                style={{
                                                                                    margin: "0 0 8px",
                                                                                    color: "#828282",
                                                                                    fontSize: "14px",
                                                                                    fontFamily: "Roboto",
                                                                                }}
                                                                            >
                                                                                Expiration Date
                                                                            </FormHelperText>
                                                                             <TextField
                                                                                type="date"
                                                                                style={{
                                                                                    borderRadius: "8px",
                                                                                    height: "48px",
                                                                                }}
                                                                                onBlur={this.validateExpirationDateCode}
                                                                                value={expiration_date}
                                                                                onChange={this.handleExpirationDateChange}
                                                                                inputProps={{
                                                                                    'data-test-id': 'expireDateInput',
                                                                                }}
                                                                                className={classes.customOutlinedInput}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                variant="outlined"
                                                                            />
                                                                            {this.state.expiration_date_error && (
                                                                                <FormHelperText
                                                                                    className={classes.errorText}
                                                                                >
                                                                                    {this.state.expiration_date_error}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                    <Box
                                                        style={{
                                                            maxWidth: "500px",
                                                            margin: "40px 0px",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <div>
                                                            <Button
                                                                className={classes.startBtn}
                                                                onClick={this.handleClickOpen}
                                                            >
                                                                Start Over
                                                            </Button>
                                                            <Dialog
                                                                open={open}
                                                                onClose={this.handleClose}
                                                                className={classes.dialogContainer}
                                                                PaperProps={{
                                                                    style: {
                                                                        borderRadius: 16,
                                                                    },
                                                                }}
                                                            >
                                                                <DialogTitle
                                                                    style={{ padding: "16px 24px 0px" }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            color: "#222222",
                                                                            fontSize: "32px",
                                                                            fontWeight: 700,
                                                                            padding: "0px 16px",
                                                                        }}
                                                                    >
                                                                        {"Start Over"}
                                                                    </Typography>
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#6F6F70",
                                                                            fontSize: "16px",
                                                                            margin: "16px",
                                                                        }}
                                                                    >
                                                                        By continuing you will delete the investor
                                                                        profile that is in progress. This action
                                                                        cannot be undone.
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#222222",
                                                                            fontSize: "16px",
                                                                            fontWeight: 600,
                                                                            margin: "16px",
                                                                        }}
                                                                    >
                                                                        Would you like to delete this investor
                                                                        profile and start a new one?
                                                                    </Typography>
                                                                </DialogContent>
                                                                <DialogActions
                                                                    style={{
                                                                        justifyContent: "space-between",
                                                                        padding: "0px 40px 30px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className={classes.startBtn}
                                                                        onClick={this.handleClose}
                                                                        style={{ width: "241px" }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        className={classes.nextBtn}
                                                                        onClick={this.handleStartOver}
                                                                        style={{ width: "241px" }}
                                                                    >
                                                                        Confirm
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                        <Button
                                                            className={classes.nextBtn}
                                                            onClick={this.handleKycSubmit}
                                                        >
                                                            Next
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                )}
                                                { kycVerified && (
                                                    <Box style={{ height: "80vh" }}>
                                                        <Box
                                                            style={{
                                                                marginBottom: "50px",
                                                                textAlign: "justify",
                                                            }}
                                                        >
                                                            <Typography className={classes.headerText}>
                                                                Verification Status
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                gap: "24px",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <img src={imgSuccess} />
                                                            <Typography
                                                                style={{
                                                                    fontSize: "32px",
                                                                    fontWeight: 800,
                                                                    fontFamily: "Lato",
                                                                    color: "#222222",
                                                                }}
                                                            >
                                                                Verified!
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    textAlign: "center",
                                                                    fontSize: "16px",
                                                                    color: "#6F6F70",
                                                                }}
                                                            >
                                                                Your details has been verified successfully.
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {kycFailed && (
                                                    <Box style={{ height: "80vh" }}>
                                                        <Box
                                                            style={{
                                                                marginBottom: "50px",
                                                                textAlign: "justify",
                                                            }}
                                                        >
                                                            <Typography className={classes.headerText}>
                                                                Verification Status
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                display: "flex",
                                                                gap: "24px",
                                                            }}
                                                        >
                                                            <img src={imgFailed} />
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 800,
                                                                    color: "#222222",
                                                                    fontFamily: "Lato",
                                                                    fontSize: "32px",
                                                                }}
                                                            >
                                                                Unable to verify
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    textAlign: "center",
                                                                    fontSize: "16px",
                                                                    fontFamily: "Roboto",
                                                                    color: "#6F6F70",
                                                                }}
                                                            >
                                                                We were unable yo verify the identity to you or
                                                                your business. We will automatically review to
                                                                ensure this wasn’t an error. If you think this
                                                                is a mistake, please open a support ticket.
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                )} 

                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                (identification_type === "national_id" && page === 8) && (
                                    <>
                                        {this.adhaarVerificationDetails()}
                                    </>
                                )}
                            {investorType === "As an individual" &&
                                !investorTypeSelect &&
                                (identification_type === "national_id" && page === 9) && (
                                    <Box className={classes.centerMainBox}>
                                            <Box>
                                                {!kycFailed && !kycVerified && (
                                                    <>
                                                        {this.adhaarVerificationOTP()}
                                                    </>
                                                )}
                                                {kycVerified && (
                                                    <Box style={{ height: "80vh" }}>
                                                        <Box
                                                            style={{
                                                                marginBottom: "50px",
                                                                textAlign: "justify",
                                                            }}
                                                        >
                                                            <Typography className={classes.headerText}>
                                                                Verification Status
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                gap: "24px",
                                                            }}
                                                        >
                                                            <img src={imgSuccess} />
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Lato",
                                                                    fontSize: "32px",
                                                                    color: "#222222",
                                                                    fontWeight: 800,
                                                                }}
                                                            >
                                                                Verified!
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: "#6F6F70",
                                                                    fontSize: "16px",
                                                                    fontFamily: "Roboto",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Your details has been verified successfully.
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {kycFailed && (
                                                    <Box style={{ height: "80vh" }}>
                                                        <Box
                                                            style={{
                                                                textAlign: "justify",
                                                                marginBottom: "50px",
                                                            }}
                                                        >
                                                            <Typography className={classes.headerText}>
                                                                Verification Status
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                display: "flex",
                                                                gap: "24px",
                                                            }}
                                                        >
                                                            <img src={imgFailed} />
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Lato",
                                                                    fontSize: "32px",
                                                                    color: "#222222",
                                                                    fontWeight: 800,
                                                                }}
                                                            >
                                                                Unable to verify
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: "#6F6F70",
                                                                    fontSize: "16px",
                                                                    textAlign: "center",
                                                                    fontFamily: "Roboto",
                                                                }}
                                                            >
                                                                We were unable yo verify the identity to you or
                                                                your business. We will automatically review to
                                                                ensure this wasn’t an error. If you think this
                                                                is a mistake, please open a support ticket.
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                    </Box>
                                )} 

                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 2 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                Is your organization “family run”?
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                padding: "16px",
                                                gap: "16px",
                                                borderRadius: "12px",
                                                background: "#0069BC1A",
                                                display: "flex",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            <img src={imgInfo} />
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    fontSize: "16px",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                    color: "#6F6F70",
                                                    lineHeight:"24px",
                                                    fontFamily: 'Roboto',
                                                    fontWeight: "400"
                                                }}
                                            >
                                                A “family company” means any company.(including a trust,
                                                partnership, limited liability company, or corporation)
                                                that is owned directly or indirectly by or for (i)(A)
                                                two or more natural persons who are related as siblings,
                                                spouses or former spouses, or as direct lineal
                                                descendants by birth or adoption, or (B) spouses of such
                                                persons, (ii) estates of such persons, or (iii)
                                                foundations, charitable organizations, or trusts
                                                established by or for the benefit of such persons.
                                            </Box>
                                        </Box>
                                        <Button
                                            onClick={() => this.handleFamilyRunChange('Yes')}
                                            className={classes.btn}
                                            style={{
                                                color: is_family_run === "Yes" ? "#0069BC" : "#868686",
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            onClick={() => this.handleFamilyRunChange('No')}
                                            className={classes.btn}
                                            style={{
                                                color: is_family_run === "No" ? "#0069BC" : "#868686",
                                            }}
                                        >
                                            No
                                        </Button>
                                        <Box
                                            style={{
                                                maxWidth: "500px",
                                                margin: "40px 0px",
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>
                                                <Button
                                                    onClick={this.handleClickOpen}
                                                    className={classes.startBtn}
                                                >
                                                    Start Over
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    className={classes.dialogContainer}
                                                    onClose={this.handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            borderRadius: 16,
                                                        },
                                                    }}
                                                >
                                                    <DialogTitle style={{ padding: "16px 24px 0px" }}>
                                                        <Typography
                                                            style={{
                                                                fontSize: "32px",
                                                                color: "#222222",
                                                                fontWeight: 700,
                                                                padding: "0px 16px",
                                                            }}
                                                        >
                                                            {"Start Over"}
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Typography
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#6F6F70",
                                                                margin: "16px",
                                                            }}
                                                        >
                                                            By continuing you will delete the investor profile
                                                            that is in progress. This action cannot be undone.
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#222222",
                                                                fontWeight: 600,
                                                                margin: "16px",
                                                            }}
                                                        >
                                                            Would you like to delete this investor profile and
                                                            start a new one?
                                                        </Typography>
                                                    </DialogContent>
                                                    <DialogActions
                                                        style={{
                                                            justifyContent: "space-between",
                                                            padding: "0px 40px 30px",
                                                        }}
                                                    >
                                                        <Button
                                                            onClick={this.handleClose}
                                                            className={classes.startBtn}
                                                            style={{ width: "241px" }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={this.handleStartOver}
                                                            className={classes.nextBtn}
                                                            style={{ width: "241px" }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                            <Button
                                                onClick={this.nextPage}
                                                className={classes.nextBtn}
                                                disabled={is_family_run === ""}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 3 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography
                                                className={classes.textStyle}
                                            >
                                                What experience do you or your entity have with
                                                alternative investments as an asset class?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    New to Alternative Investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handleAlternativeInvestmentChange}
                                                checked={
                                                    experience_alternative_investment ===
                                                    "New to Alternative Investments"
                                                }
                                                name="radio-buttons"
                                                value="New to Alternative Investments"
                                                style={{
                                                    color:
                                                        experience_alternative_investment ===
                                                            "New to Alternative Investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Moderate experience in alternative investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handleAlternativeInvestmentChange}
                                                checked={
                                                    experience_alternative_investment ===
                                                    "Moderate experience in alternative investments"
                                                }
                                                name="radio-buttons"
                                                value="Moderate experience in alternative investments"
                                                style={{
                                                    color:
                                                        experience_alternative_investment ===
                                                            "Moderate experience in alternative investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Extensive experience in alternative investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handleAlternativeInvestmentChange}
                                                checked={
                                                    experience_alternative_investment ===
                                                    "Extensive experience in alternative investments"
                                                }
                                                name="radio-buttons"
                                                value="Extensive experience in alternative investments"
                                                style={{
                                                    color:
                                                        experience_alternative_investment ===
                                                            "Extensive experience in alternative investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            onClick={this.nextPage}
                                            className={classes.nextBtn}
                                            disabled={experience_alternative_investment === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 4 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                What’s driving your alternative investment strategy?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Secure access to alternative investments
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handleInvestmentStrategyChange}
                                                checked={
                                                    alternative_investment_strategy ===
                                                    "Secure access to alternative investments"
                                                }
                                                name="radio-buttons"
                                                value="Secure access to alternative investments"
                                                style={{
                                                    color:
                                                        alternative_investment_strategy ===
                                                            "Secure access to alternative investments"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Discover options with higher than usual returns
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handleInvestmentStrategyChange}
                                                checked={
                                                    alternative_investment_strategy ===
                                                    "Discover options with higher than usual returns"
                                                }
                                                name="radio-buttons"
                                                value="Discover options with higher than usual returns"
                                                style={{
                                                    color:
                                                        alternative_investment_strategy ===
                                                            "Discover options with higher than usual returns"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Expand the diversity of your investment portfolio
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handleInvestmentStrategyChange}
                                                checked={
                                                    alternative_investment_strategy ===
                                                    "Expand the diversity of your investment portfolio"
                                                }
                                                name="radio-buttons"
                                                value="Expand the diversity of your investment portfolio"
                                                style={{
                                                    color:
                                                        alternative_investment_strategy ===
                                                            "Expand the diversity of your investment portfolio"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            onClick={this.nextPage}
                                            className={classes.nextBtn}
                                            disabled={alternative_investment_strategy === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 5 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                What is the extent of capital actively invested by you or
                                                your entity?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                Less than $1M
                                            </Typography>
                                            <Radio
                                                onChange={this.handleCapitalInvestedChange}
                                                checked={capital_actively_invested === "Less than $1M"}
                                                value="Less than $1M"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "Less than $1M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                                name="radio-buttons"
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                $1M - $50M
                                            </Typography>
                                            <Radio
                                                onChange={this.handleCapitalInvestedChange}
                                                checked={capital_actively_invested === "$1M - $50M"}
                                                value="$1M - $50M"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "$1M - $50M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                                name="radio-buttons"
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                $50M - $100M
                                            </Typography>
                                            <Radio
                                                onChange={this.handleCapitalInvestedChange}
                                                checked={capital_actively_invested === "$50M - $100M"}
                                                value="$50M - $100M"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "$50M - $100M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                                name="radio-buttons"
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                $100M - $500M
                                            </Typography>
                                            <Radio
                                                onChange={this.handleCapitalInvestedChange}
                                                checked={capital_actively_invested === "$100M - $500M"}
                                                value="$100M - $500M"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "$100M - $500M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                                name="radio-buttons"
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                Over $500M
                                            </Typography>
                                            <Radio
                                                onChange={this.handleCapitalInvestedChange}
                                                checked={capital_actively_invested === "Over $500M"}
                                                value="Over $500M"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "Over $500M"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                                name="radio-buttons"
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Typography className={classes.option}>
                                                Over $1B
                                            </Typography>
                                            <Radio
                                                onChange={this.handleCapitalInvestedChange}
                                                checked={capital_actively_invested === "Over $1B"}
                                                value="Over $1B"
                                                style={{
                                                    color:
                                                        capital_actively_invested === "Over $1B"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                                name="radio-buttons"
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            onClick={this.nextPage}
                                            className={classes.nextBtn}
                                            disabled={capital_actively_invested === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 6 && (
                                    <Box className={classes.centerMainBox}>
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Investor Profile
                                            </Typography>
                                            <Typography
                                                className={classes.textStyle}
                                            >
                                                What percentage are you aiming to allocate into
                                                alternative investments?
                                            </Typography>
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    gap: "16px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    1-10%
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handlePercentageAllocateChange}
                                                checked={percentage_to_allocate === "1-10%"}
                                                name="radio-buttons"
                                                value="1-10%"
                                                style={{
                                                    color:
                                                        percentage_to_allocate === "1-10%"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    10 - 20%
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handlePercentageAllocateChange}
                                                checked={percentage_to_allocate === "10 - 20%"}
                                                name="radio-buttons"
                                                value="10 - 20%"
                                                style={{
                                                    color:
                                                        percentage_to_allocate === "10 - 20%"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    20 - 30%
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handlePercentageAllocateChange}
                                                checked={percentage_to_allocate === "20 - 30%"}
                                                name="radio-buttons"
                                                value="20 - 30%"
                                                style={{
                                                    color:
                                                        percentage_to_allocate === "20 - 30%"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Box className={classes.optionBox}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                }}
                                            >
                                                <Typography className={classes.option}>
                                                    Over 30%
                                                </Typography>
                                            </Box>
                                            <Radio
                                                onChange={this.handlePercentageAllocateChange}
                                                checked={percentage_to_allocate === "Over 30%"}
                                                name="radio-buttons"
                                                value="Over 30%"
                                                style={{
                                                    color:
                                                        percentage_to_allocate === "Over 30%"
                                                            ? "#0069BC"
                                                            : "#6F6F70",
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            style={{ margin: "40px 0px 0px 0px" }}
                                            onClick={this.nextPage}
                                            className={classes.nextBtn}
                                            disabled={percentage_to_allocate === ""}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 7 && (
                                    <Box
                                        className={classes.centerMainBox}
                                        style={{ gap: "16px" }}
                                    >
                                        <Box style={{ textAlign: "justify" }}>
                                            <Typography className={classes.headerText}>
                                                Identity Accreditation
                                            </Typography>
                                            <Typography className={classes.textStyle}>
                                                Tell us how your organization is accredited:
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                padding: "16px",
                                                gap: "16px",
                                                borderRadius: "12px",
                                                background: "#0069BC1A",
                                                display: "flex",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            <img src={imgInfo} />
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px",
                                                    fontSize: "16px",
                                                    color: "#6F6F70",
                                                    lineHeight:"24px",
                                                    fontFamily: '"Inter", sans-serif',
                                                    fontOpticalSizing: 'auto',
                                                    fontWeight: 400,
                                                    fontStyle: 'normal',
                                                    fontVariationSettings: '"slnt" 0'
                                                }}
                                            >
                                                <li>
                                                    Only one qualification is required, but you should
                                                    select the highest level that you or your entity
                                                    qualify for.
                                                </li>
                                                <li>
                                                    The SEC requires that all U.S. investors in Future for
                                                    Alts funds must be accredited.{" "}
                                                </li>
                                            </Box>
                                        </Box>
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 0
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(0)}
                                                    style={{
                                                        marginRight: "8px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight:600 }}>
                                                        Ownership - all equity owners are qualified
                                                        purchasers
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 1
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(1)}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontWeight:600, fontSize: "16px" }}>
                                                        Investments - $5M or more in investments owned (must
                                                        be a “family company”)
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 2
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(2)}
                                                    style={{
                                                        marginRight: "8px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight:600 }}>
                                                        Investments - $25M or more in investments owned or
                                                        managed
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 3
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(3)}
                                                    style={{
                                                        marginRight: "8px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight:600 }}>
                                                        Ownership - all equity owners are accredited
                                                        investors
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={
                                                        selectedCheckbox === 4
                                                            ? imgBlueCheck
                                                            : imgBlueUncheck
                                                    }
                                                    alt="checkbox"
                                                    onClick={() => this.handleCheckboxChange(4)}
                                                    style={{
                                                        marginRight: "8px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography style={{ fontWeight:600, fontSize: "16px" }}>
                                                        My organization is not accredited
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ margin: "16px 0px 0px" }}
                                            control={
                                                <img
                                                    src={confirmSelection ? imgBlueCheck : imgDarkUncheck}
                                                    alt="checkbox"
                                                    onClick={() => this.handleConfirmSelection()}
                                                    style={{
                                                        margin: "0px 8px 20px 0px",
                                                        height: "22px",
                                                        width: "22px",
                                                    }}
                                                />
                                            }
                                            label={
                                                <span style={{ textAlign: "justify" }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: 900,
                                                            fontFamily: "Roboto",
                                                            lineHeight: "24px",
                                                        }}
                                                    >
                                                        I confirm that my selection is accurate and that I
                                                        am an accredited investor
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                        <FormHelperText
                                            className={classes.errorText}
                                            style={{ marginLeft: "28px" }}
                                        >
                                            {this.state.confirmation_error}
                                        </FormHelperText>
                                        <Box
                                            style={{
                                                maxWidth: "500px",
                                                cursor: "pointer",
                                                display: "flex",
                                                margin: "40px 0px",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>
                                                <Button
                                                    className={classes.startBtn}
                                                    onClick={this.handleClickOpen}
                                                >
                                                    Start Over
                                                </Button>
                                                <Dialog
                                                    open={open}
                                                    onClose={this.handleClose}
                                                    className={classes.dialogContainer}
                                                    PaperProps={{
                                                        style: {
                                                            borderRadius: 16,
                                                        },
                                                    }}
                                                >
                                                    <DialogTitle style={{ padding: "16px 24px 0px" }}>
                                                        <Typography
                                                            style={{
                                                                color: "#222222",
                                                                fontSize: "32px",
                                                                fontWeight: 700,
                                                                padding: "0px 16px",
                                                            }}
                                                        >
                                                            {"Start Over"}
                                                        </Typography>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Typography
                                                            style={{
                                                                color: "#6F6F70",
                                                                fontSize: "16px",
                                                                margin: "16px",
                                                            }}
                                                        >
                                                            By continuing you will delete the investor profile
                                                            that is in progress. This action cannot be undone.
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                color: "#222222",
                                                                fontSize: "16px",
                                                                fontWeight: 600,
                                                                margin: "16px",
                                                            }}
                                                        >
                                                            Would you like to delete this investor profile and
                                                            start a new one?
                                                        </Typography>
                                                    </DialogContent>
                                                    <DialogActions
                                                        style={{
                                                            justifyContent: "space-between",
                                                            padding: "0px 40px 30px",
                                                        }}
                                                    >
                                                        <Button
                                                            className={classes.startBtn}
                                                            onClick={this.handleClose}
                                                            style={{ width: "241px" }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            className={classes.nextBtn}
                                                            onClick={this.handleStartOver}
                                                            style={{ width: "241px" }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                            <Button
                                                className={classes.nextBtn}
                                                onClick={this.goOnNextPage}
                                                disabled={selectedCheckbox === null}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                page === 8 && (
                                    <Box className={classes.centerMainBox}>
                                        {loading ? (
                                            <Box style={{ height: "80vh" }}>
                                                <Box
                                                    style={{ marginBottom: "50px", textAlign: "justify" }}
                                                >
                                                    <Typography className={classes.headerText}>
                                                        Verification Status
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                        gap: "24px",
                                                    }}
                                                >
                                                    <img src={imgProgress} />
                                                    <Typography
                                                        style={{
                                                            color: "#222222",
                                                            fontSize: "32px",
                                                            fontFamily: "Lato",
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        In-Progress
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            color: "#6F6F70",
                                                            fontSize: "16px",
                                                            fontFamily: "Roboto",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        We are working on verifying your profile. This
                                                        usually happens within 24 hours and you’ll receive
                                                        an email once verified.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box>
                                                {!kycVerified && !kycFailed && (
                                                    <Box
                                                        style={{ display: "flex", flexDirection: "column" }}
                                                    >
                                                        <Box style={{ textAlign: "justify" }}>
                                                            <Typography className={classes.headerText}>
                                                                Identity Verification
                                                            </Typography>
                                                            <Typography className={classes.textStyle}>
                                                                Please provide all required information in order
                                                                to complete profile verification.
                                                            </Typography>
                                                        </Box>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%", margin: "16px 0px" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            color: "#828282",
                                                                            margin: "0 0 8px",
                                                                            fontFamily: "Roboto",
                                                                        }}
                                                                    >
                                                                        Legal First Name
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            height: "48px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        onChange={this.handleFirstNameChange}
                                                                        onBlur={this.validateFirstName}
                                                                        value={first_name}
                                                                    />
                                                                    {this.state.first_name_error && (
                                                                        <FormHelperText className={classes.errorText}>
                                                                            {this.state.first_name_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%", margin: "16px 0px" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            color: "#828282",
                                                                            fontFamily: "Roboto",
                                                                            margin: "0 0 8px",
                                                                            fontSize: "14px",
                                                                        }}
                                                                    >
                                                                        Legal Last Name
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            height: "48px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        onChange={this.handleLastNameChange}
                                                                        onBlur={this.validateLastName}
                                                                        value={last_name}
                                                                    />
                                                                    {this.state.last_name_error && (
                                                                        <FormHelperText className={classes.errorText}>
                                                                            {this.state.last_name_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={2}>
                                                            <Grid
                                                                item
                                                                md={6}
                                                                lg={6}
                                                                xs={12}
                                                                sm={12}
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            color: "#828282",
                                                                            fontFamily: "Roboto",
                                                                            margin: "0 0 8px",
                                                                        }}
                                                                    >
                                                                        Date Of Birth
                                                                    </FormHelperText>
                                                                    <TextField
                                                                        style={{
                                                                            height: "48px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        value={date_of_birth}
                                                                        type="date"
                                                                        onBlur={this.validateDob}
                                                                        onChange={this.handleDobChange}
                                                                        inputProps={{
                                                                            'data-test-id': 'dateOfBirthInput',
                                                                            max: `${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().padStart(2, '0')}-${new Date().getDate()}`
                                                                        }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        variant="outlined"
                                                                        className={classes.customOutlinedInput}
                                                                    />
                                                                    {this.state.date_of_birth_error && (
                                                                        <FormHelperText className={classes.errorText}>
                                                                            {this.state.date_of_birth_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            margin: "0 0 8px",
                                                                            fontSize: "14px",
                                                                            color: "#828282",
                                                                            fontFamily: "Roboto",
                                                                        }}
                                                                    >
                                                                        Email
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            height: "48px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        value={email}
                                                                        onChange={this.handleEmailChange}
                                                                        onBlur={this.validateEmail}
                                                                    />
                                                                    {this.state.email_error && (
                                                                        <FormHelperText
                                                                            className={classes.errorText}
                                                                        >
                                                                            {this.state.email_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                sm={4}
                                                                md={4}
                                                                lg={4}
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    gap: "10px",
                                                                }}
                                                            >

                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            width: "150px",
                                                                            color: "#828282",
                                                                            fontFamily: "Roboto",
                                                                            margin: "0 8px 8px 0",
                                                                        }}
                                                                    >
                                                                        Phone Number
                                                                    </FormHelperText>

                                                                    <Autocomplete
                                                                        options={config.countryData}
                                                                        onChange={this.setCountryCode}
                                                                        id="combo-box-demo"
                                                                        value={this.state.country_code}
                                                                        onBlur={this.validateCountryCode}
                                                                        getOptionLabel={(option: any) =>
                                                                            option.dial_code + " " + option.code
                                                                        }
                                                                        className={classes.autoComplateStyle}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} lg={8}>
                                                                <FormControl
                                                                    style={{ margin: "16px 0px", width: '100%' }}
                                                                    variant="outlined"
                                                                >
                                                                    <OutlinedInput
                                                                        style={{
                                                                            marginTop: "31px",
                                                                            height: "48px",
                                                                            borderRadius: "0 8px 8px 0",
                                                                        }}
                                                                        value={phone_number}
                                                                        onBlur={this.validatePhoneNumber}
                                                                        onChange={this.handlePhoneNumberChange}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                {this.state.country_code_error && (
                                                                    <FormHelperText
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: '-16px',
                                                                        }}
                                                                        className={classes.errorText}
                                                                    >
                                                                        {this.state.country_code_error}
                                                                    </FormHelperText>
                                                                )}

                                                                {this.state.phone_number_error && (
                                                                    <FormHelperText
                                                                        className={classes.errorText}
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: '-16px',
                                                                        }}
                                                                    >
                                                                        {this.state.phone_number_error}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>

                                                        <FormControl
                                                            variant="outlined"
                                                            style={{ width: "100%", margin: "16px 0px" }}
                                                        >
                                                            <FormHelperText
                                                                style={{
                                                                    fontSize: "14px",
                                                                    margin: "0 0 8px",
                                                                    color: "#828282",
                                                                    fontFamily: "Roboto",
                                                                }}
                                                            >
                                                                Address
                                                            </FormHelperText>
                                                            <OutlinedInput
                                                                style={{
                                                                    height: "48px",
                                                                    borderRadius: "8px",
                                                                }}
                                                                onChange={this.handleAddressChange}
                                                                onBlur={this.validateAddress}
                                                                value={address}
                                                            />
                                                            {this.state.address_error && (
                                                                <FormHelperText className={classes.errorText}>
                                                                    {this.state.address_error}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                        <Grid container spacing={2}>
                                                            <Grid item md={6} lg={6} xs={12} sm={12} >
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            color: "#828282",
                                                                            fontSize: "14px",
                                                                            fontFamily: "Roboto",
                                                                            margin: "0 0 8px",
                                                                        }}
                                                                    >
                                                                        City
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            height: "48px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        onChange={this.handleCityChange}
                                                                        data-test-id="cityInput"
                                                                        onBlur={this.validateCity}
                                                                        value={city}
                                                                    />
                                                                    {this.state.city_error && (
                                                                        <FormHelperText
                                                                            className={classes.errorText}
                                                                        >
                                                                            {this.state.city_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={6} lg={6} xs={12} sm={12} >
                                                                <FormControl
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                    variant="outlined"
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            fontFamily: "Roboto",
                                                                            fontSize: "14px",
                                                                            color: "#828282",
                                                                            margin: "0 0 8px",
                                                                        }}
                                                                    >
                                                                        State or Province
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            height: "48px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                        onBlur={this.validateState}
                                                                        value={state}
                                                                        data-test-id="stateInput"
                                                                        onChange={this.handleStateChange}
                                                                    />
                                                                    {this.state.state_error && (
                                                                        <FormHelperText
                                                                            className={classes.errorText}
                                                                        >
                                                                            {this.state.state_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item md={6} lg={6} xs={12} sm={12} >
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            margin: "0 0 8px",
                                                                            color: "#828282",
                                                                            fontFamily: "Roboto",
                                                                        }}
                                                                    >
                                                                        Postal Code
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            borderRadius: "8px",
                                                                            height: "48px",
                                                                        }}
                                                                        data-test-id="postalInput"
                                                                        onChange={this.handlePostalCodeChange}
                                                                        value={postal_code}
                                                                        onBlur={this.validatePostalCode}
                                                                    />
                                                                    {this.state.postal_code_error && (
                                                                        <FormHelperText
                                                                            className={classes.errorText}
                                                                        >
                                                                            {this.state.postal_code_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={6} lg={6} xs={12} sm={12} >
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px", width: "100%" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            margin: "0 0 8px",
                                                                            fontSize: "14px",
                                                                            color: "#828282",
                                                                            fontFamily: "Roboto",
                                                                        }}
                                                                    >
                                                                        Country
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            borderRadius: "8px",
                                                                            height: "48px",
                                                                        }}
                                                                        value={country}
                                                                        data-test-id="countryInput"
                                                                        onBlur={this.validateCountryCodeCheck}
                                                                        onChange={this.handleCountryChange}
                                                                    />
                                                                    {this.state.country_error && (
                                                                        <FormHelperText
                                                                            className={classes.errorText}
                                                                        >
                                                                            {this.state.country_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                        <FormControl
                                                            variant="outlined"
                                                            style={{ margin: "16px 0px" }}
                                                        >
                                                            <FormHelperText
                                                                style={{
                                                                    fontSize: "14px",
                                                                    margin: "0 0 8px",
                                                                    color: "#828282",
                                                                    fontFamily: "Roboto",
                                                                }}
                                                            >
                                                                Identification Number <br />

                                                            <span style={{fontSize:"12px"}}> (Note: Indian passport holders are requested to use their National ID (Aadhar) for quicker verification.) </span> 
                                                            </FormHelperText>
                                                            <Select
                                                                data-test-id="identification_type_id_check"
                                                                value={identification_type}
                                                                onChange={this.selectDocumentChange}
                                                                onBlur={this.validateDocument}
                                                                displayEmpty
                                                                style={{
                                                                    height: "48px",
                                                                    maxWidth: "505px",
                                                                    width: "100%",
                                                                    borderRadius: "8px",
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    <em>Select identification document</em>
                                                                </MenuItem>
                                                                <MenuItem value="passport">Passport</MenuItem>
                                                                <MenuItem value="national_id">
                                                                    National ID
                                                                </MenuItem>
                                                                <MenuItem value="social_security">
                                                                    Social Security
                                                                </MenuItem>
                                                            </Select>
                                                            {this.state.identification_type_error && (
                                                                <FormHelperText className={classes.errorText}>
                                                                    {this.state.identification_type_error}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                        {identification_type !== "" && (
                                                            <>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ margin: "16px 0px" }}
                                                                >
                                                                    <FormHelperText
                                                                        style={{
                                                                            color: "#828282",
                                                                            margin: "0 0 8px",
                                                                            fontSize: "14px",
                                                                            fontFamily: "Roboto",
                                                                        }}
                                                                    >
                                                                        {identification_type === "national_id" &&
                                                                            "National ID number"}
                                                                        {identification_type === "passport" &&
                                                                            "Passport number"}
                                                                        {identification_type === "social_security" &&
                                                                            "Social Security number"}
                                                                    </FormHelperText>
                                                                    <OutlinedInput
                                                                        style={{
                                                                            maxWidth: "505px",
                                                                            width: "100%",
                                                                            borderRadius: "8px",
                                                                            height: "48px",
                                                                        }}
                                                                        onChange={
                                                                            this.handleIdentificationNumberChange
                                                                        }
                                                                        onBlur={this.validateIdentificationNumber}
                                                                        value={identification_number}
                                                                    />
                                                                    {this.state.identification_number_error && (
                                                                        <FormHelperText className={classes.errorText}>
                                                                            {this.state.identification_number_error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>

                                                                {identification_type === "passport" && (
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                            <FormControl
                                                                                variant="outlined"
                                                                                style={{
                                                                                    margin: "16px 0px",
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                <FormHelperText
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "#828282",
                                                                                        fontFamily: "Roboto",
                                                                                        margin: "0 0 8px",
                                                                                    }}
                                                                                >
                                                                                    Issue Date
                                                                                </FormHelperText>
                                                                                <TextField
                                                                                    style={{
                                                                                        height: "48px",
                                                                                        borderRadius: "8px",
                                                                                    }}
                                                                                    value={issue_date}
                                                                                    type="date"
                                                                                    onChange={this.handleIssueDateChange}
                                                                                    onBlur={this.validateIssueDateCode}
                                                                                    inputProps={{
                                                                                        'data-test-id': 'issueDateInput',
                                                                                    }}
                                                                                    className={classes.customOutlinedInput}
                                                                                    variant="outlined"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                                {this.state.issue_date_error && (
                                                                                    <FormHelperText
                                                                                        className={classes.errorText}
                                                                                    >
                                                                                        {this.state.issue_date_error}
                                                                                    </FormHelperText>
                                                                                )}
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                            <FormControl
                                                                                variant="outlined"
                                                                                style={{
                                                                                    margin: "16px 0px",
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                <FormHelperText
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        color: "#828282",
                                                                                        fontFamily: "Roboto",
                                                                                        margin: "0 0 8px",
                                                                                    }}
                                                                                >
                                                                                    Expiration Date
                                                                                </FormHelperText>
                                                                                <TextField
                                                                                    style={{
                                                                                        borderRadius: "8px",
                                                                                        height: "48px",
                                                                                    }}
                                                                                    type="date"
                                                                                    value={expiration_date}
                                                                                    onBlur={this.validateExpirationDateCode}
                                                                                    onChange={this.handleExpirationDateChange}
                                                                                    className={classes.customOutlinedInput}
                                                                                    variant="outlined"
                                                                                    inputProps={{
                                                                                        'data-test-id': 'expireDateInput',
                                                                                    }}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                                {this.state.expiration_date_error && (
                                                                                    <FormHelperText
                                                                                        className={classes.errorText}
                                                                                    >
                                                                                        {this.state.expiration_date_error}
                                                                                    </FormHelperText>
                                                                                )}
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                        <Box
                                                            style={{
                                                                maxWidth: "500px",
                                                                cursor: "pointer",
                                                                margin: "40px 0px",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <Button
                                                                    onClick={this.handleClickOpen}
                                                                    className={classes.startBtn}
                                                                >
                                                                    Start Over
                                                                </Button>
                                                                <Dialog
                                                                    open={open}
                                                                    className={classes.dialogContainer}
                                                                    onClose={this.handleClose}
                                                                    PaperProps={{
                                                                        style: {
                                                                            borderRadius: 16,
                                                                        },
                                                                    }}
                                                                >
                                                                    <DialogTitle
                                                                        style={{ padding: "16px 24px 0px" }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: "32px",
                                                                                color: "#222222",
                                                                                fontWeight: 700,
                                                                                padding: "0px 16px",
                                                                            }}
                                                                        >
                                                                            {"Start Over"}
                                                                        </Typography>
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                color: "#6F6F70",
                                                                                margin: "16px",
                                                                            }}
                                                                        >
                                                                            By continuing you will delete the investor
                                                                            profile that is in progress. This action
                                                                            cannot be undone.
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                color: "#222222",
                                                                                fontWeight: 600,
                                                                                margin: "16px",
                                                                            }}
                                                                        >
                                                                            Would you like to delete this investor
                                                                            profile and start a new one?
                                                                        </Typography>
                                                                    </DialogContent>
                                                                    <DialogActions
                                                                        style={{
                                                                            justifyContent: "space-between",
                                                                            padding: "0px 40px 30px",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            onClick={this.handleClose}
                                                                            className={classes.startBtn}
                                                                            style={{ width: "241px" }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                        <Button
                                                                            onClick={this.handleStartOver}
                                                                            className={classes.nextBtn}
                                                                            style={{ width: "241px" }}
                                                                        >
                                                                            Confirm
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                            <Button
                                                                onClick={this.handleKycSubmit}
                                                                className={classes.nextBtn}
                                                            >
                                                                Next
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {kycVerified && (
                                                    <Box style={{ height: "80vh" }}>
                                                        <Box
                                                            style={{
                                                                marginBottom: "50px",
                                                                textAlign: "justify",
                                                            }}
                                                        >
                                                            <Typography className={classes.headerText}>
                                                                Verification Status
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                gap: "24px",
                                                            }}
                                                        >
                                                            <img src={imgSuccess} />
                                                            <Typography
                                                                style={{
                                                                    color: "#222222",
                                                                    fontSize: "32px",
                                                                    fontWeight: 800,
                                                                    fontFamily: "Lato",
                                                                }}
                                                            >
                                                                Verified!
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: "16px",
                                                                    color: "#6F6F70",
                                                                    textAlign: "center",
                                                                    fontFamily: "Roboto",
                                                                }}
                                                            >
                                                                Your details has been verified successfully.
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {kycFailed && (
                                                    <Box style={{ height: "80vh" }}>
                                                        <Box
                                                            style={{
                                                                textAlign: "justify",
                                                                marginBottom: "50px",
                                                            }}
                                                        >
                                                            <Typography className={classes.headerText}>
                                                                Verification Status
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                gap: "24px",
                                                            }}
                                                        >
                                                            <img src={imgFailed} />
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Lato",
                                                                    fontSize: "32px",
                                                                    color: "#222222",
                                                                    fontWeight: 800,
                                                                }}
                                                            >
                                                                Unable to verify
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontSize: "16px",
                                                                    color: "#6F6F70",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                We were unable yo verify the identity to you or
                                                                your business. We will automatically review to
                                                                ensure this wasn’t an error. If you think this
                                                                is a mistake, please open a support ticket.
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                )}

                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                (identification_type === "national_id" && page === 9) && (
                                    <>
                                        {this.adhaarVerificationDetails()}
                                    </>
                                )}
                            {(investorType === "As a firm or fund" ||
                                investorType === "As a family office" ||
                                investorType === "Through a trust") &&
                                !investorTypeSelect &&
                                (identification_type === "national_id" && page === 10) && (
                                    <Box className={classes.centerMainBox}>
                                        <Box>
                                            {!kycFailed && !kycVerified && (
                                                <>
                                                    {this.adhaarVerificationOTP()}
                                                </>
                                            )}
                                            {kycVerified && (
                                                <Box style={{ height: "80vh" }}>
                                                    <Box
                                                        style={{
                                                            textAlign: "justify",
                                                            marginBottom: "50px",
                                                        }}
                                                    >
                                                        <Typography className={classes.headerText}>
                                                            Verification Status
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            gap: "24px",
                                                        }}
                                                    >
                                                        <img src={imgSuccess} />
                                                        <Typography
                                                            style={{
                                                                fontSize: "32px",
                                                                color: "#222222",
                                                                fontFamily: "Lato",
                                                                fontWeight: 800,
                                                            }}
                                                        >
                                                            Verified!
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#6F6F70",
                                                                fontFamily: "Roboto",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            Your details has been verified successfully.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                            {kycFailed && (
                                                <Box style={{ height: "80vh" }}>
                                                    <Box
                                                        style={{
                                                            textAlign: "justify",
                                                            marginBottom: "50px",
                                                        }}
                                                    >
                                                        <Typography className={classes.headerText}>
                                                            Verification Status
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            gap: "24px",
                                                        }}
                                                    >
                                                        <img src={imgFailed} />
                                                        <Typography
                                                            style={{
                                                                fontSize: "32px",
                                                                color: "#222222",
                                                                fontFamily: "Lato",
                                                                fontWeight: 800,
                                                            }}
                                                        >
                                                            Unable to verify
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#6F6F70",
                                                                fontFamily: "Roboto",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            We were unable yo verify the identity to you or
                                                            your business. We will automatically review to
                                                            ensure this wasn’t an error. If you think this
                                                            is a mistake, please open a support ticket.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                )} 
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(InvestorProfile);
// Customizable Area End
