import React from "react";

// Customizable Area Start
import {

    Button,
    Paper,
    Typography,
    Container,
    StepLabel,
    Step,
    Stepper,
    Grid
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import Footer from "../../../components/src/Footer.web";
import TopNavBar from "../../../components/src/TopNavbar.web";
import { investment1, investment2, investment3, investment4 } from "./assets";
// Customizable Area End

import InvestmentProcessController, {
    Props,
} from "./InvestmentProcessController.web";

const styles: StyleRules = {
    // Customizable Area Start
    investmentProcessContainerStyle: {
        height: '1080px',
        maxWidth: 'none',
        '@media (max-width: 1600px)': {
            height: '900px',
        },
        '@media (max-width: 1280px)': {
            height: '720px',
        },
        '@media (max-width: 1024px)': {
            height: 'auto',
        }
    },
    investmentProcessStyle: {
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '52.51px',
        maxWidth: '1536px',
        margin: 'auto',
        padding: '115.35px 0',
        "& .investmentProcessText": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '58.92px',
            lineHeight: '70.71px',
            color: 'rgba(0, 105, 188, 1)',
        },
        '@media (max-width: 1600px)': {
            gap: '43.76px',
            maxWidth: '1280px',
            padding: '93.63px 0',
            "& .investmentProcessText": {
                fontSize: '49.1px',
                lineHeight: '58.92px',
            }
        },
        '@media (max-width: 1320px)': {
            gap: '12.76px',
        },
        '@media (max-width: 1280px)': {
            gap: '35.65px',
            maxWidth: '1042.69px',
            padding: '68.05px 0',
            "& .investmentProcessText": {
                fontSize: '40px',
                lineHeight: '48px',
            }
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
        },
    },
    investmentProcessLeft: {
        display: 'flex',
        gap: '55.03px',
        flexDirection: 'column',
        '@media (max-width: 1600px)': {
            gap: '46.02px',
        },
        '@media (max-width: 1280px)': {
            gap: '37.55px',
        }
    },
    stepper: {
        padding: '0px',
        '& .MuiStepIcon-root': {
            color: 'transparent',
            width:"130.77px",
            height: '131.28px',
            zIndex: 1,
            '@media (max-width: 1600px)': {
                width:"109.15px",
                height: '109.4px',
            },
            '@media (max-width: 1280px)': {
                width:"88.73px",
                height: '89.12px',
            }
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            fontWeight: 600
        },
        '& .MuiStepLabel-label': {
            fontSize: '20.62px'
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: 'rgba(0, 105, 188, 1)',
            '& .MuiStepIcon-text': {
                fill: 'white',
                fontWeight: 700,
                fontFamily: 'Lato'
            },
        },
        '& .MuiStepIcon-text': {
            fill: 'rgba(0, 105, 188, 1)',
            fontWeight: 700,
            fontFamily: 'Lato',
        },
        '& .MuiStepConnector-active': {
        },
        '& .MuiStepLabel-root': {
            gap: '21.01px',
            '@media (max-width: 1600px)': {
                gap: '17.5px',
            },
            '@media (max-width: 1280px)': {
                gap: '14.26px',
            }
        },
        '& .MuiStepLabel-iconContainer' : {
            padding: '0px',
            justifyContent: 'center',
            '@media(max-width:600px)':{
                paddingLeft:"0px",
            }
        },
        '& .MuiStepConnector-vertical': {
            margin: '0'
        },
        '& .MuiStepConnector-lineVertical': {
            minHeight: '104.42px',
            borderLeft: '2px solid black',
            margin: '-42.72px 65.64px',
            '@media(max-width:1600px)':{
                minHeight: '100.42px',
                margin: '-40.72px 56.64px',
            },
            '@media(max-width:1280px)':{
                minHeight: '86.42px',
                margin: '-34.72px 45.64px',
            },
        }
    },
    activeClass: {
        borderRadius: '21.01px',
        backgroundColor: 'rgba(0, 105, 188, 1)',
        color: 'rgba(255, 255, 255, 1)',
        display: 'flex',
        gap: '15.75px',
        flexDirection: 'column',
        padding: '31.51px 42.01px',
        '@media (max-width: 1600px)': {
            gap: '13.13px',
            borderRadius: '17.5px',
            padding: '26.26px 35.01px',
        },
        '@media (max-width: 1280px)': {
            gap: '10.7px',
            borderRadius: '14.26px',
            padding: '21.39px 28.52px',
        },
        '@media (max-width: 600px)': {
            gap: '0px',
            padding: '12px 15px',
        }
    },
    activeHeading: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '32.41px',
        lineHeight: '48.61px',
        '@media (max-width: 1600px)': {
            fontSize: '27.01px',
            lineHeight: '40.51px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '22px',
            lineHeight: '33px',
        },
        '@media (max-width: 600px)': {
            fontSize: '18px',
        }
    },
    activeDescription: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '20.62px',
        lineHeight: '30.94px',
        '@media (max-width: 1600px)': {
            fontSize: '17.19px',
            lineHeight: '25.78px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '14px',
            lineHeight: '21px',
        },
    },
    browseBtn: {
        textTransform: "none",
        backgroundColor: 'rgba(0, 105, 188, 1)',
        height: "72px",
        width: "300px",
        color: "#FFFFFF",
        borderRadius: "15.75px",
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: "26.26px",
        lineHeight: '30.77px',
        "&:hover": {
            backgroundColor: "rgba(0, 105, 188, 1)",
        },
        '@media (max-width: 1600px)': {
            height: "60px",
            width: "250px",
            borderRadius: "13.13px",
            fontSize: "21.88px",
            lineHeight: '25.64px',
        },
        '@media (max-width: 1280px)': {
            height: "48px",
            width: "200px",
            borderRadius: "10.69px",
            fontSize: "17.82px",
            lineHeight: '20.89px',
        },
        '@media(max-width:600px)':{
            marginLeft:"0"
        }
    },
    btnWrapper: {
        marginTop: '84.14px',
        display: 'flex',
        justifyContent: 'end',
        '@media(max-width:1600px)': {
            marginTop: '75.63px',
        },
        '@media(max-width:1280px)': {
            marginTop: '65.36px',
        },
        '@media(max-width:600px)': {
            justifyContent: 'center',
        }
    },
    investmentImage: {
        width: '735.18px',
        '@media(max-width:1600px)': {
            width: '612.65px',
        },
        '@media(max-width:1280px)': {
            width: '499.07px',
        },
        '@media(max-width:959px)': {
            display: 'none'
        }
    }
    // Customizable Area End
};

export class InvestmentProcessWeb extends InvestmentProcessController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    arrayOfImages = [investment1, investment2, investment3, investment4]
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { activeStep, investmentDetails } = this.state;
        const { classes, } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <TopNavBar navigation={this.props.navigation} />
                <Container className={classes.investmentProcessContainerStyle}>
                    <Grid container className={classes.investmentProcessStyle}>
                        <Grid className={classes.investmentProcessLeft}>
                            <Grid>
                                <Typography className="investmentProcessText">Investment Process</Typography>
                            </Grid>
                            <Grid>
                                <img src={this.arrayOfImages[activeStep]} className={classes.investmentImage} />
                            </Grid>
                        </Grid>
                        <Grid >
                            <Stepper className={classes.stepper}
                                activeStep={activeStep}
                                orientation="vertical">
                                {investmentDetails.data?.map((label: any, index: number) => (    
                                    <Step key={label.id} completed={false} className="scroll-content">
                                        {activeStep === index ? <StepLabel >
                                            <Grid className={classes.activeClass}>
                                                <Typography className={classes.activeHeading}>{label?.attributes?.title}:</Typography>
                                                <Typography className={classes.activeDescription}>{label?.attributes?.description}</Typography>
                                            </Grid>
                                        </StepLabel> :
                                            <StepLabel>
                                                <Typography className={classes.activeDescription}><span style={{ fontWeight: 800, color: 'rgba(34, 34, 34, 1)' }}>{label?.attributes?.title}:</span>{label?.attributes?.description}</Typography>
                                            </StepLabel>}
                                    </Step>
                                ))}
                            </Stepper>
                            <Grid className={classes.btnWrapper}>
                                <Button className={classes.browseBtn} onClick={this.navigateToInvestList} data-test-id="browseBtn">Browse Opportunities</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(InvestmentProcessWeb);
// Customizable Area End
