import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imgShape } from "../../landingpage/src/assets";
import { imageHerosectionbackground } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { GroupArrowBig, imgBigOne, imgBigZero, imgBigTwo, imgBigThree } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Readmore from '../../../components/src/ReadMore.web'
// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImagesStyle: {
        flexDirection: 'column',
        display: 'flex',
        backgroundRepeat: "round",
        alignItems: 'center',
        backgroundImage: `url(${imageHerosectionbackground})`,
        backgroundSize: "cover",
        gap: '102.27px',
        position: 'relative',
        '@media (max-width: 1920px)': {
            minHeight: '100vh',
            gap: '55.22px',
        },
        '@media (max-width: 1600px)': {
            gap: '42.22px',
        },
        '@media (max-width: 1280px)': {
            gap: '22.54px'
        },
        '@media (max-width: 959px)': {
            height: '570px',
            minHeight:'auto',
            gap: '52px'
        },
        '@media (max-width: 600px)': {
            height: '449px',
            gap: '26px'
        }
    },
    titleTextMainBoxStyle: {
        width: '1520.56px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30.51px',
        '@media (max-width: 1600px)': {
            gap: '25.42px',
            width: '1267.14px',
        },
        '@media (max-width: 1280px)': {
            gap: '21.33px',
            width: '1040px',
        },
        '@media (max-width: 959px)': {
            width: 'auto',
        }
    },
    headerMainTextStyle: {
        fontSize: '84.91px',
        lineHeight: '127.36px',
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        fontWeight: 400,
        '@media (max-width: 1600px)': {
            fontSize: '70.76px',
            lineHeight: '106.13px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '58px',
            lineHeight: '87px',
        },
        '@media (max-width: 959px)': {
            fontSize: '36px',
            lineHeight: '57px',
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
            lineHeight: '37px',
            marginBottom: "0px"
        }
    },
    headerSubText: {
        fontFamily: 'Roboto',
        fontSize: '26.4px',
        lineHeight: '39.6px',
        color: '#ffffff',
        textAlign: 'center',
        padding:'0 20px',
        '@media (max-width: 1600px)': {
            fontSize: '22px',
            lineHeight: '33px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '18px',
            lineHeight: '27px',
        },
    },
    futureTextStyle: {
        color: '#00E644',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    opportunitiesBtnStyle: {
        fontSize: '26.03px',
        lineHeight: '30.5px',
        fontFamily: "Roboto",
        fontWeight: 700,
        width: '100%',
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '419.48px',
        borderRadius: '15.62px',
        textTransform: 'none',
        height: '72px',
        background: 'rgba(255, 255, 255, 1)',
        marginTop: "30px",
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        },
        '@media(max-width:1600px)':{
            fontSize: '21.69px',
            lineHeight: '25.42px',
            maxWidth: '349.56px',
            height: '60px',
            borderRadius: '13.01px',
            marginTop: "25px",
        },
        '@media(max-width:1280px)':{
            fontSize: '17.78px',
            lineHeight: '20.83px',
            maxWidth: '300px',
            height: '44.77px',
            borderRadius: '10.67px',
            marginTop: "21px",
        },
        '@media (max-width: 600px)': {
            marginTop: "0px",
        }
    },
    containerStyle: {
        display: "flex",
        justifyContent: "center",
        '@media(max-width:1920px)':{
            minHeight:'100vh'
        },
        '@media (max-width: 959px)': {
            padding: "0px",
            minHeight:"auto",
            margin: '70px 0px !important'
        },
    },
    spanStyle: {
        fontStyle: 'italic',
        color: 'rgba(0, 105, 188, 1)',
        fontWeight: 800
    },
    Imgnumbers :{
        margin: '0px -24px -60px 30px',
        '@media (max-width: 1600px)': {
            margin: '0px -18px -42px 30px'
        },
        '@media (max-width: 1280px)': {
            margin: '0px -12px -30px 22px'
        }
    },
    FundBox :{
        position: 'relative',
        backgroundColor: '#F2F8FC',
        alignContent:"end",
        borderRadius: '21.13px',
        overflow: 'hidden',
        padding:'60.26px 42.26px 28.93px',
        '@media (max-width: 1600px)': {
            borderRadius: '17.61px',
            padding:'40.02px 35.21px 23.61px',
        },
        '@media (max-width: 1280px)': {
            borderRadius: '14.09px',
            padding:'28px 28.17px 19.29px',
        },
    },
    shapeImage: {
        position: 'absolute',
        right: '112.2px',
        top: '585.66px',
        '@media (max-width: 1600px)': {
            right: '93.5px',
            top: '488.05px',
        },
        '@media (max-width: 1280px)': {
            right: '75.8px',
            top: '363.96px',
        },
        '@media (max-width: 600px)': {
            top: '300px',
        }
    },
    directlyInvestFundsStyle: {
        margin: '166.59px 0',
        maxWidth: '1563.29px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20.44px',
        "& .startupFundsInvestBox": {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
            "& .startupFundsInvestBoxStyle": {
                display: 'flex',
                gap: '49.11px'
            }
        },
        "& .directlyInvestFundsHeading": {
            fontSize: '60px',
            lineHeight: '72px',
            fontFamily: 'Lato',
            fontWeight: 600,
            textAlign: 'center'
        },
        '@media (max-width: 1600px)': {
            margin: '130.95px 0',
            maxWidth: '1302.74px',
            gap: '17.03px',
            "& .startupFundsInvestBox": {
                gap: '16.91px',
                "& .startupFundsInvestBoxStyle": {
                    gap: '40.93px !important'
                }
            },
            "& .directlyInvestFundsHeading": {
                fontSize: '50px',
                lineHeight: '60px',
            },
        },
        '@media (max-width: 1280px)': {
            margin: '100.06px 0',
            maxWidth: '1042.2px',
            gap: '13.98px',
            "& .startupFundsInvestBox": {
                gap: '13.33px',
                "& .startupFundsInvestBoxStyle": {
                    gap: '32.74px !important'
                }
            },
            "& .directlyInvestFundsHeading": {
                fontSize: '40px',
                lineHeight: '48px',
            },
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0',
            gap: '32.74px',
            "& .startupFundsInvestBox": {
                "& img": {
                    display: 'none'
                },
                "& .startupFundsInvestBoxStyle": {
                    flexDirection: 'column'
                }
            },
            "& .directlyInvestFundsHeading": {
                fontSize: '40px',
            },
        },
        '@media (max-width: 600px)': {
            "& .directlyInvestFundsHeading": {
                fontSize: '28px',
            },
        }
    },
    startupFundsBox: {
        maxWidth: '404.07px',
        height: 'max-content',
        width: '100%',
        padding: '42.26px 42.26px 83.2px',
        background: 'rgba(229, 240, 248, 1)',
        borderRadius: '21.13px',
        display: 'flex',
        flexDirection: 'column',
        gap: '31.69px',
        position: 'relative',
        "&.second": {
            background: 'rgba(0, 105, 188, 1)',
            top: '-82.77px'
        },
        "&.third": {
            top: '-166.77px',
        },
        '@media (max-width: 1600px)': {
            maxWidth: '336.74px',
            padding: '35.21px 35.21px 69.33px',
            borderRadius: '17.61px',
            gap: '26.41px',
            "&.second": {
                top: '-68.73px'
            },
            "&.third": {
                top: '-138.98px',
            },
        },
        '@media (max-width: 1280px)': {
            maxWidth: '269.39px',
            padding: '28.17px',
            borderRadius: '14.09px',
            gap: '21.13px',
            "&.second": {
                top: '-55.18px'
            },
            "&.third": {
                top: '-111.18px',
            },
        },
        '@media (max-width: 959px)': {
            top: "0 !important",
            width: "auto",
            maxWidth:'none'
        }
    },
    arrowImage: {
        width: '100%',
        maxWidth: '1067.23px',
        '@media (max-width: 1600px)': {
            maxWidth: '889.36px',
        },
        '@media (max-width: 1280px)': {
            maxWidth: '711.49px',
        }
    },
    startupFundsBoxTitle: {
        fontSize: '42.26px',
        lineHeight: '50.71px',
        fontFamily: 'Lato',
        fontWeight: 800,
        color: 'rgba(0, 105, 188, 1)',
        '@media (max-width: 1600px)': {
            fontSize: '35.21px',
            lineHeight: '42.26px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '28.17px',
            lineHeight: '33.81px',
        }
    },
    startupBoxText: {
        wordWrap: 'break-word',
        fontSize: '23.77px',
        lineHeight: '35.65px',
        fontFamily: 'Lato',
        fontWeight: 400,
        '@media (max-width: 1600px)': {
            fontSize: '19.81px',
            lineHeight: '29.71px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '23.77px',
        }
    },
    simplifiedFundsStyle: {
        maxWidth: '1545.74px',
        margin: '43.5px 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& img": {
            height: 'fit-content',
            width: '92.2px'
        },
        "& .simplifiedFundsHeading": {
            fontSize: '63.38px',
            lineHeight: '76.06px',
            fontFamily: 'Lato',
            fontWeight: 600,
            textAlign: 'center'
        },
        "& .directlyInvestText": {
            display: 'flex',
            alignItems: 'center',
            marginTop: '70px'
        },
        '@media(max-width:1600px)':{
            maxWidth: '1288.12px',
            margin: '36.25px 0',
            "& img": {
                height: 'fit-content',
                width: '70px'
            },
            "& .simplifiedFundsHeading": {
                fontSize: '52.82px',
                lineHeight: '63.38px',
            },
            "& .directlyInvestText": {
                marginTop: '64px'
            },
        },
        '@media(max-width:1280px)':{
            maxWidth: '1030.49px',
            margin: '29px 0',
            "& img": {
                height: 'fit-content',
                width: '50.8px'
            },
            "& .simplifiedFundsHeading": {
                fontSize: '42.26px',
                lineHeight: '50.71px',
            },
            "& .directlyInvestText": {
                marginTop: '48px'
            },
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0',
            "& .simplifiedFundsHeading": {
                fontSize: '40px',
            },
            "& .directlyInvestText": {
                order: 1,
                marginTop: '0px'
            },
        },
        '@media (max-width: 600px)': {
            "& .simplifiedFundsHeading": {
                fontSize: '28px',
            },
        }
    },
    feeStructureFundsStyle: {
        width: '100%',
        gap: '40.36px',
        flexWrap: 'nowrap',
        marginTop: '-23.63px',
        '@media (max-width: 1600px)': {
            gap: '33.63px',
            marginTop: '-18.85px',
        },
        '@media (max-width: 1280px)': {
            gap: '26.9px',
            marginTop: '-15.08px',
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
        },
    },
    directlyFundsInvestDetails: {
        fontSize: '23.77px',
        lineHeight: '35.65px',
        fontFamily: 'Lato',
        fontWeight: 400,
        '@media (max-width: 1600px)': {
            fontSize: '19.81px',
            lineHeight: '29.71px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '23.77px',
        }
    },
    fundsBoxText: {
        fontSize: '39px',
        lineHeight: '58.5px',
        fontFamily: 'Lato',
        fontWeight: 600,
        '@media (max-width: 1600px)': {
            fontSize: '32.5px',
            lineHeight: '48.75px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '24px',
            lineHeight: '36px',
        },
    },
    futureOpportunitiesFundsStyle: {
        maxWidth: '1536px',
        margin: '98.3px 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& .opportunityDataStyle": {
            display: 'flex',
            gap: '39.39px',
            marginTop: '62.95px',
            flexWrap: 'nowrap'
        },
        "& .directStartupHeading": {
            fontSize: '52.51px',
            lineHeight: '63.02px',
            marginTop: '21.88px',
            fontFamily: 'Lato',
            fontWeight: 800,
            textAlign: 'center'
        },
        '@media (max-width: 1600px)': {
            maxWidth: '1280px',
            margin: '79.42px 0',
            "& .opportunityDataStyle": {
                gap: '32.82px',
                marginTop: '30.96px',
            },
            "& .directStartupHeading": {
                fontSize: '43.76px',
                lineHeight: '52.51px',
                marginTop: '17.57px',
            },
        },
        '@media (max-width: 1280px)': {
            maxWidth: '1030px',
            margin: '57.65px 0',
            "& .opportunityDataStyle": {
                gap: '26.41px',
                marginTop: '42.46px',
            },
            "& .directStartupHeading": {
                fontSize: '35.21px',
                lineHeight: '42.26px',
                marginTop: '14.45px',
            },
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0px',
            "& .opportunityDataStyle": {
                flexWrap: 'wrap',
                justifyContent: 'center'
            },
            "& .directStartupHeading": {
                fontSize: '32px',
            },
        },
        '@media (max-width: 600px)': {
            "& .directStartupHeading": {
                fontSize: '24px',
            },
        }
    },
    futureOpportunitiesFundsText: {
        fontSize: '23.63px',
        lineHeight: '28.36px',
        fontFamily: 'Lato',
        color: 'rgba(0, 105, 188, 1)',
        fontWeight: 400,
        textAlign: 'center',
        '@media (max-width: 1600px)': {
            fontSize: '19.69px',
            lineHeight: '23.63px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '19.02px',
        }
    },
    opportunityFundsDataGrid: {
        maxWidth: '748.31px',
        width: '100%',
        padding: '30.5px 60px',
        display: 'flex',
        flexDirection: 'column',
        border:'1px solid rgba(111, 111, 112, 1)',
        gap: '20px',
        borderRadius:'12px',
        backgroundColor:'rgb(242, 248, 252)',
        '& .title-description':{
            display: 'flex', 
            gap: '20px',
            flexDirection: 'column' 
         },
        '@media (max-width: 1600px)': {
            maxWidth: '623.59px',
            padding: '30.5px 40px',
            '& .title-description':{
                gap: '8px',
            },
        },
        '@media (max-width: 1280px)': {
            maxWidth: '501.79px',
            padding: '20px',
            '& .title-description':{
                gap: '8px',
            },
        },
        '@media (max-width: 959px)': {
            maxWidth: 'none'
        }
    },
    opportunityFundsTitle: {
        fontFamily: 'Lato',
        fontWeight: 800,
        fontSize: '52.51px',
        '@media (max-width: 1600px)': {
            fontSize: '43.76px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '35.21px',
        }
    },
    textFundsStyle: {
        fontSize: '23.63px',
        color: 'rgba(111, 111, 112, 1)',
        fontWeight: 500,
        fontFamily: 'Roboto',
        '@media (max-width: 1600px)': {
            fontSize: '19.69px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
        }
    },
    investFundsBtn: {
        marginTop: '48.48px',
        maxWidth: '300px',
        width: '100%',
        height: '72px',
        borderRadius: '15.75px',
        textTransform: 'none',
        fontSize: '23.63px',
        lineHeight: '27.69px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: '#ffffff',
        background: 'rgba(0, 105, 188, 1)',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        },
        '@media (max-width: 1600px)': {
            marginTop: '45.4px',
            maxWidth: '250px',
            height: '60px',
            borderRadius: '13.13px',
            fontSize: '19.69px',
            lineHeight: '23.08px',
        },
        '@media (max-width: 1280px)': {
            marginTop: '40.1px',
            maxWidth: '200px',
            height: '48px',
            borderRadius: '10.56px',
            fontSize: '15.85px',
            lineHeight: '18.57px',
        }
    },
    // Customizable Area End
};

export class FundInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { startupInvestData, opportunityData, feeStructureData } = this.state;

        return (
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImagesStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <img src={imgShape} className={classes.shapeImage} />
                    <Box className={classes.titleTextMainBoxStyle}>
                        <Typography className={classes.headerMainTextStyle}> Experience <br /><span className={classes.futureTextStyle}>Instant Diversification </span></Typography>
                        <Typography className={classes.headerSubText}>Tap into the track record of top-tier managers with fund investing, maximising your potential for success.</Typography>
                        <Button className={classes.opportunitiesBtnStyle} onClick={this.showFundInvestmentDetails}>View Available Opportunities</Button>
                    </Box>
                </Box>

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.directlyInvestFundsStyle}>
                        <Typography className="directlyInvestFundsHeading">
                            Why directly invest in <span className={classes.spanStyle} >Funds</span>?
                        </Typography>
                        <Grid item className="startupFundsInvestBox">
                            <img src={GroupArrowBig} alt="" className={classes.arrowImage} />
                            <Box className="startupFundsInvestBoxStyle">
                                <Box className={classes.startupFundsBox}>
                                    <Typography className={classes.startupFundsBoxTitle}>
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[0]?.sub_title}
                                    </Typography>
                                    <Typography className={classes.startupBoxText} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[0]?.content}
                                    </Typography>
                                </Box>
                                <Box className={`${classes.startupFundsBox} second`}>
                                    <Typography className={classes.startupFundsBoxTitle} style={{ color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[1]?.sub_title}
                                    </Typography>
                                    <Typography className={classes.startupBoxText} style={{ color: '#FFFFFF' }} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[1]?.content}
                                    </Typography>
                                </Box>
                                <Box className={`${classes.startupFundsBox} third`}>
                                    <Typography className={classes.startupFundsBoxTitle} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[2]?.sub_title}
                                    </Typography>
                                    <Typography className={classes.startupBoxText} >
                                        {startupInvestData?.titles?.data[1]?.attributes?.title_contents[2]?.content}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {feeStructureData != undefined ?
                    <Grid container className={classes.containerStyle}>
                        <Grid container className={classes.simplifiedFundsStyle}>
                            <Typography className="simplifiedFundsHeading">
                                Simplified <span className={classes.spanStyle}>Fee Structure</span>
                            </Typography>
                            <div>
                                <Grid container className={classes.feeStructureFundsStyle}>
                                    <Grid item md={6} xs={12} className={classes.feeStructureBlueBox}>
                                        <img src={imgBigZero} className={classes.Imgnumbers} />
                                        <img src={imgBigOne} style={{ position: 'absolute', zIndex: 1}} />
                                        <Box className={classes.FundBox}>
                                            <Typography className={classes.fundsBoxText}>
                                                <span className={classes.spanStyle}>Low minimum allocation amount</span> - Invest with flexibility and comfort
                                            </Typography>

                                        </Box>

                                    </Grid>
                                    <Grid item md={6} xs={12} className="directlyInvestText">
                                        <Typography
                                            className={classes.directlyFundsInvestDetails}
                                            dangerouslySetInnerHTML={{ __html: feeStructureData[0]?.attributes?.content }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className={classes.feeStructureFundsStyle}>
                                    <Grid item md={6} xs={12} className="directlyInvestText">
                                        <Typography
                                            className={classes.directlyFundsInvestDetails}
                                            dangerouslySetInnerHTML={{ __html: feeStructureData[1]?.attributes?.content }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} className={classes.feeStructureBlueBox}>
                                        <img src={imgBigZero} className={classes.Imgnumbers} />
                                        <img src={imgBigTwo} style={{ position: 'absolute', zIndex: 1 }} />
                                        <Box className={classes.FundBox} >
                                            <Typography className={classes.fundsBoxText}>
                                                Invest without <span className={classes.spanStyle}>upfront fees</span> - pay only when you invest

                                            </Typography>

                                        </Box>

                                    </Grid>


                                </Grid>

                                <Grid container className={classes.feeStructureFundsStyle}>
                                    <Grid item md={6} xs={12} className={classes.feeStructureBlueBox}>
                                        <img src={imgBigZero} className={classes.Imgnumbers} />
                                        <img src={imgBigThree} style={{ position: 'absolute', zIndex: 1 }} />
                                        <Box className={classes.FundBox}>
                                            <Typography className={classes.fundsBoxText}>
                                                <span className={classes.spanStyle}>Transparent fees</span> for informed investments - know the fees before you invest.
                                            </Typography>

                                        </Box>

                                    </Grid>

                                    <Grid item md={6} xs={12} className="directlyInvestText">
                                        <Typography
                                            className={classes.directlyFundsInvestDetails}
                                            dangerouslySetInnerHTML={{ __html: feeStructureData[2]?.attributes?.content }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid> : ''}

                    <Grid container style={{minHeight:'auto'}} className={classes.containerStyle}>
                        <Grid container className={classes.futureOpportunitiesFundsStyle}>
                            <Typography className={classes.futureOpportunitiesFundsText}>
                                From Our Past Successes to Your Future Opportunities
                            </Typography>
                            <Typography className="directStartupHeading">
                                Our Direct Fund Investments
                            </Typography>
                            <Grid className="opportunityDataStyle">
                                {opportunityData?.slice(0, 2).map((data: any) => (
                                    <Grid className={classes.opportunityFundsDataGrid}>
                                        <Grid className='title-description'>
                                            <Typography className={classes.opportunityFundsTitle}>{data.attributes.company.data[0]?.attributes.company_name}</Typography>
                                            <Typography className={classes.textFundsStyle}><Readmore text={data.attributes.company.data[0]?.attributes.about}/></Typography>
                                        </Grid>
                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>

                                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className={classes.textFundsStyle}>Minimum Investment</Typography>
                                                    <Typography className={classes.textFundsStyle} >${this.numberWithCommas(data.attributes.minimum_investment)}</Typography>
                                                </Grid>
                                                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className={classes.textFundsStyle}>Allocation</Typography>
                                                    <Typography className={classes.textFundsStyle}>${this.numberWithCommas(data.attributes.allocation)}</Typography>
                                                </Grid>
                                                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className={classes.textFundsStyle}  >Round</Typography>
                                                    <Typography className={classes.textFundsStyle}>{data.attributes.round}</Typography>
                                                </Grid>
                                                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography className={classes.textFundsStyle}>Estimated Fees</Typography>
                                                    <Typography className={classes.textFundsStyle}>{data.attributes.estimated_fee}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
                                                <Typography className={classes.textFundsStyle}>View Fund Info</Typography>
                                                <Grid style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 105, 188, 1)', borderRadius: '50%', padding: '6px', color: 'white' }} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${data.id}`}>
                                                    <ArrowForwardIcon />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <Button className={classes.investFundsBtn} onClick={this.showFundInvestmentDetails} data-test-id="fundInvestBtn">Invest Now</Button>
                        </Grid>
                    </Grid>

                <Footer navigation={this.props.navigation} />
            </Paper>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(FundInvestmentWeb);
// Customizable Area End
